import React from "react";
import "./App.css";
import Dashboard from "./components/dashboard";
import Login from "./components/Login";
import HomePage from "./pages/Home/HomePage";
import AddUser from "./pages/User/AddUser";
import UpdateUser from "./pages/User/updateUser";
import ViewUser from "./pages/User/ViewUser";
import DocType from "./pages/Setting/DocType";
import CertificateType from "./pages/Setting/CertificateType";
import Bank from "./pages/Setting/Bank";
import { Routes, Route } from "react-router-dom";
import AddCustomer from "./pages/Customer/AddCustomer";
import UpdateCustomer from "./pages/Customer/UpdateCustomer";
import ViewCustomer from "./pages/Customer/ViewEnquirys";
import CertificateDocument from "./pages/Setting/BindCertificateDocument";
import CustomerProfile from "./pages/Customer/Profile/Profile";
import SetInstallment from "./pages/Customer/SetInstallment";
import Payment from "./pages/Customer/Payment";
import ViewCustomers from "./pages/Customer/ViewCustomer";
import Invoice from "./pages/Customer/Invoice";
import ViewAllInvoices from "./pages/Customer/ViewAllInvoices";
import ViewAllDuePayments from "./pages/Customer/ViewAllDuePayments";
import AddVendor from './pages/Vendors/AddVendor'
import ViewVendors from "./pages/Vendors/ViewVendors";
import VendorPayment from './pages/Vendors/VendorPayment'
import ViewAgents from './pages/Agents/ViewAgents'
import AddAgents from './pages/Agents/AddAgents'
import ViewAllVendorPayments from "./pages/Vendors/ViewAllVendorPayments";
import AgentEnquiry from "./pages/Agents/AgentEnquiry";
import TestReminder from "./components/TestReminder";
import AnnualReturn from "./pages/Customer/AnnualReturn";
import CustomerEnquiry from "./pages/Customer/AddCustomer";
import DeletedEnquiries from "./pages/Customer/DeletedEnquiry";



function App() {
  return (

    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route path="" element={<HomePage />} />
        <Route path="/viewCustomers" element={<ViewCustomers />} />
        <Route path="/viewUser" element={<ViewUser />} />
        <Route path="/updateUser/:UserId" element={<UpdateUser />} />
        <Route path="/addUser" element={<AddUser />} />
        <Route path="/docType" element={<DocType />} />
        <Route path="/certificateType" element={<CertificateType />} />
        <Route path="/bank" element={<Bank />} />
        <Route path="/viewEnquiry" element={<ViewCustomer />} />
        <Route path="/viewEnquiry/:condition" element={<ViewCustomer />} />
        <Route path="/viewEnquiry/:Customer_Id/:Company_Name/:Type" element={<ViewCustomer />} />
        <Route path="/updateCustomer/:Sr_No" element={<UpdateCustomer />} />
        <Route path="/certificateDocument" element={<CertificateDocument />} />
        {/* <Route path="/customerProfile/:EnquiryId" element={<CustomerProfile />} /> */}
        {/* <Route path="/customerProfile/:UserId/:EnquiryId" element={<CustomerProfile />} /> */}
        <Route path="/agentEnquiries" element={<AgentEnquiry/>}/>
        <Route path="/agentEnquiries/:condition" element={<AgentEnquiry/>}/>
        <Route path="/setInstallment/:Enq_Id" element={<SetInstallment />} />
        <Route path="/makePayments/:Enq_Id" element={<Payment />} />
        <Route path="/invoice/:Enq_Id" element={<Invoice />} />
        <Route path="/invoice/:Enq_Id/:Payment_Id/:Installment_Id/:logId" element={<Invoice />} />
        <Route path="/invoice/:Enq_Id/:Perfoma" element={<Invoice />} />
        <Route path="/invoice/:Enq_Id/:Facility" element={<Invoice />} />
        <Route path="/ViewAllInvoices" element={<ViewAllInvoices />} />
        <Route path="/viewAllDuePayments" element={<ViewAllDuePayments />} />
        <Route path="/addVendors" element={<AddVendor/>}/>
        <Route path="/editVendor/:Vendor_Id" element={<AddVendor/>}/>
        <Route path="/viewVendors" element={<ViewVendors/>}/>
        <Route path="/vendorPayment" element={<VendorPayment/>}/>
        <Route path="/viewAgents" element={<ViewAgents/>}/>
        <Route path="/addAgents" element={<AddAgents/>}/>
        <Route path="/editAgent/:Sr_No" element={<AddAgents/>}/>
        <Route path="/viewAllVendorPayments" element={<ViewAllVendorPayments/>}/>
        <Route path="/test-reminder" element={<TestReminder/>}/>
        <Route path='/viewAnnualReturn' element={<AnnualReturn/>}/>
        <Route path='/viewDeletedEnquiries' element = {<DeletedEnquiries/>}/>
        






        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Route>
      <Route path="/enquiry" element={<AddCustomer />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;