import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import Select from "react-select"
import PreviewDocumntes from "../../components/PreviewDocumntes";



const ApplyAgent = ({ open, onClose, agentData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({
        Certificate_Name: '',
        Customer_Company: '',
        Remarks: '',
    });    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [certificateOption, setCertificateType] = useState([]);
    const [IsModal, setIsModal] = useState(null);


    const showModal = () => {
        setIsModal(true);
        //handleClose();
      };
      const closed = () => {
        setIsModal(false);
      };

  
    const [agent, setAgent] = useState({
        Certificate_Id: "",
        Certificate_Name: "",
        Company_Name:"",
        Remarks: ""
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    useEffect(() => {
        fetchData();
    }, [userData]);

    const fetchData = async () => {
        showLoader();
        try {
            const certificateType = await axios.get(`/getCertificateType`);
            const Option = certificateType.data.map((option) => ({
                label: option.Certificate_Name,
                value: option.Sr_No,
            }));
            setCertificateType(Option);
            // if (userData?.UserId) {
            //     const result = await axios.get(`/getCustomerById/${userData?.UserId}`);
            //     setCustomer(result.data);
            // }
        } catch (error) {
            console.error("Error fetching:", error);
        }
        hideLoader();
    };


    const UploadCustomerHandle = async (event) => {
        event.preventDefault();
    
        let validationErrors = {};
        let isValid = true;
    
        // Validate Certificate Type
        if (!agent.Certificate_Id) {
            validationErrors.Certificate_Name = 'Certificate Type is required';
            isValid = false;
        }
    
        // Validate Company Name (optional but you can enforce rules if needed)
        // if (!agent.Customer_Company) {
        //     validationErrors.Customer_Company = 'Company Name is required';
        //     isValid = false;
        // }
    
    
        // If validation fails, set error messages and prevent submission
        if (!isValid) {
            setErrors(validationErrors);
            return; // Early return if validation fails
        }
    
        // Prepare the object for submission
        const obj = {
            ...agent,
            Customer_Id: agentData?.Sr_No || userData?.Agent_Id, // Assuming `agentData.Sr_No` contains the customer ID
            Updated_By: `${userData?.First_Name} ${userData?.Last_Name}` || `${userData?.First_Name} ${userData?.Last_Name}`,
            Updated_By_Id: userData?.UserId || userData?.Agent_Id,
            Customer_Name: `${agentData?.First_Name} ${agentData?.Last_Name}` || `${userData?.First_Name} ${userData?.Last_Name}`, // Fallback if no agentData
        };
    
        showLoader(); // Assuming `showLoader()` triggers a loading indicator
    
        try {
            // Post data to the backend
            const res = await axios.post(`/addCustomerEnquiry`, obj);
    
            // On successful response
            Swal.fire("Success!", "Applied Successfully.", "success");
    
           handleClose();
           navigate('/viewEnquiry')

        } catch (err) {
            // Handle errors and show an error message
            console.error(err);
            Swal.fire({
                title: "Error",
                text: "Failed to Apply. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });
        } finally {
            hideLoader(); // Hide loader after the request is completed
        }
    };
    


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >   <Modal.Body>
                <h5>Apply Certificate</h5>
                <hr className="p-0 mb-2" />
                <form onSubmit={UploadCustomerHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="Bank" className="col-form-label">
                                    Certificate Type<span style={{ color: "red" }}>*</span>
                                </label>
                                <Select type="text" name="Bank" id="Bank" className="selecting"
                                    options={certificateOption} 
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: state.isSelected ? 'white' : 'black',
                                            backgroundColor: state.isFocused ? 'blue' : 'white',
                                            '&:hover': {
                                                backgroundColor: 'blue', 
                                                color: 'white', 
                                            },
                                        }),
                                    }}
                                    onChange={(e) =>
                                        setAgent({
                                            ...agent,
                                            Certificate_Id: e.value,
                                            Certificate_Name: e.label,
                                        })
                                    }
                                />
                                <p className="text-danger">{errors.Certificate_Name}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="Customer_Company" className="col-form-label">
                                    Company Name
                                </label>
                                <input
                                    type="text"
                                    id="Customer_Company"
                                    className="form-control"
                                    placeholder="Enter Company Name"
                                    onChange={(e) =>
                                        setAgent({
                                            ...agent,
                                            Customer_Company: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <p className="text-danger">{errors.Customer_Company}</p>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="Remarks" className="col-form-label">
                                    Remarks
                                </label>
                                <textarea
                                    id="Remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    onChange={(e) =>
                                        setAgent({
                                            ...agent,
                                            Remarks: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <p className="text-danger">{errors.Remarks}</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary btn-sm mt-2" type="submit">
                                Apply
                            </button>
                        </div>
                    </div>
             </form>
   {/* 
                {IsModal && (
        <PreviewDocumntes
          open={IsModal}
          onClose={closed}
          customer={customer}
        />
      )} */}
                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default ApplyAgent;
