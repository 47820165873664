import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';



const AssignEnquiry = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [check, setCheck] = useState();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [staffData, setStaffData] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [completedTill, setCompletedTill] = useState('');
    const [errors, setErrors] = useState({
      optionError: '',
      staffError: '',
      vendorError: '',
      dateError: ''
    });


    

    

  
    // Function to load staff data
    const loadStaffData = async (department) => {
      showLoader();
      try {
        const result = await axios.get(`/getUsersByDepartment?OrgId=${userData?.Org_Id}&Department=${department}`);
        setStaffData(result.data || []);
      } catch (error) {
        console.error("Error fetching staff data: ", error);
        alert("Failed to load staff data. Please try again later.");
      } finally {
        hideLoader();
      }
    };
  
    // Function to load vendor data
    const loadVendorData = async () => {
      showLoader();
      try {
        const result = await axios.get(`/getVendorCertificates/${updateData?.Certificate_Id}`);
        setVendorData(result.data.vendorCertificates || []);
        console.log(result.data.vendorCertificates);
        
      } catch (error) {
        console.error("Error fetching staff data: ", error);
        alert("Failed to load staff data. Please try again later.");
      } finally {
        hideLoader();
      }
    };


    const loadDepartments = async () => {
      showLoader();
      try {
        const result = await axios.get(`/getAllDepartments?OrgId=${userData?.Org_Id}`);
        setDepartments(result.data || []);
      } catch (error) {
        console.error("Error fetching staff data: ", error);
        alert("Failed to load staff data. Please try again later.");
      } finally {
        hideLoader();
      }
    };


      // Handle dropdown change
  const handleDepartmentChange = (e) => {
    const department = e.target.value
    setSelectedDepartment(e.target.value);
    loadStaffData(department);
    setErrors({ ...errors, optionError: "" }); // Clear error if any
  };

    

    const validate = () => {
        const newErrors = {
          optionError: '',
          staffError: '',
          vendorError: '',
          dateError: ''
        };
    
        // Validate 'Assign To' option
        if (!selectedOption) {
          newErrors.optionError = 'Please select an option';
        }
    
        // Validate selected staff or vendor based on the selected option
        if (selectedOption === 'Staff' && !selectedStaff) {
          newErrors.staffError = 'Please select a staff member';
        }
    
        if (selectedOption === 'Vendor' && !selectedVendor) {
          newErrors.vendorError = 'Please select a vendor';
        }
    
        // Validate the 'Completed Till' date
        if (!completedTill) {
          newErrors.dateError = 'Please select a date';
        }
    
        setErrors(newErrors);
        return !newErrors.optionError && !newErrors.staffError && !newErrors.vendorError && !newErrors.dateError;
      };
  
    // Handle change in the first dropdown (Assign To)
    const handleAssignToChange = (e) => {
      const value = e.target.value;
      setSelectedOption(value);
  
      if (value === 'Vendor') {
        loadVendorData();
      } else if (value === 'Staff') {
        loadDepartments();
      }
    };

    const handleDateChange = (e) => {
        setCompletedTill(e.target.value); // Update state with selected date
      };
    
  
    // Handle selection of Staff
    const handleStaffChange = (e) => {
        const selectedStaff = staffData.find(staff => staff.UserId === parseInt(e.target.value));
        setSelectedStaff(selectedStaff);
        console.log(selectedStaff);
        
      };
    
      const handleVendorChange = (e) => {
        const selectedVendor = vendorData.find(vendor => vendor.Vendor_Id === parseInt(e.target.value));
        setSelectedVendor(selectedVendor);
        console.log(selectedVendor);
        
      };

    // Handle form submission and append selected values to FormData
    const handleSubmit = async (e) => {
      showLoader();
      e.preventDefault();
          if (!validate()) {
            Swal.fire({
              icon: "error",
              title: "Validation Failed",
              text: "Please fill all required fields correctly.",
            });
            hideLoader();  // Ensure loader is hidden on validation failure
            return;
          }
    
      const formData = new FormData();
    
      // Add Vendor or Staff data to the FormData
      formData.append('Certificate_Name',updateData?.Certificate_Name)
      formData.append('Company_Name',updateData?.Company_Name)
      formData.append(
        'Customer_Company',
        updateData?.Customer_Company && updateData.Customer_Company !== 'null'
          ? updateData.Customer_Company
          : ''
      );
      formData.append('Enquiry_Id',updateData?.Enquiry_Id)
      formData.append('Role', updateData?.Role)
      if (selectedOption === 'Staff' && selectedStaff) {
        formData.append('Staff_Id', selectedStaff.UserId);
        formData.append('Staff_Name', `${selectedStaff.First_Name} ${selectedStaff.Last_Name}`);
        formData.append('Staff_Email', selectedStaff.Email);
        formData.append('Staff_Mobile', selectedStaff.Mobile);
      } else if (selectedOption === 'Vendor' && selectedVendor) {
        formData.append('Vendor_Id', selectedVendor.Vendor_Id);
        formData.append('Vendor_Name', selectedVendor.Name);
        formData.append('Staff_Id', selectedVendor.EPR_Staff_Id);
        formData.append('Staff_Name', selectedVendor.EPR_Staff_Name);
        formData.append('EPR_Staff_Email', selectedVendor.Staff_Email);
        formData.append('EPR_Staff_Mobile', selectedVendor.Staff_Mobile);
        formData.append('Amount', selectedVendor.Amount);
        formData.append('Staff_1_Email',selectedVendor.Staff_1_Email)
        formData.append('Staff_2_Email',selectedVendor.Staff_2_Email)
        formData.append('Staff_1_Mobile',selectedVendor.Staff_1_Mobile)
        formData.append('Staff_2_Mobile',selectedVendor.Staff_2_Mobile)
        formData.append('Vendor_Email', selectedVendor.Email);
        formData.append('Vendor_Mobile', selectedVendor.Mobile);

  
      }
    
      // Add Completion_Date from user input
      if (completedTill) {
        formData.append('Completion_Date', completedTill); // Ensure `completedTill` comes from the date input
      }
  
  
      showLoader();
  
      try {
          const response = await axios.put(
              `/assignEnquries/${updateData?.Enquiry_Sr_No}`,
              formData,
              {
                headers: {
                  'Content-Type': 'application/json', // Use multipart/form-data if needed
                },
              }
          );      
        if (response.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Enquiry Assigned successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            handleClose();
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error('Error submitting data:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Failed to submit data. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        hideLoader();
      }
  };
  
      

      

  

    const handleClose = () => {
        setShow(false);
        onClose();
    };




    

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Assign Enquiry - ({updateData.Certificate_Name||""})</h5>
                    <div className="col-md-6 text-end d-none">
                     
                    </div>
                </div>
                <hr className="p-0 mb-1" />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className={selectedOption === 'Staff' ? "col-md-3" : "col-md-4"}>
  <div className="form-group mb-3">
    <label htmlFor="assignTo" className="form-label">
      Assign To<span style={{ color: "red" }}>*</span>
    </label>
    <select
      className="form-select"
      name="assignTo"
      id="assignTo"
      value={selectedOption}
      onChange={handleAssignToChange}
    >
      <option value="">Select an Option</option>
      {userData?.Role === "Agent" ? (
        <option value="Staff">Staff</option> // Only "Staff" option for Staff users
      ) : (
        <>
          <option value="Vendor">Vendor</option>
          <option value="Staff">Staff</option>
        </>
      )}
    </select>
    {errors.optionError && <p className="text-danger mt-2">{errors.optionError}</p>}
  </div>
</div>

      {selectedOption === 'Staff' && (
  <div className="col-md-3">
    <div className="form-group mb-3">
      <label htmlFor="assignTo" className="form-label">
        Departments
      </label>
      <select
        className="form-select"
        name="Departments"
        id="Departments"
        value={selectedDepartment}
        onChange={handleDepartmentChange}
      >
        <option value="">Select a Department</option>
        {departments.map((dept, index) => (
          <option key={index} value={dept.Department}>
            {dept.Department}
          </option>
        ))}
      </select>
      {errors.optionError && (
        <p className="text-danger mt-2">{errors.optionError}</p>
      )}
    </div>
  </div>
)}


      {/* Second Dropdown: Display Staff or Vendor based on the first selection */}
      <div className={selectedOption === 'Staff' ? "col-md-3" : "col-md-4"}>
        <div className="form-group mb-3">
          <label htmlFor="assignStaff" className="form-label">
            Available {selectedOption === 'Vendor' ? 'Vendors' : 'Staff'}<span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-select"
            name="assignStaff"
            id="assignStaff"
            onChange={selectedOption === 'Staff' ? handleStaffChange : handleVendorChange}
            value={selectedOption === 'Staff' ? selectedStaff?.UserId : selectedVendor?.Vendor_Id || ''}
          >
            <option value="">Select a {selectedOption === 'Vendor' ? 'Vendor' : 'Staff'}</option>
            {(selectedOption === 'Staff' ? staffData : vendorData).map((item, index) => (
              <option key={index} value={item.UserId || item.Vendor_Id}>
                {item.First_Name ? `${item.First_Name} ${item.Last_Name}` : item.Name}
              </option>
            ))}
          </select>
          {errors.optionError && <p className="text-danger mt-2">{errors.optionError}</p>}
          {errors.staffError && <p className="text-danger mt-2">{errors.staffError}</p>}
          {errors.vendorError && <p className="text-danger mt-2">{errors.vendorError}</p>}


        </div>
      </div>
      <div className={selectedOption === 'Staff' ? "col-md-3" : "col-md-4"}>
        <div className="form-group mb-3">
          <label htmlFor="assignedOn" className="form-label">
            Completion Date <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="date"
            className="form-control"
            id="assignedOn"
            name="assignedOn"
            value={completedTill} // Bind state to input value
            onChange={handleDateChange} // Update state on change
          />
          {errors.dateError && (
            <p className="text-danger mt-2">{errors.dateError}</p>
          )}
        </div>
      </div>
                 </div>
                    <div className="row justify-content-center ">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                            Assign Enquiry
                            </button>
                        </div>
                    </div>
                </form>

                {loader}
            </Modal.Body>

            {/* <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div> */}

        </Modal>
    );
}
export default AssignEnquiry;
