import React, { useState,useEffect } from "react";
import axios, { apiUrl } from "../../components/axios";
import { useUser } from "../../components/UserContext";
import Swal from 'sweetalert2';
import useFullPageLoader from "../../components/useFullPageLoader";
import { useParams, useNavigate, Link } from "react-router-dom";
import Designation from "../Customer/Designation";

const AddAgents = () => {

  const { userData } = useUser();   
  const [errors, setErrors] = useState({});
  const [staffData, setStaffData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [agent, setAgent] = useState([])
  const {Sr_No} = useParams()
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [Modaldata, setModalData] = useState();
  const [designation,setDesignation] = useState([])
  

  const open = (e) => {
    setModalData(e);
    setIsModal(true);
  };

  const closed = () => {
    setIsModal(false);
    loadDesignation();
  };

  const [formData, setFormData] = useState({
    Company_Name: "",
    Name: "",
    Mobile: "",
    Telephone: "",
    Email: "",
    GST_No: "",
    Address: "",
    Staff_1_Name: "",
    Staff_1_Mobile: "",
    Staff_1_Email: "",
    Staff_2_Name: "",
    Staff_2_Mobile: "",
    Staff_2_Email: "",
    Agent_Designation:"",
    Staff_1_Designation:"",
    Staff_2_Designation:"",
    Purchase_Data:"",
    VisitingCardBack:"",
    VisitingCardFront:"",
    City:"",
    State:"",
    Company_Type:"",
    District:"",
    Reference:"",
    Other_Reference:"",
    Remarks:"",
  });  

  const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];


    const loadDesignation = async () => {
      showLoader(); // Show loader at the start of the function
    
      try {
        const result = await axios.get(`/getAllDesignations`);
        setDesignation(result.data.designations || []);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        hideLoader(); // Hide loader after the function finishes
      }
    };

    useEffect(() => {
      loadDesignation();
    }, []);
  

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleInputChangeForState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value, // Ensure the value updates
    });
  };
  
  const handleInputChangeForReference = (e) => {
    const { name, value } = e.target;
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,

      // ✅ If selecting a different option (not "Others"), clear Other_Reference
      ...(name === "Reference" && value !== "Others" && { Other_Reference: "" }),
    }));
  };






  useEffect(() => {
    if (Sr_No) {
      const fetchAgentDetails = async () => {
        showLoader(); // Trigger the loader function if needed
  
        try {
          const response = await axios.get(`/viewAgents/${Sr_No}`);
  
          if (response.status === 200) {
            const { data } = response.data; // Destructure to access `data` directly
    
            // Check if `data` contains the agent details
            if (data) {
              const rawagents = data; // Assign the agent details to `agents`
  
              // Sanitize null values: Replace null with an empty string
              const agents = Object.fromEntries(
                Object.entries(rawagents).map(([key, value]) => [key, value === null ? "" : value])
              );
              setAgent(agents);
  
              // Set the agent details in formData
              setFormData({
                Name: `${agents.First_Name} ${agents.Last_Name}` || "", // If name is null, set as empty string
                Company_Name: agents.Company_Name || "",
                Mobile: agents.Mobile || "",
                Telephone: agents.Telephone || "",
                Email: agents.Email || "",
                GST_No: agents.GST_No || "",
                Address: agents.Address || "",
                Staff_1_Name: agents.Staff_1_Name || "",
                Staff_1_Mobile: agents.Staff_1_Mobile || "",
                Staff_1_Email: agents.Staff_1_Email || "",
                Staff_2_Name: agents.Staff_2_Name || "",
                Staff_2_Mobile: agents.Staff_2_Mobile || "",
                Staff_2_Email: agents.Staff_2_Email || "",
                Agent_Designation: agents.Agent_Designation || "",
                Staff_1_Designation: agents.Staff_1_Designation || "",
                Staff_2_Designation: agents.Staff_2_Designation || "",
                Purchase_Data: agents.Purchase_Data || "",
                City: agents.City || "",
                District: agents.District || "",
                State: agents.State || "",
                Company_Type: agents.Company_Type || "",
                Reference: agents.Reference || "",
                Other_Reference: agents.Other_Reference || "",
                Remarks: agents.Remarks || "",
              });
            } else {
              console.error("Agent details not found in response data");
            }
          }
        } catch (error) {
          console.error('Error fetching agent details:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch agent details.',
          });
        } finally {
          hideLoader(); // Trigger the hide loader function if needed
        }
      };
  
      fetchAgentDetails();
    }
  }, [Sr_No]);
  
  


  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    };

    

    const validate = () => {
      let tempErrors = {};

      // Required field validations
      if (!formData.Company_Name) tempErrors.Company_Name = "Company Name is required.";
      if (!formData.Name) tempErrors.Name = "Vendor Name is required.";
      if (!formData.Mobile) {
        tempErrors.Mobile = "Mobile number is required.";
      } else if (!/^\d{10}$/.test(formData.Mobile)) {
        tempErrors.Mobile = "Mobile number must be 10 digits.";
      }
      

      if (!formData.Email && !/\S+@\S+\.\S+/.test(formData.Email)) {
        tempErrors.Email = "Invalid email format.";
      }

      if (!formData.GST_No && !/^\d{15}$/.test(formData.GST_No)) {
        tempErrors.GST_No = "GST Number must be 15 characters.";
      }

      // Staff validations

      
      if (formData.Staff_1_Name && !/^[a-zA-Z\s]+$/.test(formData.Staff_1_Name)) {
        tempErrors.Staff_1_Name = "Staff 1 Name is invalid."; // Example for name format validation
      }
      
      if (formData.Staff_1_Mobile && !/^[6-9]\d{9}$/.test(formData.Staff_1_Mobile)) {
        tempErrors.Staff_1_Mobile = "Staff 1 Mobile is invalid."; // Mobile number format validation
      }      
      
      if (formData.Staff_1_Email && !/\S+@\S+\.\S+/.test(formData.Staff_1_Email)) {
        tempErrors.Staff_1_Email = "Staff 1 Email is invalid."; // Email format validation
      }
      
      if (formData.Staff_2_Name && !/^[a-zA-Z\s]+$/.test(formData.Staff_2_Name)) {
        tempErrors.Staff_2_Name = "Staff 2 Name is invalid."; // Example for name format validation
      }
      
      if (formData.Staff_2_Mobile && !/^[6-9]\d{9}$/.test(formData.Staff_2_Mobile)) {
        tempErrors.Staff_2_Mobile = "Staff 2 Mobile is invalid."; // Mobile number format validation
      }
      
      if (formData.Staff_2_Email && !/\S+@\S+\.\S+/.test(formData.Staff_2_Email)) {
        tempErrors.Staff_2_Email = "Staff 2 Email is invalid."; // Email format validation
      }

      if(!formData.Agent_Designation){
        tempErrors.Agent_Designation = "Designation is required."
      }
      
      

      // Address validation
    // if (!formData.Address) tempErrors.Address = "Address is required.";

      setErrors(tempErrors);

      return Object.keys(tempErrors).length === 0; // Returns true if no errors
    };



    async function fetchLastCustomerId() {
      try {
        // Make the GET request to fetch the last customer ID
        const response = await axios.get('/getCustomerId');
        
        // Assuming the response contains a field 'lastCustomerId' that has the format 'EPR-XXXX'
        const lastCustomerId = response.data;
        
    
        if (!lastCustomerId) {
          throw new Error('No customer ID found');
        }
    
        // Extract the number part of the last customer ID
        const lastNumber = parseInt(lastCustomerId.split("-")[1]); // E.g., from 'EPR-0001', extract '0001'
        
        if (isNaN(lastNumber)) {
          throw new Error('Invalid customer ID format');
        }
    
        // Increment the number to get the next customer ID
        const nextNumber = lastNumber + 1;
    
        // Format the next customer ID with leading zeros (4 digits)
        const nextCustomerId = `EPR-${String(nextNumber).padStart(4, "0")}`;
        
        return nextCustomerId;
      } catch (error) {
        console.error('Error fetching last customer ID:', error);
        return 'EPR-0001'; // Default to 'EPR-0001' if there's an error or no customer ID exists
      }
    }
    



    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Show loader
      showLoader();
    
      if (!validate()) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Failed',
          text: 'Please fill all required fields correctly.',
        });
        hideLoader();
        return;
      }
    
      try {
        // Fetch the next customer ID
        const customerId = await fetchLastCustomerId();
    
        const FormDataPayload = new FormData();
        FormDataPayload.append('CustomerId', customerId); // Use the newly generated customer ID
        FormDataPayload.append('Name', formData.Name);
        FormDataPayload.append('Company_Name', formData.Company_Name);
        FormDataPayload.append('Mobile', formData.Mobile);
        FormDataPayload.append('Telephone', formData.Telephone);
        FormDataPayload.append('Email', formData.Email);
        FormDataPayload.append('GST_No', formData.GST_No);
        FormDataPayload.append('Address', formData.Address);
        FormDataPayload.append('Staff_1_Name', formData.Staff_1_Name);
        FormDataPayload.append('Staff_1_Mobile', formData.Staff_1_Mobile);
        FormDataPayload.append('Staff_1_Email', formData.Staff_1_Email);
        FormDataPayload.append('Staff_2_Name', formData.Staff_2_Name);
        FormDataPayload.append('Staff_2_Mobile', formData.Staff_2_Mobile);
        FormDataPayload.append('Staff_2_Email', formData.Staff_2_Email);
        FormDataPayload.append('Org_Id', userData?.Org_Id);
        FormDataPayload.append('Added_By_Id', userData?.UserId);
        FormDataPayload.append('Added_By', `${userData.First_Name} ${userData.Last_Name}`);
        FormDataPayload.append('Agent_Designation', formData.Agent_Designation)
        FormDataPayload.append('Staff_1_Designation', formData.Staff_1_Designation)
        FormDataPayload.append('Staff_2_Designation', formData.Staff_2_Designation)
        FormDataPayload.append('Purchase_Data', formData.Purchase_Data)
        FormDataPayload.append('Company_Type', formData.Company_Type)
        FormDataPayload.append('City', formData.City)
        FormDataPayload.append('State', formData.State)
        FormDataPayload.append('District', formData.District)
        FormDataPayload.append('Reference', formData.Reference)
        FormDataPayload.append('Other_Reference', formData.Other_Reference)
        FormDataPayload.append('Remarks', formData.Remarks)

        if (formData.VisitingCardFront instanceof File) {
          FormDataPayload.append('VisitingCardFront', formData.VisitingCardFront || "");
        }
        if (formData.VisitingCardBack instanceof File) {
          FormDataPayload.append('VisitingCardBack', formData.VisitingCardBack || "");
        }
    
        const endpoint = Sr_No ? `/editAgent/${Sr_No}` : '/addAgents';
        const method = Sr_No ? 'put' : 'post';
    

    
        const response = await axios({
          method,
          url: endpoint,
          data: FormDataPayload,
          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        });
    
        if (response.status === 200 || response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: Sr_No ? 'Agent Updated Successfully' : 'Agent Added Successfully',
            text: `Agent ${Sr_No ? 'updated' : 'added'} successfully.`,
          });
          setFormData({
            Name: '',
            Company_Name: '',
            Mobile: '',
            Telephone: '',
            Email: '',
            GST_No: '',
            Address: '',
            Staff_1_Name: '',
            Staff_1_Mobile: '',
            Staff_1_Email: '',
            Staff_2_Name: '',
            Staff_2_Mobile: '',
            Staff_2_Email: '',

          });
    
          navigate(`/viewAgents`);
    
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Submission Failed',
            text: 'Failed to save the Agent. Please try again.',
          });
        }
      } catch (error) {
        console.error('Error while submitting form:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
        });
      } finally {
        hideLoader();
      }
    };
    
  
    





  


  

  





  return (
    <div className="row">
  <div className="col-12">
    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
    <h4 className="page-title">{Sr_No ? 'Update Agent' : 'Add Agent'}</h4>
    </div>
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          {/* Vendor Details Section */}
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Agent Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Name" className="form-label">
                  Agent Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  className="form-control"
                  placeholder="Enter Agent Name"
                  required
                  onChange={handleChange}
                  value={formData.Name}
                />
                  {errors.Name && <small className="text-danger">{errors.Name}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Company_Name" className="form-label">
                  Company Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Company_Name"
                  name="Company_Name"
                  className="form-control"
                  placeholder="Enter Company Name"
                  required
                  onChange={handleChange}
                  value={formData.Company_Name}
                />
              {errors.Company_Name && <small className="text-danger">{errors.Company_Name}</small>}       
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Mobile" className="form-label">
                  Mobile<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  id="Mobile"
                  name="Mobile"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  required
                  onChange={handleChange}
                  value={formData.Mobile}
                />
            {errors.Mobile && <small className="text-danger">{errors.Mobile}</small>}         
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <div className="d-flex justify-content-between align-items-center">
      <label htmlFor="Agent_Designation" className="form-label mb-0">
        Designation<span style={{ color: "red" }}>*</span>
      </label>
      <Link
        onClick={() => {
          // Add your logic here for the button action
          open("Agent");
        }}
      >
        Add
      </Link>
    </div>
    <select
      className="form-select"
      id="Agent_Designation"
      value={formData.Agent_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Vendor Designation"
    >
      <option value="" disabled>Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
    {errors.Agent_Designation && <small className="text-danger">{errors.Agent_Designation}</small>}
  </div>
</div>

            
          </div>

          {/* Address Section */}
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Email" className="form-label">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="Email"
                  id="Email"
                  placeholder="Enter Email"
                  required
                  onChange={handleChange}
                  value={formData.Email}
                />
              {errors.Email && <small className="text-danger">{errors.Email}</small>}         
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="GST_No" className="form-label">
                  GST Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="GST_No"
                  id="GST"
                  placeholder="Enter GST Number"
                  onChange={handleChange}
                  value={formData.GST_No}
                />
                {errors.GST_No && <small className="text-danger">{errors.GST_No}</small>}  
              </div>
            </div>
            <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="Purchase_Data" className="form-label">
                        Purchase Data
                      </label>
                      <select className="form-select"
                      id="Purchase_Data" 
                      value={formData.Purchase_Data || ""} 
                      required 
                      aria-label="Select Purchase Data"

                      onChange={handleInputChange}
                      >
                        <option disabled label="Select Purchase Data" value=""></option>
                        <option label="Monthly" value="Monthly"></option>
                        <option label="Quarterly" value="Quarterly"></option>
                        <option label="Half-Yearly" value="Half-Yearly"></option>
                        <option label="Annually" value="Annually"></option>
                      </select>
                     
                    </div>
                  </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Telephone" className="form-label">
                  Telephone
                </label>
                <input
                  type="text"
                  id="Telephone"
                  name="Telephone"
                  className="form-control"
                  placeholder="Enter Telephone Number"
                  onChange={handleChange}
                  value={formData.Telephone}
                />
              </div>
            </div>
           
          </div>
          <div className="row">
        <div className="col-md-4">
          <label>City<span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            className="form-control"
            name="City"
            placeholder="Enter City"
            value={formData.City}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label>District<span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            className="form-control"
            name="District"
            placeholder="Enter District"
            value={formData.District}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label>State<span style={{ color: "red" }}>*</span></label>
          <select
            className="form-control"
            name="State"
            value={formData.State}
            onChange={handleInputChangeForState}
          >
            <option value="">Select State</option>
            {indianStates.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        
      </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="txtAddress" className="form-label">
                  Address
                </label>
                <textarea
                  id="txtAddress"
                  name="Address"
                  className="form-control"
                  placeholder="Enter Address"
                  onChange={handleChange}
                  value={formData.Address}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="Remarks" className="form-label">
                 Remarks
                </label>
                <textarea
                  id="Remarks"
                  name="Remarks"
                  className="form-control"
                  placeholder="Enter Remarks"
                  onChange={handleChange}
                  value={formData.Remarks}
                />
              </div>
            </div>
          </div>
          <div className="row">
          <div className={formData.Reference === "Others" ? "col-md-4" : "col-md-6"}>
          <label>Company Type</label>
          <input
            type="text"
            className="form-control"
            name="Company_Type"
            placeholder="Enter Company Type"
            value={formData.Company_Type}
            onChange={handleChange}
          />
        </div>
        <div className={formData.Reference === "Others" ? "col-md-4" : "col-md-6"}>
          <label>Mention Reference<span style={{ color: "red" }}>*</span></label>
          <select
            className="form-control"
            name="Reference"
            value={formData.Reference}
            onChange={handleInputChangeForReference}
          >
            <option value="">Select Reference</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Google">Google</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Others">Others</option>
          </select>
        </div>

        {/* ✅ Corrected Conditional Check */}
        {formData.Reference === "Others" && (
          <div className="col-md-4">
            <label>Mention Other Reference<span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              className="form-control"
              name="Other_Reference"
              value={formData.Other_Reference}
              onChange={handleChange}
              placeholder="Enter reference"
            />
          </div>
        )}
      </div>
          <div className="row">
            {/* Visiting Card Front */}
            <div className={agent.AgentVisitingCardFront ? "col-md-5" : "col-md-6"}>
              <div className="mb-3">
                <label htmlFor="VisitingCardFront" className="col-form-label">
                  Visiting Card (Front)
                </label>
                <input
                  type="file"
                  id="VisitingCardFront"
                  className="form-control"
                  onChange={(e) => {
                    setFormData({ ...formData, VisitingCardFront: e.target.files[0] });
                  }}
                />
              </div>
            </div>
            {agent.AgentVisitingCardFront && (
              <div className="col-md-1 mt-4">
                <a
                  style={{ color: "blue", cursor: "pointer" }}
                  href={`${apiUrl}/downloadAgentsVisitingCardFile/${agent.AgentVisitingCardFront}`}
                  target="_blank"
                  download={true}
                  rel="noopener noreferrer"
                  className="cursor-pointer"      >
                  <i className="ti ti-download"></i>
                </a>
              </div>
            )}
          
            {/* Visiting Card Back */}
            <div className={agent.AgentVisitingCardBack ? "col-md-5" : "col-md-6"}>
              <div className="mb-3">
                <label htmlFor="VisitingCardBack" className="col-form-label">
                  Visiting Card (Back)
                </label>
                <input
                  type="file"
                  id="VisitingCardBack"
                  className="form-control"
                  onChange={(e) => {
                    setFormData({ ...formData, VisitingCardBack: e.target.files[0] });
                  }}
                />
              </div>
            </div>
            {agent.AgentVisitingCardBack && (
              <div className="col-md-1 mt-4">
                <a
                  style={{ color: "blue", cursor: "pointer" }}
                  href={`${apiUrl}/downloadAgentsVisitingCardFile/${agent.AgentVisitingCardBack}`}
                  download={true}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer"
                >
                  <i className="ti ti-download"></i>
                </a>
              </div>
            )}
          </div>

          <div className="row ">
            <div className="col-12">
              <h5 className="section-title" style={{marginBottom:"-10px"}}>Staff 1 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff1Name"
                  name="Staff_1_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  onChange={handleChange}
                  value={formData.Staff_1_Name}
                />
               {errors.Staff_1_Name && <small className="text-danger">{errors.Staff_1_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_1_Mobile"
                  name="Staff_1_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={handleChange}
                  value={formData.Staff_1_Mobile}
                />
              {errors.Staff_1_Mobile && <small className="text-danger">{errors.Staff_1_Mobile}</small>}        
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Email" className="form-label">
                  Staff Email
                </label>
                <input
                  type="email"
                  id="Staff_1_Email"
                  name="Staff_1_Email"
                  className="form-control"
                  placeholder="Enter Staff Email"
                  onChange={handleChange}
                  value={formData.Staff_1_Email}
                />
              {errors.Staff_1_Email && <small className="text-danger">{errors.Staff_1_Email}</small>}  
              </div>
            </div>
            <div className="col-md-3">
            <div className="d-flex justify-content-between align-items-center">
      <label htmlFor="Staff_1_Designation" className="form-label mb-0">
        Designation<span style={{ color: "red" }}>*</span>
      </label>
      <Link
        onClick={() => {
          // Add your logic here for the button action
          open("Agent_Staff1");
        }}
      >
        Add
      </Link>
    </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Staff_1_Designation"
      value={formData.Staff_1_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Staff Designation"
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>
          </div>
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Staff 2 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff_2_Name"
                  name="Staff_2_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  onChange={handleChange}
                  value={formData.Staff_2_Name}
                />
              {errors.Staff_2_Name && <small className="text-danger">{errors.Staff_2_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_2_Mobile"
                  name="Staff_2_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={handleChange}
                  value={formData.Staff_2_Mobile}
                />
              {errors.Staff_2_Mobile && <small className="text-danger">{errors.Staff_2_Mobile}</small>}  
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff_2_Email" className="form-label">
      Staff Email
    </label>
    <input
      type="email"
      id="Staff_2_Email"
      name="Staff_2_Email" 
      className="form-control"
      placeholder="Enter Staff Email"
      onChange={handleChange}  
      value={formData.Staff_2_Email}  
    />
    {errors.Staff_2_Email && <small className="text-danger">{errors.Staff_2_Email}</small>}
  </div>
</div>
<div className="col-md-3">
<div className="d-flex justify-content-between align-items-center">
      <label htmlFor="Staff_2_Designation" className="form-label mb-0">
        Designation<span style={{ color: "red" }}>*</span>
      </label>
      <Link
        onClick={() => {
          // Add your logic here for the button action
          open("Agent_Staff2");
        }}
      >
        Add
      </Link>
    </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Staff_2_Designation"
      value={formData.Staff_2_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Staff Designation"
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>

          </div>
      
          <div className="row justify-content-center">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="submit" className="btn btn-primary" >
    {Sr_No ? 'Update' : 'Submit'}
    </button>
  </div>
</div>


        </form>
      </div>
    </div>
    {isModal && (
          <>
            <Designation
              open={isModal}
              onClose={closed}
              Modaldata={Modaldata}
            />
          </>
        )
        }
    {loader}
  </div>
    </div>

  );
};

export default AddAgents;
