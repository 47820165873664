import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { formatDate } from "../../components/dateFormate";


const UploadCertificate = ({ open, onClose, data }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    onClose();
  };


  const columns = [
    {
      name: "Application Date",
      selector: (row) => formatDate(row.Added_On),
      sortable: true,
    },
          {
        name: "Certificate",
        selector: (row) => row.Certificate_Name,
      },
    {
      name: "ID",
      selector: (row) => row.CustomerId,
    },
    {
        name: "Company Name",
        selector: (row) => row.Company_Name,
      },
    {
      name: "Name",
      selector: (row) => `${row.First_Name} ${row.Last_Name}`,
      sortable: true,
    },

      
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
    },
    {
        name: "Address",
        selector: (row) => row.Address,
      },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };



  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("Customer haven't Uploaded Documents", 20, 10);

    const columns = ["Date", "Customer Id", "Name", "Company Name", "Mobile", "Certificate", "DOB", "Email", "Address"];

    const rows = data.map((row) => [
      formatDate(row.Added_On),
      row.CustomerId,
      `${row.First_Name} ${row.Last_Name}`,
      row.Company_Name,
      row.Mobile,
      row.Certificate_Name,
      row.DOB ? formatDate(row.DOB) : "--",
      row.Email ? row.Email : "--",
      row.Address ? row.Address : "--"
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Customer Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Date", "Customer Id", "Name", "Company Name", "Mobile", "Certificate", "DOB", "Email", "Address"];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        formatDate(row.Added_On),
        row.CustomerId,
        `${row.First_Name} ${row.Last_Name}`,
        row.Company_Name,
        row.Mobile,
        row.Certificate_Name,
        row.DOB ? formatDate(row.DOB) : "--",
        row.Email ? row.Email : "--",
        row.Address ? row.Address : "--",
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Customer Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Customer Details.xlsx");
  };

//   function handleFilter(event) {
//     const filterValue = event.target.value.toLowerCase();
//     const filteredData = dataa.filter((row) => {
//       return (
//         (`${row.First_Name} ${row.Last_Name}` &&
//           `${row.First_Name} ${row.Last_Name}`
//             .toLowerCase()
//             .includes(filterValue)) ||
//         (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
//         (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
//         (row.Address && row.Address.toLowerCase().includes(filterValue))
//         ||
//         (row.CustomerId && row.CustomerId.toLowerCase().includes(filterValue))
//         ||
//         (row.Added_On && formatDate(row.Added_On).toLowerCase().includes(filterValue))
//         ||
//         (row.Certificate_Name && row.Certificate_Name.toLowerCase().includes(filterValue))
//         ||
//         (row.Company_Name && row.Company_Name.toLowerCase().includes(filterValue))
//       );
//     });
//     setStaffData(filteredData);
//   }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      style={{ boxShadow: "none !important" }}
    >
      <Modal.Body>
        <h5>Customer haven't Uploaded Documents</h5>
        <hr className="p-0 mb-1" />
        <div>
        <div className="card">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                {/* <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                /> */}
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              </div>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
          
            </div>
        {loader}
      </Modal.Body>
    </Modal>
  );
}
export default UploadCertificate;
