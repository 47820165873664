import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "./axios";
import useFullPageLoader from "./useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "./UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import OtpInput from "react-otp-input";




const ForgetPassword = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [isSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState("");

    const [customer, setCustomer] = useState({
        Mobile: "",
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
    
        const validationErrors = {};

        if (!/^([6-9][0-9]{9,11})$/.test(customer.Mobile)) {
            validationErrors.Mobile = "Invalid Mobile Number";
          }

        
        if (Object.keys(validationErrors).length === 0) {
          showLoader();
         await axios
            .post(`/checkPassword`, customer)
            .then((res) => {
              setIsOtpSend(true)
              hideLoader();
            })
            .catch((err) => {
              if (err.response && err.response.status === 400) {
                Swal.fire({
                  title: "Error",
                  text: "Your mobile number is not registered.<br>Please enter a correct mobile number!",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              } else {
                console.error(err);
                Swal.fire({
                  title: "Error",
                  text: "Server is Busy!!!. Please try again later.",
                  icon: "error",
                  confirmButtonText: "OK",
                });}
              hideLoader();
            });
        } else {
          setErrors(validationErrors);
        }
      };

      const [showResend, setShowResend] = useState(false);
      const [seconds, setSeconds] = useState(30);
    
      useEffect(() => {
        const interval = setInterval(() => {
          if (seconds === 0) {
            setShowResend(true);
            clearInterval(interval);
          } else {
            setSeconds((prevSeconds) => prevSeconds - 1);
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [seconds]);
    
      const handleResend = (event) => {
        if (event) {
          event.preventDefault();
          handleSubmit(event)
        }
        setSeconds(30);
        setShowResend(false);
      };
    
   
const submitHandler = async (event) => {
  event.preventDefault();
  
  const Verified = {
    OTP: otp,
    Mobile: customer.Mobile
  };

  const validation = {};

  if (!otp || otp.length !== 4 || !/^\d+$/.test(otp)) {
    validation.OTP = "Please enter a valid 4-digit OTP!";
  }

  if (Object.keys(validation).length === 0) {
    showLoader();

    try {
  await axios.put(`/verifyOTP`, Verified);
      Swal.fire({
        title: 'Success!',
        text: 'Verified Successfully!!',
        html: 'Password sent on your mobile number.',
        icon: 'success'
      });
      handleClose();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        Swal.fire({
          title: "Error",
          text: "Wrong OTP. Please enter the correct OTP!",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else if (err.code === 'ECONNABORTED') {
        Swal.fire({
          title: "Error",
          text: "Request timed out. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          text: "Server Timeout!. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } finally {
      hideLoader();
    }
  } else {
    setErrors(validation);
  }
};


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="md"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
            <div className="row align-items-center">
    <h5 className="col-md-6 mb-0" style={{ marginBottom: '0', marginTop: '0' }}>Forgot Password?</h5>
    <hr className="p-0 mb-1 mt-2" />
</div>

               
            
                    {isSend ? (
                        <div className="row justify-content-center mt-2">
              <div className="col-12 col-md-5 text-center">
                <div className="form-group">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="form-control"
                        style={{ width: "40px" }}
                      />
                    )}
                  />
                </div>
                {errors.OTP && (
                  <div className="alert alert-danger">{errors.OTP}</div>
                )}
              </div>
              <div className="col-md-12 text-center mb-3">
                {seconds > 0 ? (
                  <div className="">{`Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds
                    }`}</div>
                ) : (
                  <a
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleResend(e)}
                  >
                    Resend OTP
                  </a>
                )}
              </div>
              <div className="col-md-12 text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={submitHandler}
                >
                  Submit OTP
                </button>
              </div>
            </div>
            ):(
                <form>
                    <div className="row">
                    <div className="col-md-12">
                    <div className="mb-1">
                      <label htmlFor="txtMobile" className="form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={customer.Mobile}
                        id="txtMobile"
                        className="form-control"
                        placeholder="Enter Your Registered Mobile Number" 
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setCustomer({ ...customer, Mobile: numericInput });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>

                
                    </div>
                         <div className="row justify-content-center mt-4">
                        <div className="col-md-3">
                            <button type="button" className="btn btn-md btn-primary" onClick={handleSubmit}>
                  Send OTP
                            </button>
                        </div>
                    </div>
                    </form>
            )}
             

                {loader}
            </Modal.Body>

            {/* <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div> */}

        </Modal>
    );
}
export default ForgetPassword;
