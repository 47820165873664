import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import axios from "./axios";
import Swal from "sweetalert2";
import useFullPageLoader from "./useFullPageLoader";

export default function ChangePasswordModal({ isOpen, onClose, vendorId, agentId }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loader,showLoader,hideLoader] = useFullPageLoader();
  

  const handleSubmit = async () => {
  
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match");
      return;
    }
    setError("");
  
    // Determine which ID to use
    let endpoint = "";
    if (agentId) {
      endpoint = `/changePasswordForAgent/${agentId}`;
    } else if (vendorId) {
      endpoint = `/changePasswordForVendor/${vendorId}`;
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid request. No Agent or Vendor ID provided.",
      });
      console.log("Error: No Agent or Vendor ID provided");
      return;
    }
  
  
    try {
      showLoader();
      const response = await axios.put(endpoint, {
        currentPassword,
        newPassword,
        confirmPassword,
      },  { headers: { "Content-Type": "application/json" }, withCredentials: true });
      
  
      console.log("API Response:", response.data);
  
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Password changed successfully!",
      });
  
      // Clear inputs after success
      setCurrentPassword("");
      setConfirmPassword("");
      setNewPassword("");
      onClose();
    } catch (error) {
      console.error("Error updating password:", error);
      console.log("API Error Response:", error.response?.data);
  
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.error || "An error occurred. Please try again.",
      });
    } finally {
      hideLoader();
      console.log("Process completed");
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
        <TextField
          type="password"
          label="Current Password"
          fullWidth
          margin="dense"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <TextField
          type="password"
          label="New Password"
          fullWidth
          margin="dense"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          type="password"
          label="Confirm New Password"
          fullWidth
          margin="dense"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">Change Password</Button>
      </DialogActions>
      {loader}
    </Dialog>
  );
}  