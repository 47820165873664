import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from "@mui/material";
import { formatDate } from "./dateFormate";

const RenewalReminder = ({ open, onClose, data }) => {
  const { due_today = [], overdue = [] } = data; // Ensure both are always arrays
  
  

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          Renewal Reminder Alert!
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* Overdue Renewals Section */}
        {overdue.length > 0 && (
          <>
            <Typography variant="body1" color="error" fontWeight="bold" gutterBottom>
              ⚠️ Overdue Renewals:
            </Typography>
            <List>
            {overdue.map((item, index) => (
              <ListItem key={index} divider>
    <ListItemText
        primary={`Enquiry ID: ${item.Enquiry_Id ? item.Enquiry_Id : ""} | Company: ${item.Company_Name}`}
        secondary={`Certificate: ${item.Certificate_Name} - Issued On: ${formatDate(item.Certificate_Date)} | Renewal Date: ${formatDate(item.Renewal_Date)}`}
    />
</ListItem>

            ))}
            </List>
          </>
        )}

        {/* Due Today Renewals Section */}
        {due_today.length > 0 && (
          <>
            <Typography variant="body1" color="warning.main" fontWeight="bold" gutterBottom>
              📅 Due Today:
            </Typography>
            <List>
            {due_today.map((item, index) => (
              <ListItem key={index} divider>
    <ListItemText
        primary={`Enquiry ID: ${item.Enquiry_Id ? item.Enquiry_Id : ""} | Company: ${item.Company_Name}`}
        secondary={`Certificate: ${item.Certificate_Name} - Issued On: ${formatDate(item.Issued_On)} | Renewal Date: ${formatDate(item.Renewal_Date)}`}
    />
</ListItem>
            ))}
            </List>
          </>
        )}

        {/* No renewals message */}
        {due_today.length === 0 && overdue.length === 0 && (
          <Typography variant="body2" color="textSecondary">
            No renewals are due or overdue today.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenewalReminder;
