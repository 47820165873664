import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "./axios";
import useFullPageLoader from "./useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "./UserContext";
import { apiUrl } from "./axios";



const UpdateCustomerProfile = ({ open, onClose, customer }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);


    const handleClose = () => {
     window.location.reload();
        // setShow(false);
        // onClose();
    };


    useEffect(() => {
        fetchData();
    }, [userData, customer?.Certificate_Id]);



    const fetchData = async () => {
        showLoader();
        try {

            if (customer?.Certificate_Id) {
                const result = await axios.get(`/getCertificateDocByCerIdForCustomer?CerId=${customer?.Certificate_Id}`);
                setDocument(result.data);
            }
        } catch (error) {
            console.error("Error fetching:", error);
        }
        hideLoader();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Body>
                <h5>Required Documntes Sample Formates</h5>
                <hr className="p-0 mb-2" />
                <div className="row mt-3">
                    <table
                        className="table table-bordered table-hover table-responsive"
                        cellSpacing="0"
                        cellPadding="4"
                        align="Center"
                        rules="cols"
                        style={{
                            color: "Black",
                            backgroundColor: "White",
                            borderColor: "#DEDFDE",
                            borderWidth: "1px",
                            borderStyle: "None",
                            borderCollapse: "collapse",
                        }}
                    >
                        <tbody>
                            <tr align="center">
                                <th scope="col">Document</th>
                                <th scope="col">Sample Format</th>
                            </tr>
                            {documentName.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-center">
                                        {item.Doc_Name}
                                    </td>
                                    <td className="text-center">
                                        {item.Sample_Format ? (
                                            <>
                                                <a
                                                    style={{ color: "blue", cursor: 'pointer' }}
                                                    href={`${apiUrl}/downloadSampleFormat/${item.Sample_Format}`}
                                                    download={true}
                                                    className="cursor-pointer"
                                                >
                                                    <i className="ti ti-download"></i>
                                                </a>
                                            </>
                                        ) : (
                                            <>
                                                <i className="ti ti-download-off"></i>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default UpdateCustomerProfile;
