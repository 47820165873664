import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "./axios";
import useFullPageLoader from "./useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "./UserContext";




const UpdateCustomerProfile = ({ open, onClose }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = (e) => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        fetchData();
    };
    const [customer, setCustomer] = useState({
        CustomerId: "",
        First_Name: "",
        Last_Name: "",
        Company_Name: "",
        Reference: "",
        Mobile: "",
        Email: "",
        Address: ""
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    useEffect(() => {
        fetchData();
    }, [userData]);

    const fetchData = async () => {
        showLoader();
        try {
           
            if (userData?.UserId) {
                const result = await axios.get(`/getCustomerById/${userData?.UserId}`);
                setCustomer(result.data);
            }
        } catch (error) {
            console.error("Error fetching:", error);
        }
        hideLoader();
    };


    const UploadCustomerHandle = async (event) => {
        event.preventDefault();

        showLoader();

        // Send PUT request
        axios
            .put(`/updateCustomerProfile/${userData?.UserId}`, customer)
            .then((res) => {
                Swal.fire("Success!", "Your Profile Updated Successfully.", "success");
                handleClose();
                hideLoader();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Updated. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <h5>Your Profile</h5>
                <hr className="p-0 mb-2" />
                <form onSubmit={UploadCustomerHandle}>
                                    <div className="row">
                                    <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="CustomerId" className="form-label">
                                                    Customer Id
                                                </label>
                                                <input
                                                    type="text"
                                                    id="CustomerId"
                                                    className="form-control"
                                                    placeholder="Enter Customer Id"
                                                    disabled
                                                    value={customer.CustomerId}
                                                // required
                                                // onChange={(e) =>
                                                //   setCustomer({
                                                //     ...customer,
                                                //     CustomerId: e.target.value,
                                                //   })
                                                // }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="txtFname" className="form-label">
                                                    First Name<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="txtFname"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    required
                                                    disabled={!isEditing}
                                                    value={customer.First_Name}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            First_Name: e.target.value,
                                                        })
                                                    }
                                                />
                                                <p className="text-danger">{errors.First_Name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="txtLname" className="form-label">
                                                    Last Name<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="txtLname"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    required
                                                    disabled={!isEditing}
                                                    value={customer.Last_Name}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Last_Name: e.target.value,
                                                        })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Last_Name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="Company_Name" className="form-label">
                                                    Company Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="Company_Name"
                                                    className="form-control"
                                                    disabled={!isEditing}
                                                    value={customer.Company_Name}
                                                    placeholder="Enter Company Name"
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Company_Name: e.target.value,
                                                        })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Company_Name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="txtMobile" className="form-label">
                                                    Mobile<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    maxLength={10}
                                                    value={customer.Mobile}
                                                    id="txtMobile"
                                                    className="form-control"
                                                    placeholder="Enter Mobile Number"
                                                    required
                                                    disabled={!isEditing}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const numericInput = input.replace(/[^0-9]/g, "");
                                                        setCustomer({ ...customer, Mobile: numericInput });
                                                    }}
                                                />
                                                <p className="text-danger">{errors.Mobile}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="txtEmail" className="form-label">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="txtEmail"
                                                    className="form-control"
                                                    placeholder="Enter Email Id"
                                                    disabled={!isEditing}
                                                    value={customer.Email}
                                                    onChange={(e) =>
                                                        setCustomer({ ...customer, Email: e.target.value })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Email}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-none">
                                            <div className="mb-3">
                                                <label htmlFor="Reference" className="form-label">
                                                    Reference
                                                </label>
                                                <input
                                                    type="text"
                                                    id="Reference"
                                                    disabled={!isEditing}
                                                    value={customer.Reference}
                                                    className="form-control"
                                                    placeholder="Enter Reference"
                                                    onChange={(e) =>
                                                        setCustomer({ ...customer, Reference: e.target.value })
                                                    }
                                                />
                                                <p className="text-danger">{errors.Reference}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="txtAddress" className="form-label">
                                                    Address<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <textarea
                                                    type="Date"
                                                    id="txtAddress"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    required
                                                    rows={3}
                                                    value={customer.Address}
                                                    disabled={!isEditing}
                                                    onChange={(e) =>
                                                        setCustomer({
                                                            ...customer,
                                                            Address: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <p className="text-danger">{errors.Address}</p>
                                        </div>
                               
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            {!isEditing && (
                                                <button
                                                    className="btn btn-primary btn-sm mt-2"
                                                    onClick={handleEditClick}
                                                >
                                                    Edit
                                                </button>
                                            )}{" "}
                                            &nbsp;

                                            {isEditing && (
                                                <button
                                                    className="btn btn-primary btn-sm mt-2"
                                                    onClick={

                                                        handleCancelEdit
                                                    }
                                                >
                                                    Close
                                                </button>
                                            )}
                                            &nbsp;
                                            {isEditing && (
                                                <button className="btn btn-primary btn-sm mt-2" type="submit">
                                                    Save Changes
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>

                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default UpdateCustomerProfile;
