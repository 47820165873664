import React, { useState } from "react";
import axios from '../components/axios';

function TestReminder() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const triggerTestReminder = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("New password and confirm password do not match.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const response = await axios.put("/changePassword/agent/337", { currentPassword, newPassword, confirmPassword }); // Replace with your backend URL
      if (response.data.success) {
        setMessage("Password changed successfully!");
      } else {
        setMessage("Failed to change password.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Change Password</h1>
      <input
        type="password"
        placeholder="Current Password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        style={{ padding: "10px", fontSize: "16px", marginBottom: "10px", display: "block", width: "80%", margin: "0 auto" }}
      />
      <input
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        style={{ padding: "10px", fontSize: "16px", marginBottom: "10px", display: "block", width: "80%", margin: "0 auto" }}
      />
      <input
        type="password"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={{ padding: "10px", fontSize: "16px", marginBottom: "10px", display: "block", width: "80%", margin: "0 auto" }}
      />
      <button
        onClick={triggerTestReminder}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          color: "white",
          backgroundColor: loading ? "gray" : "#007BFF",
          border: "none",
          borderRadius: "5px",
          cursor: loading ? "not-allowed" : "pointer",
        }}
        disabled={loading}
      >
        {loading ? "Processing..." : "Change Password"}
      </button>
      {message && <p style={{ marginTop: "20px", fontSize: "16px" }}>{message}</p>}
    </div>
  );
}

export default TestReminder;
