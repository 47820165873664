import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UploadAcknowledgement = ({ open, onClose, updateData }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();


  const [upload, setUpload] = useState({
    Message_Body: `
<p>Application Number: {ApplicationNumber}</p>
<p>Application Date: {ApplicationDate}</p>
<p>OTP Mobile Number: {MobileNumber}</p>
<p>Link: {Link}</p>` ,
    Attachment1: "",
    Attachment2: "",
    Attachment3: "",
    Application_Date: "",
    Application_Number: "",
    Username: "",
    Password: "",
    Link: "",
    Pending_Tasks:"",
    State: "",
    Mobile_Number:""

  });
  

  const indianStates = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    // Union Territories
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Ladakh', label: 'Ladakh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'CPCB' , label: "CPCB"},
  ];

  // useEffect(() => {
  //     loadData();
  // }, [updateData]);

  // const loadData = async () => {
  //     showLoader();
  //     try {
  //       await axios.get(`/getEnquiry/${updateData}`).then((res) => {

  //             console.log(res.data)
  //             const data = res.data[0];
  //             setUpload({
  //                 Message_Body: data.Message_Body? data.Message_Body:"",
  //                 Attachment1: data.Attachment1? data.Attachment1:"",
  //                 Attachment2: data.Attachment2? data.Attachment2:"",
  //                 Attachment3: data.Attachment3? data.Attachment3:"",
  //             });
  //         });

  //         hideLoader();
  //     } catch (error) {
  //         hideLoader();
  //         console.error("Error fetching data: ", error);
  //     }
  // };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  // const UploadAcknowledgementHandle = async (event) => {
  //   event.preventDefault();

  //   const formdata = new FormData();
  //   formdata.append("Message_Body", upload.Message_Body);
  //   formdata.append("Attachment1", upload.Attachment1);
  //   formdata.append("Attachment2", upload.Attachment2);
  //   formdata.append("Attachment3", upload.Attachment3);
  //   formdata.append("Customer_Id", updateData.Sr_No);
  //   formdata.append("Certificate_Name", updateData.Certificate_Name);

  //   const validationErrors = {};

  //   if (!upload.Message_Body.trim()) {
  //     validationErrors.Message_Body = "Message body field is required.";
  //   }

  //   if (Object.keys(validationErrors).length === 0) {
  //     showLoader();

  //     // Send PUT request
  //     await axios
  //       .put(`/uploadAcknowledgement/${updateData.Enquiry_Sr_No}`, formdata)
  //       .then((res) => {
  //         Swal.fire(
  //           "Success!",
  //           "Acknowledgement Uploaded Successfully.",
  //           "success"
  //         );
  //         handleClose();
  //         hideLoader();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         Swal.fire({
  //           title: "Error",
  //           text: "Failed to Upload. Please try again later.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //         hideLoader();
  //       });
  //   } else {
  //     setErrors(validationErrors);
  //   }
  // };

  // const editorConfiguration = {
  //     plugins: [CKFinder, ...ClassicEditor.builtinPlugins],
  //     ckfinder: {
  //         uploadUrl: `${apiUrl}/uploadCkEditor`
  //     },
  // };
 
  const UploadAcknowledgementHandle = async (event) => {
    event.preventDefault();

    const formdata = new FormData();
    formdata.append("Message_Body", upload.Message_Body);
    formdata.append("Attachment1", upload.Attachment1);
    formdata.append("Attachment2", upload.Attachment2);
    formdata.append("Attachment3", upload.Attachment3);
    formdata.append("Application_Date", upload.Application_Date);
    formdata.append("Application_Number", upload.Application_Number);
    formdata.append("Username", upload.Username);
    formdata.append("Password", upload.Password);
    formdata.append("Link", upload.Link);
    formdata.append("Mobile_Number", upload.Mobile_Number);
    formdata.append("Customer_Id", updateData?.Sr_No);
    formdata.append("Certificate_Name", updateData?.Certificate_Name);
    formdata.append("Company_Name", updateData?.Company_Name)
    formdata.append("Enquiry_Sr_No", updateData?.Enquiry_Sr_No)
    formdata.append("Enquiry_Id",updateData?.Enquiry_Id)
    formdata.append("Customer_Company", updateData?.Customer_Company || updateData?.Company_Name || "");
    formdata.append("Remarks", updateData?.Remarks ?? "")
    formdata.append("Certificate_Id", updateData?.Certificate_Id)
    formdata.append("Added_By", `${userData?.First_Name} ${userData?.Last_Name}` || `${userData?.vendorName}`)
    formdata.append("Added_By_Id", userData?.UserId || userData?.Vendor_Id)
    formdata.append("State", upload.State)
    formdata.append('Pending_Tasks', upload.Pending_Tasks)
    formdata.append("Updated_By", `${userData?.First_Name} ${userData?.Last_Name}` || `${userData?.vendorName}`)
    formdata.append("Updated_By_Id", userData?.UserId || userData?.Vendor_Id)
    formdata.append("Enquiry_Id", updateData?.Enquiry_Id)
    formdata.append("Staff_1_Email", updateData?.Staff_1_Email)
    formdata.append("Staff_2_Email", updateData?.Staff_2_Email)


    
    const validationErrors = {};
    if (!upload.Message_Body.trim()) {
      validationErrors.Message_Body = "Message body field is required.";
    }

    if (Object.keys(validationErrors).length === 0) {
      showLoader();

      try {
        await axios.put(
          `/uploadAcknowledgement/${updateData.Enquiry_Sr_No}`,
          formdata
        );
        Swal.fire(
          "Success!",
          "Acknowledgement Uploaded Successfully.",
          "success"
        );
        handleClose();
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Upload. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        hideLoader();
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const message = `
<p>Application Number: {ApplicationNumber}</p>
<p>Application Date: {ApplicationDate}</p>
<p>OTP Mobile Number: {MobileNumber}</p>
<p>Link: {Link}</p>`

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      style={{ boxShadow: "none !important" }}
    >
      <Modal.Body>
        <h5>Upload Acknowledgement - {`(${updateData.Certificate_Name})`}</h5>
        <hr className="p-0 mb-2" />
        <form onSubmit={UploadAcknowledgementHandle}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="Message_Body" className="form-label">
                  Message Body<span style={{ color: "red" }}>*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={message}
                  //config={editorConfiguration}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setErrors({ Message_Body: "" });
                    setUpload({
                      ...upload,
                      Message_Body: data,
                    });
                  }}
                />
                {errors && (
                  <p style={{ color: "red" }}>{errors.Message_Body}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Attachment1" className="form-label">
                  Application Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="Application-Date"
                  className="form-control"
                  value={upload.Application_Date}
                  onChange={(e) =>
                    setUpload({ ...upload, Application_Date: e.target.value })
                  }
                  placeholder="Date"
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Application-Number" className="form-label">
                  Application No.<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Attachment1"
                  className="form-control"
                  value={upload.Application_Number}
                  onChange={(e) =>
                    setUpload({ ...upload, Application_Number: e.target.value })
                  }
                  placeholder="Enter your Application Number..."
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Username" className="form-label">
                  Username<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  value={upload.Username}
                  onChange={(e) =>
                    setUpload({ ...upload, Username: e.target.value })
                  }
                  placeholder="Enter your Username..."
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Password" className="form-label">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Password"
                  className="form-control"
                  value={upload.Password}
                  onChange={(e) =>
                    setUpload({ ...upload, Password: e.target.value })
                  }
                  placeholder="Enter your password..."
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
  <div className="mb-2">
    <label htmlFor="State" className="col-form-label">
      State/CPCB<span style={{ color: "red" }}>*</span>
    </label>
    <select
      id="State"
      className="form-control"
      required
      value={upload.State || ""}
      onChange={(e) => setUpload({ ...upload, State: e.target.value })}
    >
      <option value="" disabled>
        Select a state
      </option>
      {indianStates.map((state) => (
        <option key={state.value} value={state.value}>
          {state.label}
        </option>
      ))}
    </select>
  </div>
</div>
            <div className="col-md-4">
  <div className="mb-3">
    <label htmlFor="Pending_Tasks" className="form-label">
      Pending Tasks
    </label>
    <select
      id="Pending_Tasks"
      className="form-control"
      value={upload.Pending_Tasks}
      onChange={(e) => setUpload({ ...upload, Pending_Tasks: e.target.value })}
    >
      <option value="">Select</option>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  </div>
</div>

            
            <div className="col-md-4">
  <div className="mb-3">
    <label htmlFor="mobileNumber" className="form-label">
      OTP Mobile Number<span style={{ color: "red" }}>*</span>
    </label>
    <input
      type="tel"
      id="mobileNumber"
      className="form-control"
      placeholder="Enter your Mobile Number..."
      value={upload.Mobile_Number}
      onChange={(e) =>
        setUpload({ ...upload, Mobile_Number: e.target.value })
      }
      required
    />
  </div>
</div>
<div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="Password" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  id="Link"
                  className="form-control"
                  value={upload.Link}
                  onChange={(e) =>
                    setUpload({ ...upload, Link: e.target.value })
                  }
                  placeholder="Enter the Link..."
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Attachment1" className="form-label">
                  Attachment1
                </label>
                <input
                  type="file"
                  id="Attachment1"
                  className="form-control"
                  capture="false"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setUpload({
                      ...upload,
                      Attachment1: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Attachment2" className="form-label">
                  Attachment2
                </label>
                <input
                  type="file"
                  id="Attachment2"
                  className="form-control"
                  capture="false"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setUpload({
                      ...upload,
                      Attachment2: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Attachment3" className="form-label">
                  Attachment3
                </label>
                <input
                  type="file"
                  id="Attachment3"
                  className="form-control"
                  capture="false"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setUpload({
                      ...upload,
                      Attachment3: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-2 col-sm-3 col-xs-3 col-3">
              <button type="submit" className="btn btn-md btn-primary ">
                Submit
              </button>
            </div>
          </div>
        </form>

        {loader}
      </Modal.Body>
    </Modal>
  );
};
export default UploadAcknowledgement;
