import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useUser } from "../../components/UserContext";

const UpdateCertificateDetails = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    
    const [customer, setCustomer] = useState({
        Renewal_Date: "",
        Username: "",
        Password: "",
        Updated_By: `${userData?.First_Name} ${userData?.Last_Name}`,
        Updated_By_Id: userData?.UserId,
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        showLoader();
        await axios
            .put(`/updateCertificateDetails/${updateData?.Enquiry_Sr_No}`, customer)
            .then(() => {
                Swal.fire("Success!", "Details Updated Successfully!", "success").then((result) => {
                    if (result.isConfirmed) {
                        handleClose();
                    }
                });
                hideLoader();
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Failed to update details. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Update Certificate Details - ({updateData.Certificate_Name || ""})</h5>
                </div>
                <hr className="p-0 mb-1" />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-2">
                                <label htmlFor="Renewal_Date" className="form-label">Renewal Date<span style={{ color: "red" }}>*</span></label>
                                <input type="date" id="Renewal_Date" className="form-control" value={customer.Renewal_Date} required onChange={(e) => setCustomer({ ...customer, Renewal_Date: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-2">
                                <label htmlFor="Username" className="form-label">Username<span style={{ color: "red" }}>*</span></label>
                                <input type="text" id="Username" className="form-control" placeholder="Enter Username" value={customer.Username} required onChange={(e) => setCustomer({ ...customer, Username: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-2">
                                <label htmlFor="Password" className="form-label">Password<span style={{ color: "red" }}>*</span></label>
                                <input type="password" id="Password" className="form-control" placeholder="Enter Password" value={customer.Password} required onChange={(e) => setCustomer({ ...customer, Password: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary">Update</button>
                        </div>
                    </div>
                </form>
                {loader}
            </Modal.Body>
        </Modal>
    );
};

export default UpdateCertificateDetails;
