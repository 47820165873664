import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import Select from "react-select"
import { apiUrl } from "../../components/axios";



const PurchaseData = ({ open, onClose, agentData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({
        Duration: '',
    });    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [certificateOption, setCertificateType] = useState([]);
    const [IsModal, setIsModal] = useState(null);
    const [staffData, setStaffData] = useState([]);
    


    const showModal = () => {
        setIsModal(true);
        //handleClose();
      };
      const closed = () => {
        setIsModal(false);
      };

  
    const [agent, setAgent] = useState({
        Purchase_File:"",
        Duration: "",
        Period:""
        });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


// Function to load staff data
    const loadStaffData = async () => {
        showLoader();
        try {
            const result = await axios.get(`/viewPurchaseData/${agentData?.Enquiry_Sr_No}`);
            setStaffData(result.data || []);
        } catch (error) {
            console.error("Error fetching staff data: ", error);
            alert("Failed to load staff data. Please try again later.");
        } finally {
            hideLoader();
        }
    };


        useEffect(() => {
            if (agentData?.Enquiry_Sr_No) {
                loadStaffData();
            }
        }, [agentData]);


        const UploadCustomerHandle = async (event) => {
          event.preventDefault();
      
          let validationErrors = {};
          let isValid = true;
      
          // Validate Purchase_File
          if (!agent.Purchase_File) {
              validationErrors.Purchase_File = "Upload Excel File is required.";
              isValid = false;
          }
      
          // Validate Period
          if (!agent.Period) {
              validationErrors.Period = "Purchase Data Period is required.";
              isValid = false;
          }
      
          // Set validation errors to state (if applicable)
          setErrors(validationErrors);
      
          if (!isValid) {
              return; // Exit early if validation fails
          }
      
          // Prepare FormData
          const formData = new FormData();
          formData.append("Enquiry_Id", agentData?.Enquiry_Sr_No);
          formData.append("Duration", agentData?.Purchase_Data);
          formData.append("Period", agent.Period);
          formData.append("Purchase_File", agent.Purchase_File);
          formData.append("Added_By", `${userData?.First_Name} ${userData?.Last_Name}`);
          formData.append("Added_By_Id", userData?.UserId || userData?.Agent_Id);
      
          showLoader();
      
          try {
              const res = await axios.post(`/sendPurchaseData`, formData, {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              });
      
              if (res.status === 200) {
                  Swal.fire("Success!", "Applied Successfully.", "success");
                  await loadStaffData();
                  setAgent("")
              } else {
                  Swal.fire({
                      title: "Error",
                      text: "Failed to apply. Unexpected server response.",
                      icon: "error",
                      confirmButtonText: "OK",
                  });
              }
          } catch (err) {
              console.error("Error during request:", err);
              Swal.fire({
                  title: "Error",
                  text: "Failed to Apply. Please try again later.",
                  icon: "error",
                  confirmButtonText: "OK",
              });
          } finally {
              hideLoader();
          }
      };
      
    
    
    
    


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        > 
          <Modal.Body>
                <h5>Send Purchase Data</h5>
                <hr className="p-0 mb-2" />
                <form onSubmit={UploadCustomerHandle}>
                    <div className="row">
                    <div className="col-md-4">
  <div className="mb-2">
    <label htmlFor="Purchase_Data" className="col-form-label">
      Purchase Data Duration
    </label>
    <div style={{ position: "relative" }}>
      <input
        type="text"
        id="Duration"
        className="form-control"
        value={agentData?.Purchase_Data || ""}
        readOnly
        disabled
        style={{ backgroundColor: "#f8f9fa", cursor: "not-allowed" }} // Styling to emphasize disabled state
      />
      <i
        className="fas fa-forward"
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          fontSize: "16px",
          color: "#6c757d",
          cursor: "pointer",
          display: "none",
        }}
        onMouseEnter={(e) => (e.target.style.color = "#007bff")} // Change color on hover
        onMouseLeave={(e) => (e.target.style.color = "#6c757d")}
      ></i>
    </div>
  </div>
</div>


<div className="col-md-4">
  <div className="mb-2">
    <label htmlFor="Annual_Return_Type" className="col-form-label">
    Purchase Data Period<span style={{ color: "red" }}>*</span>
    </label>
    <select
      id="Period"
      className="form-select"
      onChange={(e) =>
        setAgent({
          ...agent,
          Period: e.target.value, // Update the value based on dropdown selection
        })
      }
    >
      <option value="">Select Purchase Data Period</option>
      {agentData?.Purchase_Data === "Monthly" &&
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}

      {agentData?.Purchase_Data === "Quarterly" &&
        ["April to June", "July to September", "October to December", "January to March"].map(
          (quarter) => (
            <option key={quarter} value={quarter}>
              {quarter}
            </option>
          )
        )}

      {agentData?.Purchase_Data === "Half-Yearly" &&
        ["April to September", "October to March"].map((halfYear) => (
          <option key={halfYear} value={halfYear}>
            {halfYear}
          </option>
        ))}

      {agentData?.Purchase_Data === "Annually" && (
        <option value="April to March">April to March</option>
      )}
    </select>
  </div>
  <p className="text-danger">{errors.Period}</p>
</div>


<div className="col-md-4">
  <div className="mb-2">
    <label htmlFor="Upload_Document" className="col-form-label">
      Upload Excel File
    </label>
    <input
      type="file"
      id="Upload_Document"
      className="form-control"
      accept=".xlsx, .xls" // Restrict to only Excel file formats
      onChange={(e) => {
        const file = e.target.files[0];
        if (file && !/(\.xlsx|\.xls)$/i.test(file.name)) {
          // Check if the file has an invalid format
          setErrors((prev) => ({
            ...prev,
            Purchase_File: "Only Excel files (.xlsx, .xls) are allowed.",
          }));
        } else {
          // Reset the error and save the file
          setErrors((prev) => ({ ...prev, Purchase_File: "" }));
          setAgent({
            ...agent,
            Purchase_File: file, // Save the uploaded file
          });
        }
      }}
    />
  </div>
  <p className="text-danger">{errors.Purchase_File}</p>
</div>
</div>
{staffData.length > 0 && (
  <div className="row mt-4">
   <div className="row ">
            <div className="col-12">
              <h5 className="section-title" style={{marginBottom:"-10px"}}>View Purchase Data</h5>
              <hr className="mb-2" />
            </div>
          </div>
    {staffData.map((staff, index) => (
      <React.Fragment key={index}>
        <div className="col-md-2 mb-3">
          <div style={{ color: "black",border: "1px solid #ddd", padding:"5px",backgroundColor: "#f8f9fa",
      textAlign: "center", }}>
            {staff.Period || "N/A"} {staff.Purchase_File ? (
                <>
                    

                    {/* Download Certificate Link */}
                    <a
                        style={{ color: "blue", cursor: "pointer" }}
                        href={`${apiUrl}/downloadPurchaseFile/${staff.Purchase_File}`}
                        download={true}
                        className="cursor-pointer"
                    >
                        <i className="ti ti-download" style={{ marginRight: '5px' }}></i>
                    </a>
                </>
            ) : (
                <>
                    {/* Default Icons if no certificate is available */}
                    <i className="ti ti-download-off" style={{ marginLeft: '5px' }}></i>
                </>
            )}
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
)}



<div className="row ">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary btn-sm mt-2" type="submit">
                                Send
                            </button>
                        </div>
                    </div>
                    
             </form>
                {loader}
            </Modal.Body>
            <div className="text-danger p-3">
    Note: Please Upload Documents in .xls or .xlsx format.
</div>

        </Modal>
    );
}
export default PurchaseData;
