import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../components/useFullPageLoader";
import { useUser } from "../../components/UserContext";
import axios from "../../components/axios";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { apiUrl } from "../../components/axios";
import Designation from "./Designation";


const indianStates = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'West Bengal', label: 'West Bengal' },
  // Union Territories
  { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
  { value: 'Chandigarh', label: 'Chandigarh' },
  { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
  { value: 'Lakshadweep', label: 'Lakshadweep' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Puducherry', label: 'Puducherry' },
  { value: 'Ladakh', label: 'Ladakh' },
  { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
];


const UpdateUser = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [customer, setCustomer] = useState({});
  const [isModal, setIsModal] = useState(false)
  const [Modaldata, setModalData] = useState();
  const [designation,setDesignation] = useState([])

  
  

  
    const open = (e) => {
      setModalData(e);
      setIsModal(true);
    };
  
    const closed = () => {
      setIsModal(false);
      loadDesignation();
    };
  
        const loadDesignation = async () => {
          showLoader(); // Show loader at the start of the function
        
          try {
            const result = await axios.get(`/getAllDesignations`);
            setDesignation(result.data.designations || []);
          } catch (error) {
            console.error("Error fetching data: ", error);
          } finally {
            hideLoader(); // Hide loader after the function finishes
          }
        };

  useEffect(() => {
    (async () => {
      showLoader();
      const result = await axios.get(`/getCustomerById/${Sr_No}`);
      setCustomer(result.data);
      hideLoader();
    })();
    loadDesignation();
  }, []);



  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Create a sanitized version of the customer object, replacing null or undefined with ""
    const sanitizedCustomer = {
      ...customer,
      First_Name: customer.First_Name || "",
      Last_Name: customer.Last_Name || "",
      Mobile: customer.Mobile || "",
      Email: customer.Email || "",
      Alt_Email: customer.Alt_Email || "",
      Company_Name: customer.Company_Name || "",
      Company_Type: customer.Company_Type || "",
      Reference: customer.Reference || "",
      Other_Reference: customer.Other_Reference || "",
      Remarks: customer.Remarks || "",
      DOB: customer.DOB || "",
      Certificate_Id: customer.Certificate_Id || "",
      Certificate_Name: customer.Certificate_Name || "",
      Gender: customer.Gender || "",
      Address: customer.Address || "",
      Status: customer.Status || "",
      State: customer.State || "",
      GST_No: customer.GST_No || "",
      District: customer.District || "",
      City: customer.City || "",
      Purchase_Data: customer.Purchase_Data || "",
      VisitingCardFront: customer.VisitingCardFront || "",
      VisitingCardBack: customer.VisitingCardBack || "",
      Staff_1_Name: customer.Staff_1_Name || "",
      Staff_1_Email: customer.Staff_1_Email || "",
      Staff_1_Mobile: customer.Staff_1_Mobile || "",
      Staff_1_Designation: customer.Staff_1_Designation || "",
      Staff_2_Name: customer.Staff_2_Name || "",
      Staff_2_Email: customer.Staff_2_Email || "",
      Staff_2_Mobile: customer.Staff_2_Mobile || "",
      Staff_2_Designation: customer.Staff_2_Designation || "",
      Customer_Designation: customer.Customer_Designation || "",
    };
  
    const validationErrors = {};
  
    if (!/^[a-zA-Z\s]+$/.test(sanitizedCustomer.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(sanitizedCustomer.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }
    if (!/^([6-9][0-9]{9,11})$/.test(sanitizedCustomer.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }
    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(sanitizedCustomer.Email) &&
      sanitizedCustomer.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }
    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(sanitizedCustomer.Email) &&
      sanitizedCustomer.Alt_Email !== ""
    ) {
      validationErrors.Alt_Email = "Invalid Alt Email Address";
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    Swal.fire({
      title: "Confirm Update",
      text: "Are you sure you want to update this customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("CustomerId", sanitizedCustomer.CustomerId);
        formdata.append("Username", sanitizedCustomer.Username);
        formdata.append("Password", sanitizedCustomer.Password);
        formdata.append("First_Name", sanitizedCustomer.First_Name);
        formdata.append("Last_Name", sanitizedCustomer.Last_Name);
        formdata.append("Company_Name", sanitizedCustomer.Company_Name);
        formdata.append("Company_Type", sanitizedCustomer.Company_Type);
        formdata.append("Reference", sanitizedCustomer.Reference);
        formdata.append("Other_Reference", sanitizedCustomer.Other_Reference);
        formdata.append("Remarks", sanitizedCustomer.Remarks);
        formdata.append("DOB", sanitizedCustomer.DOB);
        formdata.append("Certificate_Id", sanitizedCustomer.Certificate_Id);
        formdata.append("Certificate_Name", sanitizedCustomer.Certificate_Name);
        formdata.append("Gender", sanitizedCustomer.Gender);
        formdata.append("Mobile", sanitizedCustomer.Mobile);
        formdata.append("Email", sanitizedCustomer.Email);
        formdata.append("Address", sanitizedCustomer.Address);
        formdata.append("Status", sanitizedCustomer.Status);
        formdata.append("State", sanitizedCustomer.State);
        formdata.append("Alt_Email", sanitizedCustomer.Alt_Email);
        formdata.append("GST_No", sanitizedCustomer.GST_No);
        formdata.append("District", sanitizedCustomer.District);
        formdata.append("City", sanitizedCustomer.City);
        formdata.append("Purchase_Data", sanitizedCustomer.Purchase_Data);
        formdata.append("VisitingCardFront", sanitizedCustomer.VisitingCardFront);
        formdata.append("VisitingCardBack", sanitizedCustomer.VisitingCardBack);
        formdata.append("Staff_1_Name", sanitizedCustomer.Staff_1_Name);
        formdata.append("Staff_1_Email", sanitizedCustomer.Staff_1_Email);
        formdata.append("Staff_1_Mobile", sanitizedCustomer.Staff_1_Mobile);
        formdata.append("Staff_1_Designation", sanitizedCustomer.Staff_1_Designation);
        formdata.append("Staff_2_Name", sanitizedCustomer.Staff_2_Name);
        formdata.append("Staff_2_Email", sanitizedCustomer.Staff_2_Email);
        formdata.append("Staff_2_Mobile", sanitizedCustomer.Staff_2_Mobile);
        formdata.append("Staff_2_Designation", sanitizedCustomer.Staff_2_Designation);
        formdata.append("Customer_Designation", sanitizedCustomer.Customer_Designation);
  
        showLoader();
  
        axios
          .put(`/updateCustomerDetails/${Sr_No}`, formdata)
          .then(() => {
            Swal.fire("Success!", "Customer updated successfully.", "success").then(() => {
              hideLoader(); // Ensure loader is hidden before navigating
              navigate("/viewCustomers");
            });
          })
          .catch((error) => {
            console.error("Update Error:", error);
            hideLoader();
            Swal.fire({
              title: "Error",
              text: "Failed to update the customer. Please try again later.",
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    });
  };
  
  
  

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Update Customer</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <form method="post" id="contactForm" name="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtFname" className="col-form-label">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtFname"
                        className="form-control"
                        placeholder="Enter First Name"
                        required
                        value={customer.First_Name}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            First_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.First_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtLname" className="col-form-label">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control"
                        placeholder="Enter Last Name"
                        required
                        value={customer.Last_Name}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Last_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Last_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Company_Name" className="col-form-label">
                        Company Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="Company_Name"
                        className="form-control"
                        placeholder="Enter Company Name"
                        value={customer.Company_Name}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Company_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Company_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtMobile" className="col-form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={customer.Mobile}
                        id="txtMobile"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setCustomer({ ...customer, Mobile: numericInput });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="txtEmail" className="col-form-label">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email" required
                        id="txtEmail"
                        value={customer.Email}
                        className="form-control"
                        placeholder="Enter Email Id"
                        onChange={(e) =>
                          setCustomer({ ...customer, Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Email}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Alt_Email" className="col-form-label">
                        Alt  Email
                      </label>
                      <input
                        type="email"
                        id="Alt_Email"
                        className="form-control"
                        placeholder="Enter Alt Email Id"
                        value={customer.Alt_Email}
                        onChange={(e) =>
                          setCustomer({ ...customer, Alt_Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Alt_Email}</p>
                    </div>
                  </div>
          
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="GST_No" className="col-form-label">
                        GST No
                      </label>
                      <input
                        type="text"
                        id="GST_No"
                        className="form-control"
                        placeholder="Enter GST No"
                        maxLength={15}
                        value={customer.GST_No}
                        onChange={(e) =>
                          setCustomer({ ...customer, GST_No: e.target.value.toUpperCase() })
                        }
                      />
                      <p className="text-danger">{errors.GST_No}</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Reference" className="col-form-label">
                        Reference<span style={{ color: "red" }}>*</span>
                      </label>
                      <select className="form-select" value={customer.Reference || ""} required onChange={(e) =>
                        setCustomer({ ...customer, Reference: e.target.value, Other_Reference: ""  })
                        
                      } >
                        <option disabled label="Select Reference" value=""></option>
                        <option label="Facebook" value="Facebook"></option>
                        <option label="Instagram" value="Instagram"></option>
                        <option label="Google" value="Google"></option>
                        <option label="LinkedIn" value="LinkedIn"></option>
                        <option label="Other" value="Other"></option>
                      </select>
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label htmlFor="Reference" className="col-form-label">
                        Mention Reference<span style={{ color: "red" }}>*</span>
                      </label>
                      <input required={customer.Reference === "Other" ? true : false}
                        disabled={customer.Reference === "Other" ? false : true}
                        type="text"
                        id="Reference"
                        value={customer.Other_Reference}
                        className="form-control"
                        placeholder="Other Reference"
                        onChange={(e) =>
                          setCustomer({ ...customer, Other_Reference: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="City" className="col-form-label">
                        City
                      </label>
                      <input

                        type="text"
                        id="City"
                        value={customer.City}
                        className="form-control"
                        placeholder="Enter City"
                        onChange={(e) =>
                          setCustomer({ ...customer, City: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.City}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="District" className="col-form-label">
                        District
                      </label>
                      <input
                        type="text"
                        id="District"
                        value={customer.District}
                        className="form-control"
                        placeholder="Enter District"
                        onChange={(e) =>
                          setCustomer({ ...customer, District: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.District}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-2">
                      <label htmlFor="State" className="col-form-label">
                        State<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        required
                        value={customer.State ? { value: customer.State, label: customer.State } : null}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? 'white' : 'black',
                            backgroundColor: state.isFocused ? 'blue' : 'white',
                            '&:hover': {
                              backgroundColor: 'blue',
                              color: 'white',
                            },
                          }),
                        }}
                        options={indianStates}
                        onChange={(e) => {
                          setCustomer({ ...customer, State: e.value })
                        }}
                        placeholder="Select a state"
                      />


                    </div>
                  </div>
                  <div className="col-md-3">
  <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="Staff_2_Designation" className="col-form-label">
          Designation
        </label>
        <Link
          onClick={() => {
            // Add your logic here for the button action
            open("Customer");
          }}
        >
          Add
        </Link>
      </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Customer_Designation"
      aria-label="Select Staff Designation"
      value={customer.Customer_Designation || ""}
      onChange={(e) =>
        setCustomer({
          ...customer,
          Customer_Designation: e.target.value,
        })
      }
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>


                </div>
                <div className="row">
                <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="Company_Type" className="col-form-label">
                        Company Type
                      </label>
                      <input
                        type="text"
                        id="Company_Type"
                        name="Company_Type"
                        className="form-control"
                        placeholder="Enter Company Type"
                        value={customer.Company_Type}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Company_Type: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="Address" className="col-form-label">
                        Address
                      </label>
                      <textarea
                        type="text"
                        id="Address"
                        className="form-control"
                        placeholder="Enter Address..."
                        rows="2"
                        value={customer.Address}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Address}</p>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="Remarks" className="col-form-label">
                        Remarks/Requirements
                      </label>
                      <textarea
                        type="text"
                        id="Remarks"
                        className="form-control"
                        placeholder="Enter Remarks..."
                        value={customer.Remarks}
                        rows="2"
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Remarks: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Remarks}</p>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="Purchase_Data" className="col-form-label">
                        Purchase Data
                      </label>
                      <select className="form-select" value={customer.Purchase_Data || ""} required onChange={(e) =>
                        setCustomer({ ...customer, Purchase_Data: e.target.value  })
                        
                      } >
                        <option disabled label="Select Purchase Data" value=""></option>
                        <option label="Monthly" value="Monthly"></option>
                        <option label="Quarterly" value="Quarterly"></option>
                        <option label="Half-Yearly" value="Half-Yearly"></option>
                        <option label="Annually" value="Annually"></option>
                      </select>
                     
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="mb-3">
                      <label htmlFor="VisitingCardFront" className="col-form-label">
                        Visiting Card (Front)
                      </label>
                      <input
                        type="file"
                        id="VisitingCardFront"
                        className="form-control"
                        //placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, VisitingCardFront: e.target.files[0] })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mt-4">
                    <>

                      {customer.VisitingCardFront ? (<>

                        <a
                          style={{ color: "blue", cursor: 'pointer' }}
                          href={`${apiUrl}/downloadVCF/${customer.VisitingCardFront}`}
                          download={true}
                          className="cursor-pointer"
                        >
                          <i className="ti ti-download"></i>
                        </a>
                      </>
                      ) : (
                        <>
                          {/* <i className="ti ti-eye-off"></i> /  */}
                          <i className="ti ti-download-off"></i>
                        </>
                      )}

                    </>
                  </div>
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label htmlFor="VisitingCardBack" className="col-form-label">
                        Visiting Card (Back)
                      </label>
                      <input
                        type="file"
                        id="VisitingCardBack"
                        className="form-control"
                        //placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, VisitingCardBack: e.target.files[0] })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mt-4">
                    <>
                      {customer.VisitingCardBack ? (
                        <>

                          <a
                            style={{ color: "blue", cursor: 'pointer' }}
                            href={`${apiUrl}/downloadVCF/${customer.VisitingCardBack}`}
                            download={true}
                            className="cursor-pointer"
                          >
                            <i className="ti ti-download"></i>
                          </a>
                        </>
                      ) : (
                        <>
                          <i className="ti ti-download-off"></i>
                        </>
                      )}
                    </>
                  </div>
                </div>
                <div className="row ">
            <div className="col-12">
              <h5 className="section-title" style={{marginBottom:"-10px"}}>Staff 1 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff1Name"
                  name="Staff_1_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  value={customer.Staff_1_Name || ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      Staff_1_Name: e.target.value,
                    })
                  }
                />
               {errors.Staff_1_Name && <small className="text-danger">{errors.Staff_1_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_1_Mobile"
                  name="Staff_1_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  value={customer.Staff_1_Mobile || ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      Staff_1_Mobile: e.target.value,
                    })
                  }
                />
              {errors.Staff_1_Mobile && <small className="text-danger">{errors.Staff_1_Mobile}</small>}        
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Email" className="form-label">
                  Staff Email
                </label>
                <input
                  type="email"
                  id="Staff_1_Email"
                  name="Staff_1_Email"
                  className="form-control"
                  placeholder="Enter Staff Email"
                  value={customer.Staff_1_Email || ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      Staff_1_Email: e.target.value,
                    })
                  }
                />
              {errors.Staff_1_Email && <small className="text-danger">{errors.Staff_1_Email}</small>}  
              </div>
            </div>
            <div className="col-md-3">
            <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="Staff_1_Designation" className="form-label mb-0">
          Designation
        </label>
        <Link
          onClick={() => {
            // Add your logic here for the button action
            open("Customer_Staff1");
          }}
        >
          Add
        </Link>
      </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Staff_1_Designation"
      aria-label="Select Staff Designation"
      value={customer.Staff_1_Designation || ""}
      onChange={(e) =>
        setCustomer({
          ...customer,
          Staff_1_Designation: e.target.value,
        })
      }
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>
          </div>
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Staff 2 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff_2_Name"
                  name="Staff_2_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  value={customer.Staff_2_Name || ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      Staff_2_Name: e.target.value,
                    })
                  }

                />
              {errors.Staff_2_Name && <small className="text-danger">{errors.Staff_2_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_2_Mobile"
                  name="Staff_2_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  value={customer.Staff_2_Mobile || ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      Staff_2_Mobile: e.target.value,
                    })
                  }
                />
              {errors.Staff_2_Mobile && <small className="text-danger">{errors.Staff_2_Mobile}</small>}  
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff_2_Email" className="form-label">
      Staff Email
    </label>
    <input
      type="email"
      id="Staff_2_Email"
      name="Staff_2_Email" 
      className="form-control"
      placeholder="Enter Staff Email"
      value={customer.Staff_2_Email || ""}
      onChange={(e) =>
        setCustomer({
          ...customer,
          Staff_2_Email: e.target.value,
        })
      }
    />
    {errors.Staff_2_Email && <small className="text-danger">{errors.Staff_2_Email}</small>}
  </div>
</div>
<div className="col-md-3">
  <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="Staff_2_Designation" className="form-label mb-0">
          Designation
        </label>
        <Link
          onClick={() => {
            // Add your logic here for the button action
            open("Customer_Staff2");
          }}
        >
          Add
        </Link>
      </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Staff_2_Designation"
      aria-label="Select Staff Designation"
      value={customer.Staff_2_Designation || ""}
      onChange={(e) =>
        setCustomer({
          ...customer,
          Staff_2_Designation: e.target.value,
        })
      }
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>

          </div>
                <div className="row justify-content-center">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <button type="submit" className="btn btn-primary " style={{ height: "38px", marginBottom: "10px" }}>
                      Update
                    </button><br />

                  </div>
                </div>
              </form>
              {isModal && (
          <>
            <Designation
              open={isModal}
              onClose={closed}
              Modaldata={Modaldata}
            />
          </>
        )
        }

            </div>
          </div>
          {loader}
        </div>
      </div>
    </div>
  );
};
export default UpdateUser;
