import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import useFullPageLoader from "../../components/useFullPageLoader";
import axios from "../../components/axios";
import { useUser } from "../../components/UserContext";


const Designation = ({ open, onClose,Modaldata }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, setErrors] = useState({});
  const [designation, setDesignation] = useState("");
  const {userData} = useUser();

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleAddDesignation = async (e) => {
    e.preventDefault();
  
    if (designation.trim() === "") {
      setErrors({ Designation: "Designation is required" });
      return;
    }
  
    showLoader();
  
    try {
      const payload = {
        Designation_Value: designation,
        Designation_Type: Modaldata,
        Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
        Org_Id: userData?.Org_Id,
        Added_By_Id: userData?.UserId,
      };
  
      const response = await axios.post("/addDesignation", payload);
  
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Designation "${designation}" added successfully for the ${Modaldata}!`,
        });
  
        setDesignation("");
        setErrors({});
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add designation. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error while adding designation:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again later.",
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      backdrop="true"
      style={{
        transition: "transform 0.3s ease-in-out",
        transform: show ? "translateY(0)" : "translateY(100%)",
      }}
    >
      <Modal.Body>
        <h5 className="text-center mb-4">Add Designation</h5>
        <form onSubmit={handleAddDesignation}>
          <div className="mb-3">
            <label htmlFor="Designation" className="form-label">
              Designation<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              id="Designation"
              className="form-control"
              placeholder="Enter Designation"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              required
            />
            {errors.Designation && (
              <small className="text-danger">{errors.Designation}</small>
            )}
          </div>
          <div className="text-center mt-4">
            <button type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </form>
        {loader}
      </Modal.Body>
    </Modal>
  );
};

export default Designation;
