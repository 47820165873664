import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import UploadCertificate from "../Cetificate/UploadCertificate";
import ApproveRejectDoc from "./ApproveRejectDoc";
import UploadDocument from "./UploadDocument";
import ApproveRejectPayment from "./ApproveRejectPayment";
import UpdateDocumentByFac from "./UpdateDocumentByFac";
import SetQuotation from "./SetQuotation";
import QuotationPreview from "./QuotationPreview";
import UploadAcknowledgement from "./UploadAcknowledgement";
import { formatDate } from "../../components/dateFormate";
import dayjs from "dayjs";
import AssignEnquiry from "./AssignEnquiry";
import CustomPasswordModal from "./CustomModalPassword";
import SummaryModal from "./SummaryModal";
import SendAnnualReturn from "./SendAnnualReturn";
import PurchaseData from "./PurchaseData";

const AnnualReturn = () => {
  const { userData } = useUser();
  const { Customer_Id, Company_Name, condition } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [isModal3, setIsModal3] = useState(false);
  const [isModal4, setIsModal4] = useState(false);
  const [isModal5, setIsModal5] = useState(false);
  const [isModal6, setIsModal6] = useState(false);
  const [isModal7, setIsModal7] = useState(false);
  const [isModal8, setIsModal8] = useState(false);
  const [isModal9, setIsModal9] = useState(false);
  const [isModal10, setIsModal10] = useState(false);
  const [isModal11, setIsModal11] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Sr_No, setSrNo] = useState(null);
  const [Name, setName] = useState('');
  const [UserId, setUserId] = useState('');
  const [Modaldata, setModalData] = useState();
  const [docStatus, setDocStatus] = useState({});
  const [pendingStepFilter, setPendingStepFilter] = useState("All");
  const [certificateData, setCertificateData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [filter, setFilter] = useState({
    Customer_Id: "",
    Company_Name: Company_Name ? Company_Name : "",
    Certificate_Id: "",
    Certificate_Name: "",
    Status:
      condition === "Active"
        ? "Active"
        : condition === "Pending"
        ? "Pending"
        : condition === "CertificateIssued"
        ? "Certificate Issued"
        : condition === "PendingCertificate"
        ? "Pending Certificate"
        : "",
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });


  const closed = () => {
    setIsModal(false);
    loadStaffData();
    loadCertificateData();
  };

  const open = (e) => {
    setModalData(e);
    setIsModal(true);
  };

  const closed1 = () => {
    setIsModal1(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open1 = (e) => {
    setModalData(e);
    setIsModal1(true);
  };

  const closed2 = () => {
    setIsModal2(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open2 = (e) => {
    setModalData(e);
    setIsModal2(true);
  };

  const closed3 = () => {
    setIsModal3(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open3 = (e) => {
    setModalData(e);
    setIsModal3(true);
  };

  const closed4 = () => {
    setIsModal4(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open4 = (e) => {
    setModalData(e);
    setIsModal4(true);
  };

  const closed5 = () => {
    setIsModal5(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  };

  const open5 = (e) => {
    setModalData(e);
    setIsModal5(true);
  };

  const closed6 = () => {
    setIsModal6(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open6 = (e) => {
    setModalData(e);
    setIsModal6(true);
  };

  const closed7 = () => {
    setIsModal7(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open7 = (e) => {
    setModalData(e);
    setIsModal7(true);
  };


  const open8 =(e) =>{
    setModalData(e);
    setIsModal8(true);
  }

  const closed8 =()=>{
    setIsModal8(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }

  const open9 =(e) =>{
    setModalData(e);
    setIsModal9(true);
  }

  const closed9 =()=>{
    setIsModal9(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }

  const open10 =(e) =>{
    setModalData(e);
    setIsModal10(true);
  }

  const closed10 =()=>{
    setIsModal10(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }


  const open11 =(e) =>{
    setModalData(e);
    setIsModal11(true);
  }

  const closed11 =()=>{
    setIsModal11(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }


  const showPasswordModal = (srNo, name, userId) => {
    setSrNo(srNo);
    setName(name);
    setUserId(userId);
    setShowModal(true);
  };

  const closePasswordModal = () => {
    setShowModal(false);
    loadStaffData();
    setPendingStepFilter("All");
  };

  const filterData = () => {
    loadStaffData();
  };

  useEffect(() => {
    if (userData?.UserId || userData?.Vendor_Id) {
  
      loadStaffData();
      loadCertificateData();
    } 
  }, [userData?.UserId, userData?.Vendor_Id]);
  


  

  const loadCertificateData = async () => {
    try {
      const result = await axios.get("/getCertificateType"); // Fetch data from API

      // Log the fetched data to the consol

      setCertificateData(result.data); // Update the certificate data state with fetched data
    } catch (error) {
      console.error("Error fetching certificate data:", error);
      alert("Failed to load certificate data. Please try again later.");
    }
  };

  

  const loadStaffData = async () => {
    showLoader();
    try {
      let result;
      let endpoint = ""; // Variable to store the endpoint being called for debugging


      if (userData?.Role === "Staff") {
        endpoint = `/getAllAnnualReturnEnqForStaff/${userData?.UserId}?SrNo=${filter?.Customer_Id}&Certificate_Id=${filter?.Certificate_Id}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}&Status=${filter?.Status}`;
      } else if (userData?.Vendor_Id) {
        endpoint = `/getAllAnnualReturnEnqForVendor/${userData?.Vendor_Id}?SrNo=${filter?.Customer_Id}&Certificate_Id=${filter?.Certificate_Id}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}&Status=${filter?.Status}`;
      } else if(userData?.Agent_Id || userData?.Role === "Agent"){
        endpoint = `/getAllAgentAnnualEnq/${userData?.Agent_Id}?SrNo=${filter?.Customer_Id}&Certificate_Id=${filter?.Certificate_Id}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}&Status=${filter?.Status}`;
      }
      else {
        endpoint = `/getAllAnnualReturns?SrNo=${filter?.Customer_Id}&Certificate_Id=${filter?.Certificate_Id}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}&Status=${filter?.Status}`;
      }
  
     
  
      // Fetch data using the determined endpoint
      result = await axios.get(endpoint);
  
      // Fetch customer data for dropdown
      const customerDropdown = await axios.get(`/getCustomerForCustomerView`);
  
      // Enrich the result data with pending step
      const enrichedStaffData = result.data.map((item) => ({
        ...item,
        pendingStep: getCurrentPendingStep(item),
      }));
  
      // Update state with fetched data
      setCustomerData(customerDropdown.data); // Set dropdown data
      setStaffData(enrichedStaffData || []); // Set table data
      setStaffDataa(enrichedStaffData || []); // Backup table data
      
      
  
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };
  
  
  const checkDoc = async (enqId) => {
    if (enqId) {
      const result = await axios.get(`/getCustomerDocumentByEnqId/${enqId}`);
      setDocStatus((prevState) => ({
        ...prevState,
        [enqId]: result.data.length > 0,
      }));
    }
  };

  useEffect(() => {
    data.forEach((item) => {
      checkDoc(item.Enquiry_Sr_No);
    });
  }, [data]);


  // const deleteUser = (Sr_No, Name, UserId) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You are about to delete user: ${Name}. You won't be able to revert this!`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.value) {
  //       // Show password confirmation modal
  //       showPasswordModal(Sr_No, Name, UserId);
  //     }
  //   });
  // };
  

  const deleteUser = (Sr_No, Name, UserId) => {
 
    // If not a Staff or Vendor with Vendor_Id, proceed with deletion
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete Annual Record for ${Name}. You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        // Show password confirmation modal if the user confirms
        showPasswordModal(Sr_No, Name, UserId);
      }
    });
  };
  

  const sendAnnualReturn = async (row) => {
    try {
      // Show a confirmation dialog before proceeding
      const confirmation = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Do you want to send the Annual Return for this record?',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        cancelButtonText: 'Cancel',
      });
  
      // If the user cancels, exit the function
      if (!confirmation.isConfirmed) {
        return;
      }
  
      showLoader();
  
      // Prepare the payload object
      const normalizeValue = (value) => {
        return value === null || value === undefined ? "" : value;
      };
      
      const payload = {
        Enquiry_Sr_No: normalizeValue(row.Enquiry_Sr_No),
        Enquiry_Id: normalizeValue(row.Enquiry_Id),
        Customer_Id: normalizeValue(row.Enquiry_CustomerId),
        Certificate_Id: normalizeValue(row.Certificate_Id),
        Certificate_Name: normalizeValue(row.Certificate_Name),
        Remarks: normalizeValue(row.Remarks),
        Payment_Doc: normalizeValue(row.Payment_Doc),
        Payment_Status: normalizeValue(row.Payment_Status),
        Document_Status: normalizeValue(row.Document_Status),
        Quotation: normalizeValue(row.Quotation),
        Attachment1: normalizeValue(row.Attachment1),
        Attachment2: normalizeValue(row.Attachment2),
        Attachment3: normalizeValue(row.Attachment3),
        Customer_Company: row.Role === "Agent" ? normalizeValue(row.Customer_Company) : normalizeValue(row.Company_Name),
        Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
        Added_By_Id: userData?.UserId,
      };
      
      
  
      // Send the payload data to the backend as JSON
     const response = await axios.post("/sendAnnualReturn", payload);
  
      if (response.status === 200 && response.data.success) {
        // Success notification using SweetAlert2
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Annual Return data inserted successfully.',
          confirmButtonText: 'OK',
        });
        await loadStaffData();
      } else {
        // Error notification using SweetAlert2
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.data.message || "Failed to insert Annual Return data.",
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error("Error inserting Annual Return data:", error);
      // Error notification using SweetAlert2
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: "An error occurred while inserting Annual Return data.",
        confirmButtonText: 'OK',
      });
    } finally {
      hideLoader();
    }
  };
  
  
  




  
  const handleSubmit = async (EnqId, Customer_Id, item) => {
     
     const Data = {
       ...item,
       Updated_By: `${userData?.First_Name} ${userData?.Last_Name}`,
       Updated_By_Id: userData?.UserId || userData?.Agent_Id,
       Customer_Id,
     };
   
   
     if (Object.keys(Data).length === 0) {
       return;
     }
   
     showLoader();
     try {
       const res = await axios.put(`/approveQuotation/${EnqId}`, Data);
       Swal.fire("Success!", "Quotation Approved Successfully!!", "success").then((result) => {
         if (result.isConfirmed) {
           loadStaffData();
         }
       });
     } catch (err) {
       console.error("Error in API Call:", err.response?.data || err.message);
       Swal.fire({
         title: "Error",
         text: "Failed to Approve Quotation. Please try again later.",
         icon: "error",
         confirmButtonText: "OK",
       });
     } finally {
       hideLoader();
     }
   };



  const getCurrentPendingStep = (row) => {
    if (
      (row.Gvt_Amount === "0.00" || row.Gvt_Amount === null) &&
      (row.Consultation === "0.00" || row.Consultation === null)
    ) {
      return "Set Quotation";
    } else if (
      row.Quotation === "No" &&
      row.Consultation !== "0.00" &&
      row.Net_Amount === "0.00" &&
      (row.Quotation_Preview === "No" || row.Quotation_Preview === null)
    ) {
      return "Quotation Preview"; // Quotation Preview step
    } else if (
      row.Send_Quotation !== null &&
      row.Quotation === "No" &&
      row.Quotation_Preview === "Yes"
    ) {
      return "Approve Quotation"; // Step 3: Approve Quotation
    } else if (row.Quotation === "Yes" && row.Document_Status === "Pending") {
      return "Upload Document"; // Step 4: Upload Document
    } else if (
      row.Quotation === "Yes" &&
      row.Document_Status !== "Pending" &&
      row.Net_Amount === "0.00"
    ) {
      return "Set Installment"; // Step 5: Set Installment
    } else if (row.Amount_Balance > "0.00") {
      return "Make Payment"; // Step 6: Make Payment
    } else if (row.Payment_Status === "Inprocess") {
      return "Verify Payment"; // Step 7: Verify Payment
    } else if (row.Acknowledgement === "No") {
      return "Upload Acknowledgement"; // Step 8: Upload Acknowledgement
    }
  
    return "No Pending Tasks"; // If no conditions met
  };
  

  const handlePendingStepChange = (event) => {
    setPendingStepFilter(event.target.value);
  };


    // Function to filter data based on pending step
    const filterByPendingStep = (pendingStepFilter) => {
      const filteredData = dataa.filter((row) => {
        const currentPendingStep = getCurrentPendingStep(row).toLowerCase();
  
        // Only return rows that match the selected pending step
        return (
          pendingStepFilter === "All" || currentPendingStep.includes(pendingStepFilter.toLowerCase())
        );
      });
  
      setStaffData(filteredData); // Update the displayed data
    };
  
    useEffect(() => {
      filterByPendingStep(pendingStepFilter); // Re-filter when pendingStepFilter changes
    }, [pendingStepFilter]);


  
  const pendingStepOptions = [
    { value: "All", label: "All" },
    { value: "Set Quotation", label: "Set Quotation" },
    { value: "Approve Quotation", label: "Approve Quotation" },
    { value: "Quotation Preview", label: "Quotation Preview" },
    { value: "Upload Document", label: "Upload Document" },
    { value: "Set Installment", label: "Set Installment" },
    { value: "Make Payment", label: "Make Payment" },
    { value: "Verify Payment", label: "Verify Payment" },
    { value: "Upload Acknowledgement", label: "Upload Acknowledgement" },
    { value: "No Pending Tasks", label: "No Pending Tasks" },
  ];


  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row.Added_On),
      sortable: true,
      width: "80px",
    },
    {
      name: "Enquiry ID",
      selector: (row) => row.Enquiry_Id,
      sortable: true,
      width: "85px",
    },
    // {
    //   name: "ID",
    //   selector: (row) => row.CustomerId,
    //   sortable: true,
    // },
    {
      name: "Name",
      cell: (row) =>
        userData?.Vendor_Id ? (
          `${row.First_Name} ${row.Last_Name}`
        ) : (
          <Link
            to={
              row.Role === "Agent"
                ? `/editAgent/${row.Enquiry_CustomerId}`
                : `/updateCustomer/${row.Sr_No}`
            }
          >
            {`${row.First_Name} ${row.Last_Name}`}
          </Link>
        ),
      width: "100px",
      sortable: true,
    },
    
    
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "160px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name ? row.Company_Name : "--"}
          </span>
        </LightTooltip>
      ),
      width:"140px",
    },
    {
      name: "Customer Company",
      cell: (row) => (
        <LightTooltip title={row.Customer_Company}>
          <span
            style={{
              maxWidth: "160px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Customer_Company ? row.Customer_Company : "--"}
          </span>
        </LightTooltip>
      ),
      width: "130px",
    },
    
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      width: "85px",
    },
    {
      name: "Certificate",
      cell: (row) => (
        <LightTooltip title={row.Certificate_Name}>
          <span
            style={{
              maxWidth: "160px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Certificate_Name ? row.Certificate_Name : "--"}
          </span>
        </LightTooltip>
      ),
      width:"130px",
    },
    {
      name: "Remarks",
      cell: (row) => (
        <LightTooltip title={row.Remarks}>
          <span
            style={{
              margin: "0px 0px",
              maxWidth: "90px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Remarks ? row.Remarks : "--"}
          </span>
        </LightTooltip>
      ),
      width: userData?.Vendor_Id ? "150px" : "80px", // Increase width if Vendor_Id exists
    },
    ...(userData?.Vendor_Id
      ? [
          {
            name: "Staff Name",
            selector: (row) => row.Staff_Name,
            sortable: true,
            width: "160px",
          },
          {
            name: "Staff Mobile",
            cell: (row) => (
              <span>
                {row.Staff_Mobile || "--"}
              </span>
            ),
            width: "100px",
          },
        ]
      : []),
    {
      name: "Pending Step",
      selector: (row) => getCurrentPendingStep(row),
      cell: (row) => {
        const currentPendingStep = getCurrentPendingStep(row);
        return (
          <span
            style={{
              color:
                currentPendingStep === "No Pending Tasks" ? "green" : "blue",
            }}
          >
            {currentPendingStep}
          </span>
        );
      },
      width: "130px",
    },
    {
      name: "",
      cell: (row) => {
        if (userData?.Agent_Id) {
          return (
            <div className="dropdown">
            <a
              className="p-0"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i>
                <MoreVertIcon
                  style={{
                    color: "grey",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
              </i>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item" onClick={() => open11(row)}>
  <i className="fas fa-file-invoice-dollar" style={{ fontSize: "16px", marginRight: "5px", color: "#008a00" }}></i>
  &nbsp;Purchase Data
</Link>
{row.Quotation === "Yes" && row.Document_Status === "Pending" && (
                    <Link className="dropdown-item" onClick={() => open3(row)}>
                      <i
                        className="ti ti-file-upload text-warning"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      ></i>
                      Upload Documents
                    </Link>
                  )}
                   {row.Send_Quotation !== null && row.Quotation === "No" && (
                  <Link className="dropdown-item" onClick={() => handleSubmit(row.Enquiry_Sr_No, row.Sr_No, row)}>
                    <i
                      className="fas fa-check-square"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Approve Quotation
                  </Link>
                )}
            </div>
            

              </div>
          )
        } else if (userData?.Department === "Accounts") {
          // Show dropdown for Accounts-specific logic
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
                {row.Quotation === "Yes" &&
                  row.Document_Status !== "Pending" &&
                  row.Net_Amount === "0.00" && (
                    <Link
                      className="dropdown-item"
                      to={`/setInstallment/${row.Enquiry_Sr_No}`}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `/setInstallment/${row.Enquiry_Sr_No}`,
                          "_blank"
                        );
                      }}
                    >
                      <i
                        className="fas fa-user-cog text-primary"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      ></i>
                      Set Installment
                    </Link>
                  )}
                {row.Net_Amount !== "0.00" && (
  <>
    <Link
      className="dropdown-item"
      to={`/makePayments/${row.Enquiry_Sr_No}`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/makePayments/${row.Enquiry_Sr_No}`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-cash-register text-success"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Payment
    </Link>
    <Link
      className="dropdown-item"
      to={`/invoice/${row.Enquiry_Sr_No}/Facility`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/invoice/${row.Enquiry_Sr_No}/Facility`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-file-invoice text-info"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Invoice
    </Link>
  </>
)}
                {row.Payment_Status === "Inprocess" && (
                  <Link className="dropdown-item" onClick={() => open2(row)}>
                    <i
                      className="fas fa-clipboard-check"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Verify Payment
                  </Link>
                )}
                {row.Acknowledgement === "No" && (
                  <Link className="dropdown-item" onClick={() => open7(row)}>
                    <i
                      className="fas fa-scroll"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Upload Acknowledgement
                  </Link>
                )}
              </div>
            </div>
          );
        }
         else if (userData?.Vendor_Id) {
          // Show the dropdown only if any of the conditions are true when Vendor_Id is present
          if (
            (row.Quotation === "Yes" && row.Document_Status === "Pending") ||
            row.Acknowledgement === "No" ||
            row.Status === "Active"
          ) {
            return (
              <div className="dropdown">
                <a
                  className="p-0"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i>
                    <MoreVertIcon
                      style={{
                        color: "grey",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    />
                  </i>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>


                  {row.Quotation === "Yes" && row.Document_Status === "Pending" && (
                    <Link className="dropdown-item" onClick={() => open3(row)}>
                      <i
                        className="ti ti-file-upload text-warning"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      ></i>
                      Upload Documents
                    </Link>
                  )}
                  {row.Acknowledgement === "No" && (
                    <Link className="dropdown-item" onClick={() => open7(row)}>
                      <i
                        className="fas fa-scroll"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      ></i>
                      Upload Acknowledgement
                    </Link>
                  )}
                  
                </div>
              </div>
            );
          }
        } else if (userData?.Department === "Accounts Management") {
          // Show dropdown for Accounts-specific logic
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
                {row.Quotation === "Yes" &&
                  row.Document_Status !== "Pending" &&
                  row.Net_Amount === "0.00" && (
                    <Link
                      className="dropdown-item"
                      to={`/setInstallment/${row.Enquiry_Sr_No}`}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `/setInstallment/${row.Enquiry_Sr_No}`,
                          "_blank"
                        );
                      }}
                    >
                      <i
                        className="fas fa-user-cog text-primary"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      ></i>
                      Set Installment
                    </Link>
                  )}
                {row.Net_Amount !== "0.00" && (
  <>
    <Link
      className="dropdown-item"
      to={`/makePayments/${row.Enquiry_Sr_No}`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/makePayments/${row.Enquiry_Sr_No}`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-cash-register text-success"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Payment
    </Link>
    <Link
      className="dropdown-item"
      to={`/invoice/${row.Enquiry_Sr_No}/Facility`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/invoice/${row.Enquiry_Sr_No}/Facility`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-file-invoice text-info"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Invoice
    </Link>
  </>
)}
                {row.Payment_Status === "Inprocess" && (
                  <Link className="dropdown-item" onClick={() => open2(row)}>
                    <i
                      className="fas fa-clipboard-check"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Verify Payment
                  </Link>
                )}
  {(
  (row.Staff_Id && (row.Vendor_Id === null || row.Vendor_Id === "")) && 
  (!row.Upload_Certificate || row.Upload_Certificate === null || row.Upload_Certificate === "") &&
  row.Payment_Status === "Approved"
) && (
  <Link className="dropdown-item" onClick={() => open8(row)}>
    <i className="fas fa-handshake" style={{ fontSize: "16px", marginRight: "5px", color: "blue" }}></i>
    Assign Enquiry
  </Link>
)}
              </div>
            </div>
          );
        }  else if (userData?.Department === "Document Management") {
          // Show dropdown for Accounts-specific logic
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
{row.Quotation === "Yes" && row.Document_Status === "Pending" && (
                    <Link className="dropdown-item" onClick={() => open3(row)}>
                      <i
                        className="ti ti-file-upload text-warning"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      ></i>
                      Upload Documents
                    </Link>
                  )}
{(
  (row.Staff_Id && (row.Vendor_Id === null || row.Vendor_Id === "")) && 
  (!row.Upload_Certificate || row.Upload_Certificate === null || row.Upload_Certificate === "") &&
  row.Document_Status === "Approved"
) && (
  <Link className="dropdown-item" onClick={() => open8(row)}>
    <i className="fas fa-handshake" style={{ fontSize: "16px", marginRight: "5px", color: "blue" }}></i>
    Assign Enquiry
  </Link>
)}


              </div>
            </div>
          );
        }  else if (userData?.Department === "Quotation Management") {
          // Show dropdown for Accounts-specific logic
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
{(row.Gvt_Amount === "0.00" || row.Gvt_Amount === null) &&
                  (row.Consultation === "0.00" || row.Consultation === null) && (
                    <Link className="dropdown-item" onClick={() => open5(row)}>
                      <i className="fas fa-sliders-h" style={{ fontSize: "16px", marginRight: "5px" }}></i>
                      Set Quotation
                    </Link>
                  )}
                {row.Send_Quotation !== null && row.Quotation === "No" && (
                  <Link className="dropdown-item" onClick={() => handleSubmit(row.Enquiry_Sr_No, row.Sr_No, row)}>
                    <i
                      className="fas fa-check-square"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Approve Quotation
                  </Link>
                )}
{row.Quotation === "No" && row.Consultation !== "0.00" && row.Net_Amount === "0.00" && (
                  <Link className="dropdown-item" onClick={() => open6(row)}>
                    <i className="fas fa-receipt" style={{ fontSize: "16px", marginRight: "5px" }}></i>
                    Quotation Preview
                  </Link>
                )}
                                  {(
  (row.Staff_Id && (row.Vendor_Id === null || row.Vendor_Id === "")) && 
  (!row.Upload_Certificate || row.Upload_Certificate === null || row.Upload_Certificate === "") &&
  row.Document_Status === "Pending "
) && (
  <Link className="dropdown-item" onClick={() => open8(row)}>
    <i className="fas fa-handshake" style={{ fontSize: "16px", marginRight: "5px", color: "blue" }}></i>
    Assign Enquiry
  </Link>
)}
                
              </div>
            </div>
          );
        }  else if (userData?.Department === "Acknowledgment Management") {
          // Show dropdown for Accounts-specific logic
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
{row.Acknowledgement === "No" && (
                    <Link className="dropdown-item" onClick={() => open7(row)}>
                      <i
                        className="fas fa-scroll"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      ></i>
                      Upload Acknowledgement
                    </Link>
                  )}
                      {String(row.Acknowledgement) === "Yes" &&
 String(row.Annual_Return) === "Yes" &&
 String(row.Annual_Return_Exists) === "No" && (
    <Link className="dropdown-item" onClick={() => open10(row)}>
      <i
        className="fas fa-envelope"
        style={{
          fontSize: "16px",
          marginRight: "5px",
          color: "#FF9800", // Light green color
        }}
      ></i>
      Send Annual Return
    </Link>
  )}
                  {(
  (row.Staff_Id && (row.Vendor_Id === null || row.Vendor_Id === "")) && 
  (!row.Upload_Certificate || row.Upload_Certificate === null || row.Upload_Certificate === "") &&
  row.Acknowledgement === "Yes"
) && (
  <Link className="dropdown-item" onClick={() => open8(row)}>
    <i className="fas fa-handshake" style={{ fontSize: "16px", marginRight: "5px", color: "blue" }}></i>
    Assign Enquiry
  </Link>
)}
              </div>
            </div>
          );
        }  else if (userData?.Department === "Administration Management") {
          // Show dropdown for Accounts-specific logic
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* Conditions when Vendor_Id is not present */}
                {(!row.Staff_Id && !row.Vendor_Id && (!row.Upload_Certificate || row.Upload_Certificate === null || row.Upload_Certificate === "")) && (
                  <Link className="dropdown-item" onClick={() => open8(row)}>
                    <i className="fas fa-handshake" style={{ fontSize: "16px", marginRight: "5px", color: "blue" }}></i>
                    Assign Enquiry
                  </Link>
                )}

<Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
                {row.Quotation === "Yes" &&
  row.Document_Status !== "Pending" &&
  row.Net_Amount === "0.00" &&
  row.Role === "Agent" && (
    <Link className="dropdown-item" onClick={() => open11(row)}>
      <i
        className="fas fa-address-book"
        style={{
          fontSize: "16px",
          marginRight: "5px",
          color: "#6f42c1", 
        }}
      ></i>
      &nbsp;Add Customer Details
    </Link>
  )}



                
                {row.Quotation === "No" && row.Consultation !== "0.00" && row.Net_Amount === "0.00" && (
                  <Link className="dropdown-item" onClick={() => open6(row)}>
                    <i className="fas fa-receipt" style={{ fontSize: "16px", marginRight: "5px" }}></i>
                    Quotation Preview
                  </Link>
                )}
                {(row.Gvt_Amount === "0.00" || row.Gvt_Amount === null) &&
                  (row.Consultation === "0.00" || row.Consultation === null) && (
                    <Link className="dropdown-item" onClick={() => open5(row)}>
                      <i className="fas fa-sliders-h" style={{ fontSize: "16px", marginRight: "5px" }}></i>
                      Set Quotation
                    </Link>
                  )}
                {row.Send_Quotation !== null && row.Quotation === "No" && (
                  <Link className="dropdown-item" onClick={() => handleSubmit(row.Enquiry_Sr_No, row.Sr_No, row)}>
                    <i
                      className="fas fa-check-square"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Approve Quotation
                  </Link>
                )}
                {row.Quotation === "Yes" && row.Document_Status !== "Pending" && row.Net_Amount === "0.00" && (
  <Link 
    className="dropdown-item" 
    to={`/setInstallment/${row.Enquiry_Sr_No}`} 
    onClick={(e) => {
      e.preventDefault();  // Prevent the default behavior of Link
      window.open(`/setInstallment/${row.Enquiry_Sr_No}`, "_blank");  // Open the URL in a new tab
    }}
  >
    <i
      className="fas fa-user-cog text-primary"
      style={{ fontSize: "16px", marginRight: "5px" }}
    ></i>
    Set Installment
  </Link>
)}

{row.Net_Amount !== "0.00" && (
  <>
    <Link
      className="dropdown-item"
      to={`/makePayments/${row.Enquiry_Sr_No}`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/makePayments/${row.Enquiry_Sr_No}`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-cash-register text-success"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Payment
    </Link>
    <Link
      className="dropdown-item"
      to={`/invoice/${row.Enquiry_Sr_No}/Facility`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/invoice/${row.Enquiry_Sr_No}/Facility`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-file-invoice text-info"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Invoice
    </Link>
  </>
)}

                 {row.Quotation === "Yes" && row.Document_Status === "Pending" && (
                    <Link className="dropdown-item" onClick={() => open3(row)}>
                      <i
                        className="ti ti-file-upload text-warning"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      ></i>
                      Upload Documents
                    </Link>
                  )}
                  {row.Acknowledgement === "No" && (
                    <Link className="dropdown-item" onClick={() => open7(row)}>
                      <i
                        className="fas fa-scroll"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      ></i>
                      Upload Acknowledgement
                    </Link>
                  )}
    {String(row.Acknowledgement) === "Yes" &&
 String(row.Annual_Return) === "Yes" &&
 String(row.Annual_Return_Exists) === "No" && (
    <Link className="dropdown-item" onClick={() => open10(row)}>
      <i
        className="fas fa-envelope"
        style={{
          fontSize: "16px",
          marginRight: "5px",
          color: "#FF9800", // Light green color
        }}
      ></i>
      Send Annual Return
    </Link>
  )}



                  {row.Status === "Active" && (
                    <Link className="dropdown-item" onClick={() => open(row)}>
                      <i
                        className="ti ti-upload text-info"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      ></i>
                      {row.Upload_Certificate ? "Reupload" : "Upload"} Certificate
                    </Link>
                  )}
                {row.Document_Status === "Inprocess" && (
                  <Link className="dropdown-item" onClick={() => open1(row)}>
                    <i
                      className="fas fa-clipboard-check"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Verify Documents
                  </Link>
                )}
                {row.Payment_Status === "Inprocess" && (
                  <Link className="dropdown-item" onClick={() => open2(row)}>
                    <i
                      className="fas fa-clipboard-check"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Verify Payment
                  </Link>
                )}
                {docStatus[row.Enquiry_Sr_No] && (
                  <Link className="dropdown-item" onClick={() => open4(row)}>
                    <i
                      className="fas fa-cloud-upload-alt"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Update Documents
                  </Link>
                )}
              </div>
            </div>
          );
        } else {
          // If Vendor_Id is not present, show all the menu items
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* Conditions when Vendor_Id is not present */}
                {(!row.Staff_Id && !row.Vendor_Id && (!row.Acknowledgement || row.Acknowledgement === null || row.Acknowledgement === "")) && (
                  <Link className="dropdown-item" onClick={() => open8(row)}>
                    <i className="fas fa-handshake" style={{ fontSize: "16px", marginRight: "5px", color: "blue" }}></i>
                    Assign Enquiry
                  </Link>
                )}

<Link className="dropdown-item" onClick={() => open9(row)}>
  <i className="fas fa-clipboard" style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}></i>
  &nbsp;View Summary
</Link>
<Link className="dropdown-item" onClick={() => open11(row)}>
  <i className="fas fa-file-invoice-dollar" style={{ fontSize: "16px", marginRight: "5px", color: "#008a00" }}></i>
  &nbsp;Purchase Data
</Link>


                
                {row.Quotation === "No" && row.Consultation !== "0.00" && row.Net_Amount === "0.00" && (
                  <Link className="dropdown-item" onClick={() => open6(row)}>
                    <i className="fas fa-receipt" style={{ fontSize: "16px", marginRight: "5px" }}></i>
                    Quotation Preview
                  </Link>
                )}
                {(row.Gvt_Amount === "0.00" || row.Gvt_Amount === null) &&
                  (row.Consultation === "0.00" || row.Consultation === null) && (
                    <Link className="dropdown-item" onClick={() => open5(row)}>
                      <i className="fas fa-sliders-h" style={{ fontSize: "16px", marginRight: "5px" }}></i>
                      Set Quotation
                    </Link>
                  )}
                {row.Send_Quotation !== null && row.Quotation === "No" && (
                  <Link className="dropdown-item" onClick={() => handleSubmit(row.Enquiry_Sr_No, row.Sr_No, row)}>
                    <i
                      className="fas fa-check-square"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Approve Quotation
                  </Link>
                )}
                {row.Quotation === "Yes" && row.Document_Status !== "Pending" && row.Net_Amount === "0.00" && (
  <Link 
    className="dropdown-item" 
    to={`/setInstallment/${row.Enquiry_Sr_No}`} 
    onClick={(e) => {
      e.preventDefault();  // Prevent the default behavior of Link
      window.open(`/setInstallment/${row.Enquiry_Sr_No}`, "_blank");  // Open the URL in a new tab
    }}
  >
    <i
      className="fas fa-user-cog text-primary"
      style={{ fontSize: "16px", marginRight: "5px" }}
    ></i>
    Set Installment
  </Link>
)}

{row.Net_Amount !== "0.00" && (
  <>
    <Link
      className="dropdown-item"
      to={`/makePayments/${row.Enquiry_Sr_No}`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/makePayments/${row.Enquiry_Sr_No}`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-cash-register text-success"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Payment
    </Link>
    <Link
      className="dropdown-item"
      to={`/invoice/${row.Enquiry_Sr_No}/Facility`}
      onClick={(e) => {
        e.preventDefault();  // Prevent default Link navigation
        window.open(`/invoice/${row.Enquiry_Sr_No}/Facility`, "_blank");  // Open in new tab
      }}
    >
      <i
        className="fas fa-file-invoice text-info"
        style={{ fontSize: "18px", marginRight: "5px" }}
      ></i>
      Invoice
    </Link>
  </>
)}

                 {row.Quotation === "Yes" && row.Document_Status === "Pending" && (
                    <Link className="dropdown-item" onClick={() => open3(row)}>
                      <i
                        className="ti ti-file-upload text-warning"
                        style={{ fontSize: "18px", marginRight: "5px" }}
                      ></i>
                      Upload Documents
                    </Link>
                  )}
                  {row.Acknowledgement === "No" && (
                    <Link className="dropdown-item" onClick={() => open7(row)}>
                      <i
                        className="fas fa-scroll"
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      ></i>
                      Upload Acknowledgement
                    </Link>
                  )}
                {row.Document_Status === "Inprocess" && (
                  <Link className="dropdown-item" onClick={() => open1(row)}>
                    <i
                      className="fas fa-clipboard-check"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Verify Documents
                  </Link>
                )}
                {row.Payment_Status === "Inprocess" && (
                  <Link className="dropdown-item" onClick={() => open2(row)}>
                    <i
                      className="fas fa-clipboard-check"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Verify Payment
                  </Link>
                )}
                {docStatus[row.Enquiry_Sr_No] && (
                  <Link className="dropdown-item" onClick={() => open4(row)}>
                    <i
                      className="fas fa-cloud-upload-alt"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Update Documents
                  </Link>
                )}
                {userData.Role === "Admin" && (
                  <Link
                    className="dropdown-item"
                    onClick={() =>
                      deleteUser(
                        row.Enquiry_Sr_No,
                        `${row.First_Name} ${row.Last_Name}`,
                        userData.UserId
                      )
                    }
                  >
                    <DeleteIcon
                      className="text-danger"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    Delete
                  </Link>
                )}
              </div>
            </div>
          );
        }
        return null; // If none of the conditions are met, return null to hide the dropdown
      },
      width: "45px",
    }
    
    
    
  ];


  const filteredColumns = userData?.Vendor_Id
  ?    [
    ...columns.filter(
      (column) =>
        column.name !== "Name" &&
        column.name !== "Customer Company" &&
        column.name !== "Mobile" // Add this line to exclude Mobile Number
    ),
  ]
  : columns;

// Define the dynamic column with a static header
const dynamicColumn = {
  name: "Company Name", // Keep the header static
  cell: (row) => (
    <LightTooltip
      title={row.Role === "Agent" ? row.Customer_Company : row.Company_Name}
    >
      <span
        style={{
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "5px",
          cursor: "pointer",
        }}
      >
        {row.Role === "Agent"
          ? row.Customer_Company
          : row.Company_Name || "--"}
      </span>
    </LightTooltip>
  ),
  width: "180px",
};



// Insert the dynamic column after the "Name" column
if (userData?.Vendor_Id) {
  const nameColumnIndex = filteredColumns.findIndex(
    (column) => column.name === "Name"
  );
  if (nameColumnIndex !== -1) {
    filteredColumns.splice(nameColumnIndex + 1, 0, dynamicColumn);
  }
}


  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  

  const DownloadPDF = () => {
    const doc = new jsPDF({
        orientation: "landscape",
    });

    doc.text("Annual Returns", 20, 10);

    // Define the base columns (headers)
    const baseColumns = [
        "Sr No",
        "Enquiry Id",
        "Customer Id",
        "Name",
        "Company Name", // Company Name
        "Customer Company", // Customer Company
        "Certificate Name",
        "Remarks",
        "Added On",
        "State",
        "GST No.",
        "Government",
        "Consultation",
        "Total Consultation",
        "Net Amount",
        "GST Amount",
        "Application No",
        "Application Date",
        "Username",
        "Password",
        "Link",
        "Mobile Number",
        "Certificate Number",
        "Certificate Date",
        "Renewal Date",
        "Financial Year",
        "Vendor Name",
        "Staff Name",
    ];

    // Adjust columns based on Vendor_Id or Agent_Id
    const columns =
        userData?.Vendor_Id || userData?.Agent_Id
            ? baseColumns.filter(
                  (col) =>
                      ![
                          "Name",
                          "Mobile Number",
                          "Government",
                          "Consultation",
                          "Total Consultation",
                          "Net Amount",
                          "GST Amount",
                      ].includes(col)
              )
            : baseColumns;

    // Add logic to show either "Company Name" or "Customer Company" based on user type
    if (userData?.Vendor_Id && !columns.includes("Company Name")) {
        columns.splice(4, 0, "Company Name"); // Insert Company Name column after Name
    }
    if (userData?.Agent_Id && !columns.includes("Customer Company")) {
        columns.splice(4, 0, "Customer Company"); // Insert Customer Company column after Name
    }

    // Prepare the rows dynamically
    const rows = data.map((row) => {
        const baseRow = {
            "Sr No": row.Sr_No,
            "Enquiry Id": row.Enquiry_Id,
            "Customer Id": row.CustomerId,
            "Name": `${row.First_Name} ${row.Last_Name}`,
            "Company Name": row.Company_Name,
            "Customer Company": row.Customer_Company || "--",
            "Certificate Name": row.Certificate_Name,
            "Remarks": row.Remarks || "--",
            "Added On": formatDate(row.Added_On),
            "State": row.State,
            "GST No.": row.GST_No,
            "Government": row.Government,
            "Consultation": row.Consultation,
            "Total Consultation": row.Total_Consultation,
            "Net Amount": row.Net_Amount,
            "GST Amount": row.Gst_Amount,
            "Application No": row.Application_No || "--",
            "Application Date": formatDate(row.Application_Date),
            "Username": row.Application_Username,
            "Password": row.Application_Password,
            "Link": row.Link || "--",
            "Mobile Number": row.Mobile,
            "Certificate Number": row.Certificate_Number || "--",
            "Certificate Date": formatDate(row.Certificate_Date),
            "Renewal Date": formatDate(row.Renewal_Date),
            "Financial Year": row.Financial_Year || "--",
            "Vendor Name": row.Vendor_Name || "--",
            "Staff Name": row.Staff_Name || "--",
        };

        // Adjust the row for Vendor_Id or Agent_Id
        return userData?.Vendor_Id || userData?.Agent_Id
            ? columns.map((col) => baseRow[col]) // Map only the selected columns
            : Object.values(baseRow);
    });

    // Add the table to the PDF
    doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
    });

    // Save the generated PDF
    doc.save("Annual Returns.pdf");
};



const DownloadExcel = () => {
  const baseColumns = [
      "Sr No", "Enquiry Id", "Customer Id", "Name", "Company Name", "Customer Company",
      "Certificate Name", "Remarks", "Added On", "State", "GST No.", "Government",
      "Consultation", "Total Consultation", "Net Amount", "GST Amount", "Application No",
      "Application Date", "Username", "Password", "Link", "Mobile Number",
      "Certificate Number", "Certificate Date", "Renewal Date", "Financial Year",
      "Vendor Name", "Staff Name"
  ];

  const restrictedColumns = [
      "Name", "Company Name", "Customer Company", "Mobile Number",
      "Government", "Consultation", "Total Consultation", "Net Amount", "GST Amount"
  ];

  let columns = userData?.Vendor_Id || userData?.Agent_Id 
      ? baseColumns.filter(col => !restrictedColumns.includes(col))
      : [...baseColumns];

      if (userData?.Vendor_Id && !columns.includes("Company Name")) {
        columns.splice(3, 0, "Company Name");
    }
    if (userData?.Agent_Id && !columns.includes("Customer Company")) {
        columns.splice(3, 0, "Customer Company");
    }
  const rows = [columns];

  data.forEach(row => {
      const baseRow = {
          "Sr No": row.Sr_No, "Enquiry Id": row.Enquiry_Id, "Customer Id": row.CustomerId,
          "Name": `${row.First_Name} ${row.Last_Name}`, "Company Name": row.Company_Name,
          "Customer Company": row.Customer_Company || "--", "Certificate Name": row.Certificate_Name,
          "Remarks": row.Remarks || "--", "Added On": formatDate(row.Added_On), "State": row.State,
          "GST No.": row.GST_No, "Government": row.Government, "Consultation": row.Consultation,
          "Total Consultation": row.Total_Consultation, "Net Amount": row.Net_Amount,
          "GST Amount": row.Gst_Amount, "Application No": row.Application_No || "--",
          "Application Date": formatDate(row.Application_Date), "Username": row.Application_Username,
          "Password": row.Application_Password, "Link": row.Link || "--", "Mobile Number": row.Mobile,
          "Certificate Number": row.Certificate_Number || "--", "Certificate Date": formatDate(row.Certificate_Date),
          "Renewal Date": formatDate(row.Renewal_Date), "Financial Year": row.Financial_Year || "--",
          "Vendor Name": row.Vendor_Name || "--", "Staff Name": row.Staff_Name || "--"
      };

      rows.push(columns.map(col => baseRow[col]));
  });

  const workSheet = XLSX.utils.aoa_to_sheet(rows);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "Annual Returns");
  XLSX.writeFile(workBook, "Annual Returns.xlsx");
};



  const handleFilter = (event) => {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = dataa.filter((row) => {
      const fullName = `${row.First_Name} ${row.Last_Name}`.toLowerCase();
      const currentPendingStep = getCurrentPendingStep(row).toLowerCase();

      // Filter based on the pending step and other fields
      return (
        (pendingStepFilter === "All" || currentPendingStep.includes(pendingStepFilter.toLowerCase())) &&
        (
          fullName.includes(filterValue) ||
          row.Mobile?.toLowerCase().includes(filterValue) ||
          row.Email?.toLowerCase().includes(filterValue) ||
          row.Address?.toLowerCase().includes(filterValue) ||
          row.CustomerId?.toString().toLowerCase().includes(filterValue) ||
          (row.Added_On && formatDate(row.Added_On).toLowerCase().includes(filterValue)) ||
          row.Certificate_Name?.toLowerCase().includes(filterValue) ||
          row.Company_Name?.toLowerCase().includes(filterValue) ||
          row.Remarks?.toLowerCase().includes(filterValue)
        )
      );
    });

    setStaffData(filteredData);
  };


  return (
    <div className="container-fluid" style={{ padding: "0" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Annual Returns</h4>
            {/* <div className="page-title">
              <Link to={"/addUser"}>
                <button className="btn btn-primary btn-sm text-center">
                  Add
                </button>
              </Link>
            </div> */}
          </div>
          <div className="card">
            <h5 className="page-title"></h5>
            <div className="card-body" style={{ paddingTop: "5px" }}>
              <div className="row justify-content-center mt-2">
              {(!userData?.Agent_Id && !userData?.Vendor_Id) && ( // Render if neither Agent_Id nor Vendor_Id is present
  <div className="col-3">
    <label htmlFor="company-select" className="form-label">
      Company
    </label>
    <Select
      id="company-select"
      value={
        filter.Customer_Id
          ? {
              value: filter?.Customer_Id,
              label: filter?.Company_Name,
            }
          : null
      }
      options={[
        { value: "", label: "All" },
        ...customerData.map((item) => ({
          value: item.Sr_No,
          label: item.Company_Name,
        })),
      ]}
      onChange={(selectedOption) => {
        setFilter({
          ...filter,
          Customer_Id: selectedOption.value,
          Company_Name: selectedOption.label,
        });
      }}
    />
  </div>
)}




<div className="col-3">
  <label htmlFor="certificate-select" className="form-label">
    Certificate Name
  </label>
  <Select
    id="certificate-select"
    value={
      filter.Certificate_Id
        ? {
            value: filter.Certificate_Id,
            label: filter.Certificate_Name,
          }
        : null
    }
    options={[
      { value: "", label: "All" }, // Default option for selecting all certificates
      ...[
        ...new Map(
          dataa.map((item) => [
            item.Certificate_Id, // Using Certificate_Id as the key
            {
              value: item.Certificate_Id,
              label: item.Certificate_Name,
            },
          ]),
        ).values(),
      ], // Remove duplicates by using Map
    ]}
    onChange={(selectedOption) => {
      setFilter({
        ...filter,
        Certificate_Id: selectedOption.value,
        Certificate_Name: selectedOption.label,
      });
    }}
  />
</div>



                <div className="col-3">
                  <label htmlFor="example-select" className="form-label">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={filter.fromDate}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        fromDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className="col-3">
                  <label htmlFor="example-select" className="form-label">
                    To Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={filter.toDate}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        toDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-2">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={filterData}
                    style={{ width: "100%" }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
            <Stack
      direction="row"
      spacing={2}
      className="mx-2 my-2 mb-2"
      sx={{ zIndex: 0, position: "relative" }}
    >
      <TextField
        onChange={handleFilter}
        size="small"
        label="Search..."
      />
      <div>
        <Tooltip title="Export to PDF">
          <i
            style={{
              cursor: "pointer",
              padding: "5px",
            }}
            onClick={() => {
              DownloadPDF();
            }}
          >
            <PictureAsPdfIcon />
          </i>
        </Tooltip>

        <Tooltip title="Export to Excel">
          <i
            style={{
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadExcel();
            }}
          >
            <Icon path={mdiFileExcel} size={1} />
          </i>
        </Tooltip>
      </div>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
      <div className="col-4" style={{ marginTop: "-30px" }}>
  <label htmlFor="pending-steps" className="form-label">
    Pending Steps
  </label>
  <select id="pending-steps" className="form-select" value={pendingStepFilter}
        onChange={handlePendingStepChange}>
    {pendingStepOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
</div>

      </div>
    </Stack>

              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={filteredColumns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
          <CustomPasswordModal
            showModal={showModal}
            onClose={closePasswordModal}
            Sr_No={Sr_No}
            Name={Name}
            UserId={UserId}
            actionType="deleteUser"
          />

        </div>

        {isModal && (
          <>
            <UploadCertificate
              open={isModal}
              onClose={closed}
              Modaldata={Modaldata}
            />
          </>
        )}
        {isModal1 && (
          <>
            <ApproveRejectDoc
              open={isModal1}
              onClose={closed1}
              updateData={Modaldata}
            />
          </>
        )}


        {isModal2 && (
          <>
            <ApproveRejectPayment
              open={isModal2}
              onClose={closed2}
              updateData={Modaldata}
            />
          </>
        )}

        {isModal3 && (
          <UploadDocument
            open={isModal3}
            onClose={closed3}
            updateData={Modaldata}
            Role={"Facility"}
          />
        )}

        {isModal4 && (
          <UpdateDocumentByFac
            open={isModal4}
            onClose={closed4}
            updateData={Modaldata}
          />
        )}
        {isModal5 && (
          <SetQuotation
            open={isModal5}
            onClose={closed5}
            updateData={Modaldata}
            actionType="SetQuotationForAnnualReturn"
          />
        )}
        {isModal6 && (
          <QuotationPreview
            open={isModal6}
            onClose={closed6}
            updateData={Modaldata}
          />
        )}
        {isModal7 && (
          <UploadAcknowledgement
            open={isModal7}
            onClose={closed7}
            updateData={Modaldata}
            actionType="UploadAcknowledgementForAnnualReturn"
          />
        )}
        {isModal8 && (
          <AssignEnquiry
            open={isModal8}
            onClose={closed8}
            updateData={Modaldata}
            actionType="AssignEnquiryForAnnualReturn"
          />
        )}
        {isModal9 && (
          <SummaryModal
            open={isModal9}
            onClose={closed9}
            updateData={Modaldata}
            actionType="SummaryModalForAnnualReturn"
          />
        )}
        {isModal10 && (
          <SendAnnualReturn
            open={isModal10}
            onClose={closed10}
            agentData={Modaldata}
          />
        )}
        {isModal11 && (
          <PurchaseData
            open={isModal11}
            onClose={closed11}
            agentData={Modaldata}
          />
        )}
        {loader}
      </div>
    </div>
  );
};
export default AnnualReturn;
