import React, { useState,useEffect } from "react";
import axios, { apiUrl } from "../../components/axios";
import { useUser } from "../../components/UserContext";
import Swal from 'sweetalert2';
import useFullPageLoader from "../../components/useFullPageLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Designation from "../Customer/Designation";


const AddVendor = () => {

  const [certificateDetails,setCertificateDetails] = useState([])
  const navigate = useNavigate()
  const { userData } = useUser();   
  const [errors, setErrors] = useState({});
  const [staffData, setStaffData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [certificateData, setCertificateData] = useState([]);
  const [vendor, setVendor] = useState([])
  const {Vendor_Id} = useParams()
  const [isModal, setIsModal] = useState(false)
  const [Modaldata, setModalData] = useState();
  const [designation,setDesignation] = useState([])
  const [formData, setFormData] = useState({
    Company_Name: "",
    Name: "",
    Mobile: "",
    Telephone: "",
    Email: "",
    GST_No: "",
    Address: "",
    Staff_1_Name: "",
    Staff_1_Mobile: "",
    Staff_1_Email: "",
    Staff_2_Name: "",
    Staff_2_Mobile: "",
    Staff_2_Email: "",
    EPR_Staff_Id: "",
    EPR_Staff_Name: "",
    Amount: "",
    Certificate_Id: "",  // New field for Certificate ID
    Certificate_Name: "", // New field for Certificate Name
    Vendor_Designation:"",
    Staff_1_Designation:"",
    Staff_2_Designation:"",
    Remarks:"",
    Company_Type:"",
    VisitingCardBack:"",
    VisitingCardFront:"",
    Government_Charges:"",
    City:"",
    District:"",
    State:"",
    Reference:"",
    Other_Reference:"",

  });

  const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];

  const open = (e) => {
    setModalData(e);
    setIsModal(true);
  };

  const closed = () => {
    setIsModal(false);
    loadDesignation();
  };

      const loadDesignation = async () => {
        showLoader(); // Show loader at the start of the function
      
        try {
          const result = await axios.get(`/getAllDesignations`);
          setDesignation(result.data.designations || []);
        } catch (error) {
          console.error("Error fetching data: ", error);
        } finally {
          hideLoader(); // Hide loader after the function finishes
        }
      };
  



  const loadStaffData = async () => {
    showLoader(); // Show loader at the start of the function
  
    try {
      const result = await axios.get(`/getUsers?OrgId=${userData?.Org_Id}`);
      setStaffData(result.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      alert("Failed to load staff data. Please try again later.");
    } finally {
      hideLoader(); // Hide loader after the function finishes
    }
  };
  
  const loadCertificateData = async () => {
    showLoader(); // Show loader at the start of the function
  
    try {
      const result = await axios.get('/getCertificateType'); // Fetch data from API
      setCertificateData(result.data); // Update the certificate data state with fetched data
    } catch (error) {
      console.error('Error fetching certificate data:', error);
      alert('Failed to load certificate data. Please try again later.');
    } finally {
      hideLoader(); // Hide loader after the function finishes
    }
  };
  


  useEffect(() => {
    if (Vendor_Id) {
      const fetchVendorDetails = async () => {
        showLoader(); // Show loader before starting the request
        
        try {
          const response = await axios.get(`/getVendorById/${Vendor_Id}`);
          
          if (response.status === 200) {
            const { vendor } = response.data;
            
            // Sanitize null values in the vendor object
            const sanitizedVendor = Object.fromEntries(
              Object.entries(vendor).map(([key, value]) => [key, value === null ? "" : value])
            );
            setVendor(sanitizedVendor)
  
            // Set the sanitized vendor details in formData
            setFormData({
              Name: sanitizedVendor.Name,
              Company_Name: sanitizedVendor.Company_Name,
              Mobile: sanitizedVendor.Mobile,
              Telephone: sanitizedVendor.Telephone,
              Email: sanitizedVendor.Email,
              GST_No: sanitizedVendor.GST_No,
              EPR_Staff_Id: sanitizedVendor.EPR_Staff_Id,
              EPR_Staff_Name: sanitizedVendor.EPR_Staff_Name,
              Address: sanitizedVendor.Address,
              Staff_1_Name: sanitizedVendor.Staff_1_Name,
              Staff_1_Mobile: sanitizedVendor.Staff_1_Mobile,
              Staff_1_Email: sanitizedVendor.Staff_1_Email,
              Staff_2_Name: sanitizedVendor.Staff_2_Name,
              Staff_2_Mobile: sanitizedVendor.Staff_2_Mobile,
              Staff_2_Email: sanitizedVendor.Staff_2_Email,
              Amount: sanitizedVendor.Amount,
              Vendor_Designation: sanitizedVendor.Vendor_Designation,
              Staff_1_Designation: sanitizedVendor.Staff_1_Designation,
              Staff_2_Designation: sanitizedVendor.Staff_2_Designation,
              Remarks: sanitizedVendor.Remarks,
              Company_Type: sanitizedVendor.Company_Type,
              City: sanitizedVendor.City,
              District: sanitizedVendor.District,
              State: sanitizedVendor.State,
              Reference: sanitizedVendor.Reference,
              Other_Reference: sanitizedVendor.Other_Reference

            });
  
            // Fetch certificates
            showLoader(); // Show loader for fetching certificates
            const certificatesResponse = await axios.get(`/getCertificatesByVendorId/${Vendor_Id}`);
            
            if (certificatesResponse.status === 200) {
              setCertificateDetails(certificatesResponse.data.certificates); // Set certificates in state
            }
          }
        } catch (error) {
          console.error('Error fetching vendor details:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch vendor details.',
          });
        } finally {
          hideLoader(); // Hide loader after fetching
        }
      };
  
      fetchVendorDetails();
    }
  }, [Vendor_Id]);
  
  
  
  
  

  
const handleInputChange = (e) => {
  const { id, value } = e.target;

  setFormData((prevState) => ({
    ...prevState,
    [id]: value || "",
  }));
};

const handleInputChangeForState = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Ensure the value updates
  });
};



const handleInputChangeForReference = (e) => {
  const { name, value } = e.target;
  
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,

    // ✅ If selecting a different option (not "Others"), clear Other_Reference
    ...(name === "Reference" && value !== "Others" && { Other_Reference: "" }),
  }));
};
  


  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    };

    const handleStaffChange = (e) => {
      const selectedId = e.target.value;
      const selectedName = staffData.find(
        (staff) => staff.UserId === parseInt(selectedId)
      );
    
      if (selectedName) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          EPR_Staff_Id: selectedName.UserId,
          EPR_Staff_Name: `${selectedName.First_Name} ${selectedName.Last_Name}` // Correct concatenation
        }));
      }
    };
    

    const handleCertificateChange = (e) => {
      const selectedSrNo = e.target.value;
      const selectedCert = certificateData.find(
        (certificate) => certificate.Sr_No === parseInt(selectedSrNo)
      );
    
      if (selectedCert) {
        // Update formData with both Certificate_Id and Certificate_Name
        setFormData((prevFormData) => ({
          ...prevFormData,
          Certificate_Id: selectedCert.Sr_No,  // Save Certificate ID (Sr_No)
          Certificate_Name: selectedCert.Certificate_Name,  // Save Certificate Name
        }));
      }
    };
    
    
  


    const handleCertificateDetails = () => {
      
      if (validate()) {
        const newCertificateDetails = {
          Certificate_Id: formData.Certificate_Id,
          Certificate_Name: formData.Certificate_Name,
          Amount: formData.Amount,
          Government_Charges: formData.Government_Charges,
        };
    
        // Update the state by appending the new details
        setCertificateDetails((prevDetails) => [...prevDetails, newCertificateDetails]);
      }
    };
    

    const validate = () => {
      let tempErrors = {};

      // Required field validations
      if (!formData.Company_Name) tempErrors.Company_Name = "Company Name is required.";
      if (!formData.Name) tempErrors.Name = "Vendor Name is required.";
      if (!formData.Mobile) {
        tempErrors.Mobile = "Mobile number is required.";
      } else if (!/^\d{10}$/.test(formData.Mobile)) {
        tempErrors.Mobile = "Mobile number must be 10 digits.";
      }
      

      if (!formData.Email && !/\S+@\S+\.\S+/.test(formData.Email)) {
        tempErrors.Email = "Invalid email format.";
      }

      if (!formData.GST_No && !/^\d{15}$/.test(formData.GST_No)) {
        tempErrors.GST_No = "GST Number must be 15 characters.";
      }

      // if (!formData.Amount) {
      //   tempErrors.Amount = "Amount is required.";
      // } else if (isNaN(formData.Amount)) {
      //   tempErrors.Amount = "Amount must be a valid number.";
      // }

      // Staff validations

      if (formData.Staff_1_Name && !/^[a-zA-Z\s]+$/.test(formData.Staff_1_Name)) {
        tempErrors.Staff_1_Name = "Staff 1 Name is invalid."; // Example for name format validation
      }
      
      if (formData.Staff_1_Mobile && !/^[6-9]\d{9}$/.test(formData.Staff_1_Mobile)) {
        tempErrors.Staff_1_Mobile = "Staff 1 Mobile is invalid."; // Mobile number format validation
      }      
      
      if (formData.Staff_1_Email && !/\S+@\S+\.\S+/.test(formData.Staff_1_Email)) {
        tempErrors.Staff_1_Email = "Staff 1 Email is invalid."; // Email format validation
      }
      
      if (formData.Staff_2_Name && !/^[a-zA-Z\s]+$/.test(formData.Staff_2_Name)) {
        tempErrors.Staff_2_Name = "Staff 2 Name is invalid."; // Example for name format validation
      }
      
      if (formData.Staff_2_Mobile && !/^[6-9]\d{9}$/.test(formData.Staff_2_Mobile)) {
        tempErrors.Staff_2_Mobile = "Staff 2 Mobile is invalid."; // Mobile number format validation
      }
      
      if (formData.Staff_2_Email && !/\S+@\S+\.\S+/.test(formData.Staff_2_Email)) {
        tempErrors.Staff_2_Email = "Staff 2 Email is invalid."; // Email format validation
      }
      
      if (!formData.EPR_Staff_Id || !formData.EPR_Staff_Name ){
      tempErrors.EPR_Staff_Id = "EPR Staff is required."
      }

      if (!formData.Vendor_Designation ){
        tempErrors.Vendor_Designation = "Designation is required."
        }

      // if (!formData.Certificate_Name || !formData.Certificate_Id ){
      //   tempErrors.Certificate_Name = "Certificate Type is required."
      // }

      

      // Address validation
    // if (!formData.Address) tempErrors.Address = "Address is required.";

      setErrors(tempErrors);

      return Object.keys(tempErrors).length === 0; // Returns true if no errors
    };

    const handleCertifcateDelete = (index) => {
      const updatedCertificateDetails = certificateDetails.filter(
        (certificate, certIndex) => certIndex !== index
      );
      setCertificateDetails(updatedCertificateDetails);
    };


    const handleSubmit = async (e) => {
      e.preventDefault();
      
      // Show loader
      showLoader();
    
      if (!validate()) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Failed',
          text: 'Please fill all required fields correctly.',
        });
        hideLoader();
        return;
      }
    
      try {
        const FormDataPayload = new FormData();
        FormDataPayload.append('Name', formData.Name);
        FormDataPayload.append('Company_Name', formData.Company_Name);
        FormDataPayload.append('Mobile', formData.Mobile);
        FormDataPayload.append('Telephone', formData.Telephone);
        FormDataPayload.append('Email', formData.Email);
        FormDataPayload.append('GST_No', formData.GST_No);
        FormDataPayload.append('EPR_Staff_Id', formData.EPR_Staff_Id);
        FormDataPayload.append('EPR_Staff_Name', formData.EPR_Staff_Name);
        FormDataPayload.append('Address', formData.Address);
        FormDataPayload.append('Staff_1_Name', formData.Staff_1_Name);
        FormDataPayload.append('Staff_1_Mobile', formData.Staff_1_Mobile);
        FormDataPayload.append('Staff_1_Email', formData.Staff_1_Email);
        FormDataPayload.append('Staff_2_Name', formData.Staff_2_Name);
        FormDataPayload.append('Staff_2_Mobile', formData.Staff_2_Mobile);
        FormDataPayload.append('Staff_2_Email', formData.Staff_2_Email);
        FormDataPayload.append('Org_Id', userData?.Org_Id);
        FormDataPayload.append('Added_By_Id', userData?.UserId);
        FormDataPayload.append('Added_By', `${userData.First_Name} ${userData.Last_Name}`);
        FormDataPayload.append('Vendor_Designation', formData.Vendor_Designation);
        FormDataPayload.append('Staff_1_Designation', formData.Staff_1_Designation);
        FormDataPayload.append('Staff_2_Designation', formData.Staff_2_Designation);
        FormDataPayload.append('Company_Type', formData.Company_Type);
        FormDataPayload.append('Remarks', formData.Remarks);
        FormDataPayload.append('City', formData.City);
        FormDataPayload.append('District', formData.District);
        FormDataPayload.append('State', formData.State);
        FormDataPayload.append('Reference', formData.Reference);
        FormDataPayload.append('Other_Reference', formData.Other_Reference);
    
        // Append files if they exist
        if (formData.VisitingCardFront instanceof File) {
          FormDataPayload.append('VisitingCardFront', formData.VisitingCardFront || "");
        }
        if (formData.VisitingCardBack instanceof File) {
          FormDataPayload.append('VisitingCardBack', formData.VisitingCardBack || "");
        }
    
        // Append certificate details
        FormDataPayload.append('certificateDetails', JSON.stringify(certificateDetails));
    
        const endpoint = Vendor_Id ? `/editVendor/${Vendor_Id}` : '/addVendors';
        const method = Vendor_Id ? 'put' : 'post';
    

        // Send request using axios with multipart/form-data
        const response = await axios({
          method,
          url: endpoint,
          data: FormDataPayload,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: Vendor_Id ? 'Vendor Updated Successfully' : 'Vendor Added Successfully',
            text: `Vendor ${Vendor_Id ? 'updated' : 'added'} successfully.`,
          });
    
          navigate('/viewVendors');
    
          if (!Vendor_Id) {
            // Clear form data if adding a new vendor
            setFormData({
              Name: '',
              Company_Name: '',
              Mobile: '',
              Telephone: '',
              Email: '',
              GST_No: '',
              EPR_Staff_Id: '',
              EPR_Staff_Name: '',
              Address: '',
              Staff_1_Name: '',
              Staff_1_Mobile: '',
              Staff_1_Email: '',
              Staff_2_Name: '',
              Staff_2_Mobile: '',
              Staff_2_Email: '',
              Amount: '',
            });
            setCertificateDetails([]);
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Submission Failed',
            text: 'Failed to save the vendor. Please try again.',
          });
        }
      } catch (error) {
        console.error('Error while submitting form:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
        });
      } finally {
        hideLoader();
      }
    };
    
  
    


  useEffect(() => {
    loadCertificateData();
    loadStaffData();
    loadDesignation();
  }, [userData?.Org_Id]);


  


  







  return (
    <div className="row">
  <div className="col-12">
    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
    <h4 className="page-title">{Vendor_Id ? 'Update Vendor' : 'Add Vendor'}</h4>
    </div>
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          {/* Vendor Details Section */}
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Vendor Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="VendorName" className="form-label">
                  Vendor Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  className="form-control"
                  placeholder="Enter Vendor Name"
                  required
                  onChange={handleChange}
                  value={formData.Name}
                />
                  {errors.Name && <small className="text-danger">{errors.Name}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Company_Name" className="form-label">
                  Company Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Company_Name"
                  name="Company_Name"
                  className="form-control"
                  placeholder="Enter Company Name"
                  required
                  onChange={handleChange}
                  value={formData.Company_Name}
                />
              {errors.Company_Name && <small className="text-danger">{errors.Company_Name}</small>}       
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Mobile" className="form-label">
                  Mobile<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  id="Mobile"
                  name="Mobile"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  required
                  onChange={handleChange}
                  value={formData.Mobile}
                />
            {errors.Mobile && <small className="text-danger">{errors.Mobile}</small>}         
              </div>
            </div>
            <div className="col-md-3">
                <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="Vendor_Designation" className="form-label mb-0">
                      Designation<span style={{ color: "red" }}>*</span>
                    </label>
                    <Link
                      onClick={() => {
                        // Add your logic here for the button action
                        open("Vendor");
                      }}
                    >
                      Add
                    </Link>
                  </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Vendor_Designation"
      value={formData.Vendor_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Vendor Designation"
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
    {errors.Vendor_Designation && <small className="text-danger">{errors.Vendor_Designation}</small>}
  </div>
</div>

            
            
          </div>


          {/* Address Section */}
          <div className="row">
          <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Telephone" className="form-label">
                  Telephone
                </label>
                <input
                  type="text"
                  id="Telephone"
                  name="Telephone"
                  className="form-control"
                  placeholder="Enter Telephone Number"
                  onChange={handleChange}
                  value={formData.Telephone}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Email" className="form-label">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="Email"
                  id="Email"
                  placeholder="Enter Email"
                  required
                  onChange={handleChange}
                  value={formData.Email}
                />
              {errors.Email && <small className="text-danger">{errors.Email}</small>}         
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="GST_No" className="form-label">
                  GST Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="GST_No"
                  id="GST"
                  placeholder="Enter GST Number"
                  onChange={handleChange}
                  value={formData.GST_No}
                />
                {errors.GST_No && <small className="text-danger">{errors.GST_No}</small>}  
              </div>
            </div>
            
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff" className="form-label">
      EPR Staff<span style={{ color: "red" }}>*</span>
    </label>
    <select
      className="form-select"
      id="Staff"
      value={formData.EPR_Staff_Id}
      onChange={handleStaffChange}
      aria-label="Select EPR Staff"
    >
      <option value="" disabled>Select Staff</option>
      {staffData.length > 0 ? (
        staffData.map((staff) => (
          <option key={staff.UserId} value={staff.UserId}>
            {staff.First_Name} {staff.Last_Name}
          </option>
        ))
      ) : (
        <option disabled>No staff available</option>
      )}
    </select>
    {errors.EPR_Staff_Id && <small className="text-danger">{errors.EPR_Staff_Id}</small>}
  </div>
</div>


          </div>
          <div className="row">
        <div className="col-md-4">
          <label>City<span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            className="form-control"
            name="City"
            placeholder="Enter City"
            value={formData.City}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label>District<span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            className="form-control"
            name="District"
            placeholder="Enter District"
            value={formData.District}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label>State<span style={{ color: "red" }}>*</span></label>
          <select
            className="form-control"
            name="State"
            value={formData.State}
            onChange={handleInputChangeForState}
          >
            <option value="">Select State</option>
            {indianStates.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        
      </div>
      <div className="row">
          <div className={formData.Reference === "Others" ? "col-md-4" : "col-md-6"}>
          <label>Company Type</label>
          <input
            type="text"
            className="form-control"
            name="Company_Type"
            placeholder="Enter Company Type"
            value={formData.Company_Type}
            onChange={handleChange}
          />
        </div>
        <div className={formData.Reference === "Others" ? "col-md-4" : "col-md-6"}>
          <label>Mention Reference<span style={{ color: "red" }}>*</span></label>
          <select
            className="form-control"
            name="Reference"
            value={formData.Reference}
            onChange={handleInputChangeForReference}
          >
            <option value="">Select Reference</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Google">Google</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Others">Others</option>
          </select>
        </div>

        {/* ✅ Corrected Conditional Check */}
        {formData.Reference === "Others" && (
          <div className="col-md-4">
            <label>Mention Other Reference<span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              className="form-control"
              name="Other_Reference"
              value={formData.Other_Reference}
              onChange={handleChange}
              placeholder="Enter reference"
            />
          </div>
        )}
      </div>

          <div className="row">
            
          
            
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="Remarks" className="form-label">
                  Remarks
                </label>
                <textarea
                  id="Remarks"
                  name="Remarks"
                  className="form-control"
                  placeholder="Enter Remarks"
                  onChange={handleChange}
                  value={formData.Remarks}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="txtAddress" className="form-label">
                  Address
                </label>
                <textarea
                  id="txtAddress"
                  name="Address"
                  className="form-control"
                  placeholder="Enter Address"
                  onChange={handleChange}
                  value={formData.Address}
                />
              </div>
            </div>
          </div>
          <div className="row">
  {/* Visiting Card Front */}
  <div className={vendor.VisitingCardFront ? "col-md-5" : "col-md-6"}>
    <div className="mb-3">
      <label htmlFor="VisitingCardFront" className="col-form-label">
        Visiting Card (Front)
      </label>
      <input
        type="file"
        id="VisitingCardFront"
        className="form-control"
        onChange={(e) => {
          setFormData({ ...formData, VisitingCardFront: e.target.files[0] });
        }}
      />
    </div>
  </div>
  {vendor.VisitingCardFront && (
    <div className="col-md-1 mt-4">
      <a
        style={{ color: "blue", cursor: "pointer" }}
        href={`${apiUrl}/downloadVisitingCardFile/${vendor.VisitingCardFront}`}
        target="_blank"
        download={true}
        rel="noopener noreferrer"
        className="cursor-pointer"      >
        <i className="ti ti-download"></i>
      </a>
    </div>
  )}

  {/* Visiting Card Back */}
  <div className={vendor.VisitingCardBack ? "col-md-5" : "col-md-6"}>
    <div className="mb-3">
      <label htmlFor="VisitingCardBack" className="col-form-label">
        Visiting Card (Back)
      </label>
      <input
        type="file"
        id="VisitingCardBack"
        className="form-control"
        onChange={(e) => {
          setFormData({ ...formData, VisitingCardBack: e.target.files[0] });
        }}
      />
    </div>
  </div>
  {vendor.VisitingCardBack && (
    <div className="col-md-1 mt-4">
      <a
        style={{ color: "blue", cursor: "pointer" }}
        href={`${apiUrl}/downloadVisitingCardFile/${vendor.VisitingCardBack}`}
        download={true}
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer"
      >
        <i className="ti ti-download"></i>
      </a>
    </div>
  )}
</div>


          <div className="row ">
            <div className="col-12">
              <h5 className="section-title" style={{marginBottom:"-10px"}}>Staff 1 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff1Name"
                  name="Staff_1_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  onChange={handleChange}
                  value={formData.Staff_1_Name}
                />
               {errors.Staff_1_Name && <small className="text-danger">{errors.Staff_1_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_1_Mobile"
                  name="Staff_1_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={handleChange}
                  value={formData.Staff_1_Mobile}
                />
              {errors.Staff_1_Mobile && <small className="text-danger">{errors.Staff_1_Mobile}</small>}        
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Email" className="form-label">
                  Staff Email
                </label>
                <input
                  type="email"
                  id="Staff_1_Email"
                  name="Staff_1_Email"
                  className="form-control"
                  placeholder="Enter Staff Email"
                  onChange={handleChange}
                  value={formData.Staff_1_Email}
                />
              {errors.Staff_1_Email && <small className="text-danger">{errors.Staff_1_Email}</small>}  
              </div>
            </div>
            <div className="col-md-3">
                <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="Staff_1_Designation" className="form-label mb-0">
                      Designation
                    </label>
                    <Link
                      onClick={() => {
                        // Add your logic here for the button action
                        open("Vendor_Staff1");
                      }}
                    >
                      Add
                    </Link>
                  </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Staff_1_Designation"
      value={formData.Staff_1_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Staff Designation"
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>
          </div>
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Staff 2 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff_2_Name"
                  name="Staff_2_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  onChange={handleChange}
                  value={formData.Staff_2_Name}
                />
              {errors.Staff_2_Name && <small className="text-danger">{errors.Staff_2_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_2_Mobile"
                  name="Staff_2_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={handleChange}
                  value={formData.Staff_2_Mobile}
                />
              {errors.Staff_2_Mobile && <small className="text-danger">{errors.Staff_2_Mobile}</small>}  
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff_2_Email" className="form-label">
      Staff Email
    </label>
    <input
      type="email"
      id="Staff_2_Email"
      name="Staff_2_Email" 
      className="form-control"
      placeholder="Enter Staff Email"
      onChange={handleChange}  
      value={formData.Staff_2_Email}  
    />
    {errors.Staff_2_Email && <small className="text-danger">{errors.Staff_2_Email}</small>}
  </div>
</div>
<div className="col-md-3">
<div className="d-flex justify-content-between align-items-center">
      <label htmlFor="Staff_2_Designation" className="form-label mb-0">
        Designation
      </label>
      <Link
        onClick={() => {
          // Add your logic here for the button action
          open("Vendor_Staff2");
        }}
      >
        Add
      </Link>
    </div>
  <div className="mb-3">
    <select
      className="form-select"
      id="Staff_2_Designation"
      value={formData.Staff_2_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Staff Designation"
    >
      <option value="" >Select Designation</option>
      {designation.length > 0 ? (
        designation.map((d) => (
          <option key={d.Sr_No} value={d.Designation_Value}>
            {d.Designation_Value}
          </option>
        ))
      ) : (
        <option disabled>No Designation available</option>
      )}
    </select>
  </div>
</div>

          </div>
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Certificate Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
          <div className="col-md-4">
      <div className="mb-3">
        <label htmlFor="Certificate_Name" className="form-label">
          Certificate Type<span style={{ color: "red" }}>*</span>
        </label>
        <select
          className="form-select"
          id="Certificate_Name"
          name="Certificate_Name"
          aria-label="Select Certificate Type"
          defaultValue=""
          value={formData.Certificate_Id}  // Use formData.Certificate_Name for value
          onChange={handleCertificateChange}
        >
          <option value="" >
            Select Certificate Type
          </option>
          {certificateData.map((certificate) => (
            <option key={certificate.Sr_No} value={certificate.Sr_No}>
              {certificate.Certificate_Name}
            </option>
          ))}
        </select>
        {errors.Certificate_Name && <small className="text-danger">{errors.Certificate_Name}</small>}      </div>
          </div>
          <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Government_Charges" className="form-label">
                Government Charges<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  id="Government_Charges"
                  name="Government_Charges"
                  className="form-control"
                  placeholder="Enter Government Charges"
              
                  onChange={handleChange}
                  value={formData.Government_Charges}
                />
              {errors.Government_Charges && <small className="text-danger">{errors.Government_Charges}</small>}  
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="Amount" className="form-label">
                  Amount<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  id="Amount"
                  name="Amount"
                  className="form-control"
                  placeholder="Enter Amount"
              
                  onChange={handleChange}
                  value={formData.Amount}
                />
              {errors.Amount && <small className="text-danger">{errors.Amount}</small>}  
              </div>
            </div>
            </div>

            <div className="row justify-content-center">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="button" className="btn btn-primary" onClick={handleCertificateDetails}>
      Add
    </button>
  </div>
</div>
          {certificateDetails.length > 0 && (
  <div className="row mt-4">
    <div className="col-md-12">
      <table className="table table-striped table-bordered display dataTable">
        <thead>
          <tr>
            <th>Certificate Name</th>
            <th>Government Charges</th>
            <th>Amount</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          {certificateDetails.map((certificate, index) => (
            <tr key={index}>
              <td
                className="text-truncate cursor-pointer"
                style={{ maxWidth: "200px" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={certificate.Certificate_Name}
              >
                {certificate.Certificate_Name}
              </td>
              <td>{certificate.Government_Charges}</td>
              <td>{certificate.Amount}</td>
              <td className="text-center">
        <i
          className="far fa-trash-alt"
          style={{
            cursor: "pointer",
            color: "#dc3545", // Initially red
            fontSize: "1.5rem", // Slightly larger size for better visibility
            transition: "color 0.2s ease, transform 0.2s ease", // Smooth color and scaling animations
          }}
          onClick={() => handleCertifcateDelete(index)} // Pass index to handleDelete
          onMouseEnter={(e) => {
            e.target.style.color = "#e74c3c"; // Red color on hover
            e.target.style.transform = "scale(1.2)"; // Scale up on hover
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "#dc3545"; // Reset to original red color
            e.target.style.transform = "scale(1)"; // Reset scale
          }}
        />
      </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)}
{certificateDetails.length > 0 && (
  <div className="row justify-content-center mt-4">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="submit" className="btn btn-primary" >
    {Vendor_Id ? 'Update' : 'Submit'}
    </button>
  </div>
</div>
)}
{isModal && (
          <>
            <Designation
              open={isModal}
              onClose={closed}
              Modaldata={Modaldata}
            />
          </>
        )
        }


        </form>
      </div>
    </div>
    {loader}
  </div>
    </div>

  );
};

export default AddVendor;
