import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';



const UploadCertificate = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [custDocumentName, setCustDocument] = useState([]);
    const [checkedStatus, setCheckedStatus] = useState({});




    const [customer, setCustomer] = useState({
        Documents: [],
        Customer_Id: updateData.Customer_Id,
        Doc_Type: updateData.Certificate_Name
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    const handleCheckboxChange = (e, index) => {
        const newCustDocumentName = [...custDocumentName];
        const documentToUpdate = newCustDocumentName[index];
    
        // Update the document status
        documentToUpdate.Status = e.target.checked ? "Approved" : "Reject";
    
        // // Update additional fields with data from updateData
        // documentToUpdate.Mobile = updateData.Mobile;
        // documentToUpdate.Certificate_Name = updateData.Certificate_Name;
        // documentToUpdate.Customer_Name = `${updateData.First_Name} ${updateData.Last_Name}`;
    
        // Set the updated array and checked status
        setCustDocument(newCustDocumentName);
        setCheckedStatus({ ...checkedStatus, [documentToUpdate.Sr_No]: e.target.checked });
    };
    

    useEffect(() => {
        (async () => {
            showLoader();
            try {
                setCustDocument(prevState =>
                    prevState.map(item => ({
                      ...item,
                      Status: "Reject",
                      Mobile: updateData.Mobile,
                      Certificate_Name: updateData.Certificate_Name,
                      Customer_Name: `${updateData.First_Name} ${updateData.Last_Name}`
                    }))
                  );

                if (updateData?.Enquiry_Sr_No) {
                    const response = await axios.get(`/getCustomerDocuments/${updateData?.Enquiry_Sr_No}`);

                    setCustomer((customer) => ({
                        ...customer,
                        Documents: response.data,
                    }));
                    const reload = await axios.get(`/getDocuments/${updateData?.Enquiry_Sr_No}`);

                    setCustDocument(reload.data);
                    setCustDocument(prevState =>
                        prevState.map(item => ({
                          ...item,
                          Mobile: updateData.Mobile,
                          Certificate_Name: updateData.Certificate_Name,
                          Customer_Name:`${updateData.First_Name} ${updateData.Last_Name}`
                        }))
                      );
                }
                if (updateData?.Certificate_Id) {
                    const result = await axios.get(`/getCertificateDocByCerIdForCustomer?CerId=${updateData.Certificate_Id}`);
                    setDocument(result.data);
                }
                if (updateData?.Sr_No) {
                    const result = await axios.get(`/getCustomerById/${updateData.Sr_No}`);
                    SetData(result.data);
                }
            } catch (error) {
                console.error("Error fetching:", error);
            }

            hideLoader();
        })();
    }, [userData, updateData, updateData.Certificate_Id, updateData?.Enquiry_Sr_No]);


    const UploadCertificateHandle = async (event) => {
        event.preventDefault();

        showLoader();

        // Send PUT request
        axios
            .put(`/updateDocumentStatus/${updateData?.Enquiry_Sr_No}`, custDocumentName)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Documents Verified Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Verify. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };

    const handleDownloadAllDocuments = async () => {
        if (!customer.Documents || customer.Documents.length === 0) {
            alert('No Documents Found!!');
            return;
        }

        const zip = new JSZip();
        const promises = [];

        customer.Documents.forEach(document => {
            const fileName = document.Document_Name;
            const fileUrl = `/downloadDoc/${fileName}`;

            // Push each axios.get request promise to an array
            promises.push(
                axios.get(fileUrl, { responseType: 'arraybuffer' })
                    .then(response => {
                        zip.file(fileName, response.data, { binary: true });
                    })
                    .catch(error => {
                        console.error('Error fetching document:', error);
                    })
            );
        });

        // Wait for all requests to complete
        Promise.all(promises)
            .then(() => {
                // Generate the zip file after all requests are done
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        FileSaver.saveAs(blob, `${data.First_Name} ${data.Last_Name} - ${updateData.Certificate_Name}.zip`);
                    })
                    .catch(error => {
                        console.error('Error generating zip file:', error);
                    });
            });
    };


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Verify Document - {`(${updateData?.First_Name} ${updateData?.Last_Name} for ${updateData?.Certificate_Name})`}</h5>
                    <div className="col-md-6 text-end">
                        <div className="mb-3">
                            <span className="text-dark">Download All Documents Zip : </span>
                            {customer.Documents.length > 0 ? (
                                <>
                                    <a style={{ color: "blue", cursor: 'pointer' }} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                                        <i className="ti ti-download"></i>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <i className="ti ti-download-off"></i>
                                </>
                            )}
                        </div>
                    </div>
                </div>


                <hr className="p-0 mb-1" />
                <form onSubmit={UploadCertificateHandle} >
                    <div className="row justify-content-center mt-4">

                        <table
                            className="table table-bordered table-hover table-responsive"
                            cellSpacing="0"
                            cellPadding="4"
                            align="Center"
                            rules="cols"
                            style={{
                                color: "Black",
                                backgroundColor: "White",
                                borderColor: "#DEDFDE",
                                borderWidth: "1px",
                                borderStyle: "None",
                                borderCollapse: "collapse",
                            }}
                        >
                            <thead>
                                <tr align="center">
                                    <th scope="col">Approve/Reject</th>
                                    <th scope="col">Document Type</th>
                                    <th scope="col">View/Download</th>
                                </tr>
                                </thead>
                                <tbody>
    {custDocumentName.length === 0 ? (
        <tr>
            <td colSpan="3" className="text-center">
                Document not found!
            </td>
        </tr>
    ) : (
        custDocumentName.map((item, index) => (
            <tr key={index}>
                <td className="text-center">
                    <input
                        type="checkbox"
                        checked={checkedStatus[item.Sr_No]}
                        onChange={(e) => handleCheckboxChange(e, index)}
                    />
                </td>
                <td className="text-center">
                    {item.Doc_Type}
                </td>
                <td className="text-center">
                    {item.Document_Name ? (
                        <>
                            <a
                                style={{ color: "blue", cursor: "pointer" }}
                                href={`${apiUrl}/viewDoc/` + item.Document_Name}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer"
                            >
                                <i className="ti ti-eye"></i>
                            </a>
                            /
                            <a
                                style={{ color: "blue", cursor: "pointer" }}
                                href={`${apiUrl}/downloadDoc/` + item.Document_Name}
                                download={true}
                                target="_blank"
                                className="cursor-pointer"
                            >
                                <i className="ti ti-download"></i>
                            </a>
                        </>
                    ) : (
                        <>
                            <i className="ti ti-eye-off"></i> / <i className="ti ti-download-off"></i>
                        </>
                    )}
                </td>
            </tr>
        ))
    )}
</tbody>

                        </table>
                    </div>

                    <div className="col-md-12 text-center mt-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                            Verify
                            </button>
                    </div>
                </form>

                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default UploadCertificate;
