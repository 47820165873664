import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select from "react-select";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDate } from "../../components/dateFormate";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";






const ViewVendors = () => {
  const { userData } = useUser();
  
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
const [filter, setFilter] = useState({
  Customer_Id: "",
  Company_Name: "",
  Certificate: "",
});
useEffect(() => {
  if (userData && userData.Org_Id) {
    loadVendorData();
  }
}, [userData]);


  useEffect(() => {
    if (userData && userData.Org_Id) {
        loadVendorData();
    }
  }, [userData]);

  const loadVendorData = async () => {
    showLoader();  // Show the loading spinner or animation
    try {
      const result = await axios.get('/getAllVendorCertificate');  // Fetch vendor data from the API
  
      // Ensure the result contains the expected data
      if (result.data && Array.isArray(result.data.vendors)) {
        // Destructure the filter values (from your filter state)
        const { Company_Name, Certificate } = filter;
  
        // Filter the data based on the selected filter conditions
        const filteredData = result.data.vendors.filter((row) => {
          const isCompanyMatch = !Company_Name || row.Company_Name.toLowerCase().includes(Company_Name.toLowerCase());
          const isCertificateMatch =
            !Certificate ||
            row.certificates.some((cert) => cert.Certificate_Name.toLowerCase().includes(Certificate.toLowerCase()));
  
          return isCompanyMatch && isCertificateMatch;
        });
  
        // Update the state with the filtered data
        setStaffData(filteredData);
        setStaffDataa(filteredData);  // If needed, set the same filtered data for another state
  
      } else {
        console.warn("Unexpected data format:", result.data);
        setStaffData([]);  // Set staff data to an empty array if the format is unexpected
        setStaffDataa([]); // Clear the second state as well
      }
  
    } catch (error) {
      console.error("Error fetching data: ", error);  // Log any errors that occur
    } finally {
      hideLoader();  // Hide the loading spinner or animation
    }
  };
  
  

  const uniqueCompanyNames = [
    { label: "All", value: "" },
    ...[...new Set(dataa.map(row => row.Company_Name))].map(name => ({
      label: name,
      value: name,
    })),
  ];

  const uniqueCertificateNames = [
    { label: "All", value: "" }, // Default option to show all certificates
    ...[...new Set(dataa.flatMap((vendor) => vendor.certificates.map((cert) => cert.Certificate_Name)))]
      .map((name) => ({
        label: name,
        value: name,
      })),
  ];
  
  

  const handleFilterChange = (selectedOption) => {
    setFilter((prev) => ({
      ...prev,
      Company_Name: selectedOption ? selectedOption.value : "",

    }));
  };

  const handleCertificateChange = (selectedOption) => {
    setFilter((prev) => ({
      ...prev,
      Certificate: selectedOption ? selectedOption.value : "", // Update filter with the selected certificate
    }));
  };
  

  
  const applyFilter = () => {
    showLoader();
  
    // Destructure the filter values
    const { Company_Name, Certificate } = filter;
  
    // Filter the data based on the selected filter conditions
    const filteredData = dataa.filter((row) => {
      const isCompanyMatch = !Company_Name || row.Company_Name.toLowerCase().includes(Company_Name.toLowerCase());
      const isCertificateMatch =
        !Certificate ||
        row.certificates.some((cert) => cert.Certificate_Name.toLowerCase().includes(Certificate.toLowerCase()));
      
      return isCompanyMatch && isCertificateMatch;
    });
  
    // Update the state with the filtered data
    setStaffData(filteredData);
  
    hideLoader();
  };

  const filterData = () => {
  loadVendorData();
  };
  
  



  const handleDelete = async (Vendor_Id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will permanently delete the vendor and its associated certificates.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
  
    // If the user confirms the deletion
    if (result.isConfirmed) {
      try {
        // Show loader or any loading indication
        showLoader();  // You can replace this with your actual loader implementation
  
        // Call the delete vendor API
        const response = await axios.delete(`/deleteVendor/${Vendor_Id}`);
  
        // Check if the response was successful
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Vendor Deleted',
            text: 'The vendor has been deleted successfully.',
          });
  
          loadVendorData();
  
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Deletion Failed',
            text: 'Failed to delete the vendor. Please try again later.',
          });
        }
      } catch (error) {
        console.error('Error deleting vendor:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
        });
      } finally {
        // Hide loader after the operation is complete
        hideLoader();  // You can replace this with your actual loader hiding logic
      }
    }
  };

  
  
  

  const columns = [
    
    {
      name: "Vendor ID",
      selector: (row) => row.Vendor_Id,
      width: "80px",
    },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "290px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name}

          </span>
        </LightTooltip>
      ),
      width: "220px",
    },
      {
        name: "Vendor Name",
        selector: (row) => row.Name,
        width: "170px",
      },
   
    {
      name: "Mobile No. ",
      selector: (row) => row.Mobile,
      width: "110px",
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      width: "190px",
    },    {
      name: "Password",
      selector: (row) => row.Password,
      width: "110px",
    },
    {
      name: "GST No.",
      selector: (row) => row.GST_No, // assuming you have a Due_Date field
      width: "135px",
    },
    {
        name: "",
        cell: (row) => (
          <div className="dropdown">
            <a
              className="p-0"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i>
                <MoreVertIcon
                  style={{
                    color: "grey",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
              </i>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {/* Edit Link with Vendor_Id */}
              <Link  className="dropdown-item" to={`/viewEnquiry/${row.Vendor_Id}/${row.Company_Name}/Vendor`}><ViewTimelineIcon
                className="text-primary"
                style={{ fontSize: "20px", marginRight: '5px' }}
              />View Enquiry</Link>
              <Link 
                className="dropdown-item" 
                to={`/editVendor/${row.Vendor_Id}`} // Passing Vendor_Id as URL param
              >
                <EditIcon
                  className="text-info"
                  style={{ fontSize: "18px" }}
                />
                Edit
              </Link>
      
              {/* Delete Link */}
              <Link
                className="dropdown-item"
                onClick={() => handleDelete(row.Vendor_Id)}  // Wrap the function call in an anonymous function
              >
                <DeleteIcon
                  className="text-danger"
                  style={{ fontSize: "18px" }}
                />
                Delete
              </Link>
            </div>
          </div>
        ),
        width: "45px",
      }
      
    
  ];
  
  

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));



  const DownloadPDF = () => {
    const doc = new jsPDF({
        orientation: 'landscape'
    });
    doc.text("Vendor Details", 20, 10);

    // Updated columns to include new fields
    const columns = [
        "Vendor ID",
        "Company Name",
        "Vendor Name",
        "Mobile No.",
        "Telephone",
        "Email",
        "GST No.",
        "Address",
        "Staff 1 Name",
        "Staff 1 Mobile",
        "Staff 1 Email",
        "Staff 2 Name",
        "Staff 2 Mobile",
        "Staff 2 Email",
        "EPR Staff Name",
        "Added On"

    ];

    // Update rows to include new fields from data
    const rows = data.map((row) => [
        row.Vendor_Id,
        row.Company_Name,
        row.Name,
        row.Mobile,
        row.Telephone,
        row.Email,
        row.GST_No,
        row.Address,
        row.Staff_1_Name,
        row.Staff_1_Mobile,
        row.Staff_1_Email,
        row.Staff_2_Name,
        row.Staff_2_Mobile,
        row.Staff_2_Email,
        row.EPR_Staff_Name,
        formatDate(row.Added_On),

    ]);

    doc.autoTable({
        head: [columns],
        body: rows,
    });

    doc.save("VendorDetails.pdf");
};



const DownloadExcel = () => {
  const columns = [
    "Vendor ID",
    "Company Name",
    "Vendor Name",
    "Mobile No.",
    "Telephone",
    "Email",
    "GST No.",
    "Address",
    "Staff 1 Name",
    "Staff 1 Mobile",
    "Staff 1 Email",
    "Staff 2 Name",
    "Staff 2 Mobile",
    "Staff 2 Email",
    "EPR Staff Name",
    "Added On"

];
  
  // Initialize rows with columns
  const rows = [columns];
  
  data.forEach((row) => {
      rows.push([
        row.Vendor_Id,
        row.Company_Name,
        row.Name,
        row.Mobile,
        row.Telephone,
        row.Email,
        row.GST_No,
        row.Address,
        row.Staff_1_Name,
        row.Staff_1_Mobile,
        row.Staff_1_Email,
        row.Staff_2_Name,
        row.Staff_2_Mobile,
        row.Staff_2_Email,
        row.EPR_Staff_Name,
        formatDate(row.Added_On),
      ]);
  });


  const workSheet = XLSX.utils.aoa_to_sheet(rows);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "Vendors Details");

  // Download the Excel file
  XLSX.writeFile(workBook, "VendorDetails.xlsx");
};





function handleFilter(event) {
  const filterValue = event.target.value.toLowerCase();
  const filteredData = dataa.filter((row) => {
      return (
          (row.Vendor_Id && row.Vendor_Id.toString().includes(filterValue)) ||
          (row.CustomerId && row.CustomerId.toString().includes(filterValue)) ||
          (row.Company_Name && row.Company_Name.toLowerCase().includes(filterValue)) ||
          (row.Name && row.Name.toLowerCase().includes(filterValue))
      );
  });
  setStaffData(filteredData);
}


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">View Vendors</h4>
          </div>
          <div className="card">
            
          <div className="card-body" style={{paddingTop:"15px"}}>
              <div className="row justify-content-center mt-2">
              <div className="col-4">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <Select
                    id="companyName"
                    options={uniqueCompanyNames}
                    value={
                      filter.Company_Name
                        ? { label: filter.Company_Name, value: filter.Company_Name }
                        : null
                    }
                    onChange={handleFilterChange}
                    placeholder="Select Company"
                  />
                </div>

                <div className="col-4">
  <label htmlFor="certificateName" className="form-label">
    Certificate Name
  </label>
  <Select
    id="certificateName"
    options={uniqueCertificateNames}
    value={
      filter.Certificate
        ? { label: filter.Certificate, value: filter.Certificate }
        : null
    }
    onChange={handleCertificateChange}
    placeholder="Select Certificate..."
  />
</div>


                
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-2">
                <button
                  className="btn btn-sm btn-primary"
                  style={{ width: "100%" }}
                  onClick={filterData}
                >
                  Filter
                </button>
              </div>
            </div>
            <div className="card-body">
          
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default ViewVendors;
