import React from "react";
import { Modal } from "react-bootstrap";
import { useUser } from "../../components/UserContext";
import { apiUrl } from "../../components/axios";
import { formatDate } from "../../components/dateFormate";

const CustomerAcknowledgement = ({ show, onClose, updateData }) => {
  const { userData } = useUser();

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="xl"
      centered
      style={{ boxShadow: "none !important" }}
    >
      <Modal.Body>
        <div className="row align-items-center">
          <h5 className="col-md-6">
            Enquiry Summary - ({updateData?.Certificate_Name || ""})
          </h5>
        </div>

        <div className="row" style={{ justifyContent: "center", borderRadius: "8px" }}>
          {/* First row with 3 columns */}
          <div className="row" style={{ marginBottom: "8px" }}>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Customer Id:</strong> {updateData?.Customer_Id}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Enquiry Id:</strong> {updateData?.Enquiry_Id}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Certificate:</strong> {updateData?.Certificate_Name}
              </div>
            </div>
          </div>


          {/* Fourth row with financial details */}
          <div className="row" style={{ marginBottom: "10px" }}>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Gvt Amount:</strong> {updateData?.Government}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Consultation:</strong> {updateData?.Consultation}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Remarks:</strong> {updateData?.Remarks}
              </div>
            </div>
          </div>

          {/* Fifth row with application details */}
          <div className="row" style={{ marginBottom: "10px" }}>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Application No:</strong> {updateData?.Application_No}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Application Date:</strong> {updateData?.Application_Date ? formatDate(updateData.Application_Date) : "Not Uploaded Yet"}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Link:</strong> {updateData?.Link}
              </div>
            </div>
          </div>

          {/* Attachments */}
          <div className="row" style={{ marginBottom: "10px" }}>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Attachment 1:</strong>{" "}
                {updateData?.Attachment1 ? (
                  <>
                    <a
                      style={{ color: "blue", cursor: "pointer", marginRight: "8px" }}
                      href={`${apiUrl}/viewAcknowledgement/${updateData.Attachment1}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti ti-eye" style={{ marginRight: "5px" }}></i>
                    </a>
                    <a
                      style={{ color: "blue", cursor: "pointer" }}
                      href={`${apiUrl}/downloadAcknowledgement/${updateData.Attachment1}`}
                      download
                    >
                      <i className="ti ti-download" style={{ marginRight: "5px" }}></i>
                    </a>
                  </>
                ) : (
                  <i className="ti ti-eye-off" style={{ marginRight: "5px" }}></i>
                )}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Attachment 2:</strong>{" "}
                {updateData?.Attachment2 ? (
                  <>
                    <a
                      style={{ color: "blue", cursor: "pointer", marginRight: "8px" }}
                      href={`${apiUrl}/viewAcknowledgement/${updateData.Attachment2}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti ti-eye" style={{ marginRight: "5px" }}></i>
                    </a>
                    <a
                      style={{ color: "blue", cursor: "pointer" }}
                      href={`${apiUrl}/downloadAcknowledgement/${updateData.Attachment2}`}
                      download
                    >
                      <i className="ti ti-download" style={{ marginRight: "5px" }}></i>
                    </a>
                  </>
                ) : (
                  <i className="ti ti-eye-off" style={{ marginRight: "5px" }}></i>
                )}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className="text-dark">
                <strong>Attachment 3:</strong>{" "}
                {updateData?.Attachment3 ? (
                  <>
                    <a
                      style={{ color: "blue", cursor: "pointer", marginRight: "8px" }}
                      href={`${apiUrl}/viewAcknowledgement/${updateData.Attachment3}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti ti-eye" style={{ marginRight: "5px" }}></i>
                    </a>
                    <a
                      style={{ color: "blue", cursor: "pointer" }}
                      href={`${apiUrl}/downloadAcknowledgement/${updateData.Attachment3}`}
                      download
                    >
                      <i className="ti ti-download" style={{ marginRight: "5px" }}></i>
                    </a>
                  </>
                ) : (
                  <i className="ti ti-eye-off" style={{ marginRight: "5px" }}></i>
                )}
              </div>
            </div>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerAcknowledgement;
