import React, { useState } from "react";
import useFullPageLoader from "../../components/useFullPageLoader";
import { useUser } from "../../components/UserContext";
import axios from "../../components/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const AddUser = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [userDetail, setuUerDetail] = useState({
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Gender: "Male",
    DOB: "",
    Email: "",
    Telephone: "",
    Mobile: "",
    Address: "",
    Status: "",
    Role: "Staff",
    Department: "",
    Username: "",
    Password: "",
    OTP: "",
    GCM: "",
    App_Status: "",
    Date_Added: "",
    Added_By_Id: "",
    Added_By: "",
  });
  
  const handleSubmit = (event) => {
    event.preventDefault();

    let userPassword = "";
    if (userDetail.Role === "Admin") {
      userPassword = "admin@123";
    }else if (userDetail.Role === "Staff") {
      userPassword = "staff@123";
    }

    const UpdatedUserDetail = {
      ...userDetail,
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Username: userDetail.Mobile,
      Password: userPassword
    };

    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(userDetail.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(userDetail.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }
    if (!/^([6-9][0-9]{9,11})$/.test(userDetail.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(userDetail.Email) &&
      userDetail.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }

    if(!userDetail.Department){
      validationErrors.Department = "Department is required.";
    }

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .post(`/addUsers`, UpdatedUserDetail)
        .then((res) => {
          Swal.fire("Success!", "User Added Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                // Redirect using React Router
                navigate("/viewUser");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
         // error("Server is Busy!!");
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Add User</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="txtFname" className="form-label">
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="txtFname"
                      className="form-control"
                      placeholder="Enter First Name"
                      required
                      onChange={(e) =>
                        setuUerDetail({
                          ...userDetail,
                          First_Name: e.target.value,
                        })
                      }
                    />
                    <p className="text-danger">{errors.First_Name}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="txtLname" className="form-label">
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="txtLname"
                      className="form-control"
                      placeholder="Enter Last Name"
                      required
                      onChange={(e) =>
                        setuUerDetail({
                          ...userDetail,
                          Last_Name: e.target.value,
                        })
                      }
                    />
                    <p className="text-danger">{errors.Last_Name}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="txtMobile" className="form-label">
                      Mobile<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      value={userDetail.Mobile}
                      id="txtMobile"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      required
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/[^0-9]/g, "");
                        setuUerDetail({ ...userDetail, Mobile: numericInput });
                      }}
                    />
                    <p className="text-danger">{errors.Mobile}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="txtEmail" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      id="txtEmail"
                      className="form-control"
                      placeholder="Enter Email Id"
                      onChange={(e) =>
                        setuUerDetail({ ...userDetail, Email: e.target.value })
                      }
                    />
                    <p className="text-danger">{errors.Email}</p>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Department" className="form-label">
                      Department<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="Department"
                      value={userDetail.Department}
                      onChange={(e) =>
                        setuUerDetail({ ...userDetail, Department: e.target.value })
                      }
                    >
                      <option value="" >Select Department</option>
                      <option value="Administration Management">Administration Management</option>
                      <option value="Quotation Management">Quotation Management</option>
                      <option value="Document Management">Document Management</option>
                      <option value="Accounts Management">Accounts Management</option>
                      <option value="Acknowledgment Management">Acknowledgment Management</option>
                      <option value="Certificate Management">Certificate Management</option>
                    </select>
                    <p className="text-danger">{errors.Department}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="dropRole" className="form-label">
                      Role
                    </label>
                    <select
                      className="form-select"
                      id="dropRole"
                      value={userDetail.Role}
                      onChange={(e) =>
                        setuUerDetail({ ...userDetail, Role: e.target.value })
                      }
                    >
                      <option value="Admin">Admin</option>
                      <option value="Staff">Staff</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="dropGender" className="form-label">
                      Gender
                    </label>
                    <select
                      className="form-select"
                      id="dropGender"
                      onChange={(e) =>
                        setuUerDetail({ ...userDetail, Gender: e.target.value })
                      }
                    >
                      <option label="Male" value="Male"></option>
                      <option label="Female" value="Female"></option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="txtDOB" className="form-label">
                      DOB
                    </label>
                    <input
                      type="Date"
                      id="txtDOB"
                      className="form-control"
                      placeholder="Enter DOB"
                      onChange={(e) =>{
                        setuUerDetail({ ...userDetail, DOB: e.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="txtAddress" className="form-label">
                      Address
                    </label>
                    <textarea
                      type="Date"
                      id="txtAddress"
                      className="form-control"
                      placeholder="Enter Address"
                      onChange={(e) =>
                        setuUerDetail({
                          ...userDetail,
                          Address: e.target.value,
                        })
                      }
                    />
                  </div>
                  <p className="text-danger">{errors.Address}</p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                  <button type="submit" className="btn btn-primary ">
                    Add User
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default AddUser;
