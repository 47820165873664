import React, { useState, useEffect } from "react";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useUser } from "../../components/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { formatInputDate } from "../../components/dateFormate";

const SetInstallment = () => {
  const { userData } = useUser();
  const { Enq_Id } = useParams();
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [isInstallments, setIsInstallments] = useState(false);
  const [errors, setErrors] = useState({});
  const [installment, setInstallment] = useState([]);
  const [Amount, setAmount] = useState({
    Installment: [],
    Enq_Id: "",
    Enquiry_Id:"",
    Certificate_Name: "",
    Customer_Id: "",
    Customer_Name: "",
    Gvt_Amount: 0,
    Consultation_Charges: 0,
    Gst_Percent: 18,
    Gst_Amount: 0,
    Total_Consultation: 0,
    Net_Amount: 0,
    Amount_Paid: 0,
    Amount_Balance: 0,
    InstallmentType: "Full Payment",
    Description: "",
    Added_By_Id: "",
    Added_By: "",
    Added_on: "",
    Org_Id: "",
  });

  const loadEnquiry = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getEnquiryDetail/${Enq_Id}`);
      let data = result.data;
      const cal = calculation(
        data[0].Government,
        data[0].Consultation,
        Amount.Gst_Percent
      );
      if (data.length > 0) {
        setAmount({
          ...Amount,
          ...cal,
          Customer_Id: data[0].Customer_Id,
          Customer_Name: data[0].Customer_Name,
          Certificate_Id: data[0].Certificate_Id,
          Certificate_Name: data[0].Certificate_Name,
          Gvt_Amount: data[0].Government,
          Consultation_Charges: data[0].Consultation,
          Enquiry_Id: data[0].Enquiry_Id,

        });
      }
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    if (Enq_Id) {
      loadEnquiry();
    }
  }, [Enq_Id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const UpdatedUserDetail = {
      ...Amount,
      Installment: installment,
      Enq_Id: Enq_Id,
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      Enquiry_Id: Amount.Enquiry_Id,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Updated_By_Id: userData.UserId,
      Updated_By: `${userData.First_Name} ${userData.Last_Name}`,
    };

    const validationErrors = {};
    showLoader();
    if (Object.keys(validationErrors).length === 0) {
      await axios
        .post(`/setInstallment`, UpdatedUserDetail)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Installment Set Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              navigate(`/invoice/${Enq_Id}/Perfoma`);
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to set Installment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };
  function calculation(gvtAmount, ConsultationCharges, gstPercent) {
    let Gst_Amount =
      (parseFloat(ConsultationCharges) * parseFloat(gstPercent)) / 100;
    return {
      Total_Consultation: parseFloat(ConsultationCharges) + Gst_Amount,
      Gst_Amount: Gst_Amount,
      Net_Amount:
        parseFloat(gvtAmount) + parseFloat(ConsultationCharges) + Gst_Amount,
      Amount_Balance:
        parseFloat(gvtAmount) + parseFloat(ConsultationCharges) + Gst_Amount,
    };
  }
  function installmentArray() {
    const totalInstallment = Amount.InstallmentType === "Full Payment" ? 1 : 2;
    const newInstallments = [];
    const halfConsultationCharges = parseFloat(Amount.Total_Consultation) / 2;
    const consultationCharges1 = halfConsultationCharges;
    const consultationCharges2 = halfConsultationCharges;

    for (let i = 0; i < totalInstallment; i++) {
      const installmentAmount =
        Amount.InstallmentType === "Full Payment"
          ? parseFloat(Amount.Net_Amount)
          : i === 0
            ? parseFloat(Amount.Gvt_Amount) + consultationCharges1
            : consultationCharges2;
      newInstallments.push({
        Enq_Id: Enq_Id,
        Certificate_Id: Amount.Certificate_Id,
        Certificate: Amount.Certificate_Name,
        Customer_Id: Amount.Customer_Id,
        Customer_Name: Amount.Customer_Name,
        Amount: installmentAmount,
        Late_Charges: "0",
        Discount: "0",
        Amount_Received: "0",
        Due_Date: dayjs().add(i, "month").format("YYYY-MM-DD"),
        Payment_Status: "Unpaid",
        Payment_Mode: "",
        Txn_Id: "",
        Payment_Id: "",
        Receipt_Number: "",
        Remarks: "",
        UserName: userData.Username,
        Added_By_Id: userData?.UserId,
        Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
        Added_On: dayjs().format("YYYY-MM-DD"),
        Org_Id: userData.Org_Id,
      });
    }
    // console.log(newInstallments);
    setInstallment(newInstallments);
    setIsInstallments(true);
  }
  //   function installmentArray() {
  //     const totalInstallment = Amount.InstallmentType === "Full Payment" ? 1 : 2;
  //     const newInstallments = [];
  //     for (let i = 0; i < totalInstallment; i++) {
  //       debugger;
  //       let a = Amount.Net_Amount;
  //       let installmentAmount = parseFloat(Amount.Net_Amount) / totalInstallment;
  //       newInstallments.push({
  //         Enq_Id: Enq_Id,
  //         Certificate_Id: Amount.Certificate_Id,
  //         Certificate: Amount.Certificate_Name,
  //         Customer_Id: Amount.Customer_Id,
  //         Customer_Name: Amount.Customer_Name,
  //         Amount: installmentAmount,
  //         Late_Charges: "0",
  //         Discount: "0",
  //         Amount_Received: "0",
  //         Due_Date: dayjs().add(i, "month").format("YYYY-MM-DD"),
  //         Payment_Status: "Unpaid",
  //         Payment_Mode: "",
  //         Txn_Id: "",
  //         Payment_Id: "",
  //         Receipt_Number: "",
  //         Remarks: "",
  //         UserName: userData.Username,
  //         Added_By_Id: userData?.UserId,
  //         Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
  //         Added_On: dayjs().format("YYYY-MM-DD"),
  //         Org_Id: userData.Org_Id,
  //       });
  //     }
  //     console.log(newInstallments);
  //     setInstallment(newInstallments);
  //     setIsInstallments(true);
  //   }
  // console.log(installment);

  const handleDateChange = (e, index) => {
    const { value } = e.target;
    const updatedInstallments = [...installment];
    updatedInstallments[index].Due_Date = value;
    setInstallment(updatedInstallments);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Set installment</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="txtCustomerName" className="form-label">
                      Customer Name
                    </label>
                    <input
                      type="text"
                      disabled
                      id="txtCustomerName"
                      className="form-control"
                      placeholder="Customer Name"
                      required
                      value={Amount.Customer_Name}
                    />
                    <p className="text-danger">{errors.Customer_Name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="txtCertificate" className="form-label">
                      Certificate
                    </label>
                    <input
                      type="text"
                      disabled
                      id="txtCertificate"
                      className="form-control"
                      placeholder="Enter Last Name"
                      required
                      value={Amount.Certificate_Name}
                    />
                    <p className="text-danger">{errors.Certificate_Name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="txtGvtAmount" className="form-label">
                      Government Charges<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      value={Amount.Gvt_Amount}
                      id="txtGvtAmount"
                      className="form-control"
                      placeholder="Enter Government Charges"
                      required

                      onChange={(e) => {
                        const input = e.target.value;
                        let numericInput = input.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                        const decimalIndex = numericInput.indexOf(".");
                        if (decimalIndex !== 0 && decimalIndex !== -1) {
                          // If decimal point is not at the 0 index and found
                          numericInput =
                            numericInput.slice(0, decimalIndex + 1) +
                            numericInput
                              .slice(decimalIndex + 1)
                              .replace(".", ""); // Remove any other decimal points
                        }
                        let cal = calculation(
                          numericInput,
                          Amount.Consultation_Charges,
                          Amount.Gst_Percent
                        );
                        setAmount({
                          ...Amount,
                          ...cal,
                          Gvt_Amount: numericInput,
                        });
                      }}
                    />
                    <p className="text-danger">{errors.Mobile}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="txtConsultationCharges"
                      className="form-label"
                    >
                      Consultation Charges
                    </label>
                    <input
                      type="text"
                      value={Amount.Consultation_Charges}
                      id="txtConsultationCharges"
                      className="form-control"
                      placeholder="Enter Consultation Charges"
                      onChange={(e) => {
                        const input = e.target.value;
                        let numericInput = input.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                        const decimalIndex = numericInput.indexOf(".");
                        if (decimalIndex !== 0 && decimalIndex !== -1) {
                          // If decimal point is not at the 0 index and found
                          numericInput =
                            numericInput.slice(0, decimalIndex + 1) +
                            numericInput
                              .slice(decimalIndex + 1)
                              .replace(".", ""); // Remove any other decimal points
                        }
                        let cal = calculation(
                          Amount.Gvt_Amount,
                          numericInput,
                          Amount.Gst_Percent
                        );
                        setAmount({
                          ...Amount,
                          ...cal,
                          Consultation_Charges: numericInput,
                        });
                      }}
                    />
                    <p className="text-danger">{errors.Email}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="ddlGST" className="form-label">
                      GST%
                    </label>
                    <select
                      className="form-select"
                      id="ddlGST"
                      value={Amount.Gst_Percent}
                      onChange={(e) => {
                        let cal = calculation(
                          Amount.Gvt_Amount,
                          Amount.Consultation_Charges,
                          e.target.value
                        );
                        setAmount({ ...Amount, ...cal, Gst_Percent: e.target.value });
                      }}
                    >
                      <option value="0">0%</option>
                      <option value="5">5%</option>
                      <option value="12">12%</option>
                      <option value="18">18%</option>
                      <option value="28">28%</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="txtGstAmount" className="form-label">
                      GST Amount
                    </label>
                    <input
                      type="text"
                      disabled
                      id="txtGStAmount"
                      className="form-control"
                      placeholder="Enter Consultation Charges"
                      value={Amount.Gst_Amount}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="txtTotalConsultation"
                      className="form-label"
                    >
                      Total Consultation
                    </label>
                    <input type="text" value={Amount.Total_Consultation}
                      id="txtTotalConsultation" disabled className="form-control"
                      placeholder="Total Consultation"  onChange={(e) => {
                        setAmount({
                          ...Amount,
                          Total_Consultation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="txtNetAmount" className="form-label">
                      Net Amount
                    </label>
                    <input type="text" value={Amount.Net_Amount} id="txtNetAmount" disabled
                      className="form-control" placeholder="Net Amount" onChange={(e) => {
                        setAmount({ ...Amount, Net_Amount: e.target.value });
                      }} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="ddlInstallment" className="form-label">
                      Installment
                    </label>
                    <select
                      className="form-select"
                      id="ddlInstallment"
                      value={Amount.InstallmentType}
                      onChange={(e) => {
                        setAmount({
                          ...Amount,
                          InstallmentType: e.target.value,
                        });
                      }}
                    >
                      <option value="Full Payment">Full Payment</option>
                      <option value="Installment">Installment</option>
                    </select>
                  </div>
                </div>                
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="txtNetAmount" className="form-label">
                      Description
                    </label>
                    <input type="text" value={Amount.Description} id="txtDescription"
                      className="form-control" placeholder="Description" onChange={(e) => {
                        setAmount({ ...Amount, Description: e.target.value });
                      }} />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => {
                      installmentArray();
                      // console.log(installment);
                    }}
                  >
                    Set Installment
                  </button>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                {isInstallments && (
                  <>
                    <table
                      className="table table-bordered table-hover table-responsive"
                      cellSpacing="0"
                      cellPadding="4"
                      align="Center"
                      rules="cols"
                      style={{
                        color: "Black",
                        backgroundColor: "White",
                        borderColor: "#DEDFDE",
                        borderWidth: "1px",
                        borderStyle: "None",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr align="center">
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                        </tr>
                        {installment.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <input
                                type="date"
                                className="form-control"
                                value={formatInputDate(item.Due_Date)}
                                onChange={(e) => handleDateChange(e, index)}
                              />
                            </td>
                            <td className="text-center">{item.Amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="row justify-content-center mt-4">
                      <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                        <button type="submit" className="btn btn-primary ">
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default SetInstallment;
