import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../components/useFullPageLoader";
import axios from "../../components/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Select from 'react-select';
// const indianStates = [
//   { value: 'andhra-pradesh', label: 'Andhra Pradesh' },
//   { value: 'arunachal-pradesh', label: 'Arunachal Pradesh' },
//   { value: 'assam', label: 'Assam' },
//   { value: 'bihar', label: 'Bihar' },
//   { value: 'chhattisgarh', label: 'Chhattisgarh' },
//   { value: 'goa', label: 'Goa' },
//   { value: 'gujarat', label: 'Gujarat' },
//   { value: 'haryana', label: 'Haryana' },
//   { value: 'himachal-pradesh', label: 'Himachal Pradesh' },
//   { value: 'jharkhand', label: 'Jharkhand' },
//   { value: 'karnataka', label: 'Karnataka' },
//   { value: 'kerala', label: 'Kerala' },
//   { value: 'madhya-pradesh', label: 'Madhya Pradesh' },
//   { value: 'maharashtra', label: 'Maharashtra' },
//   { value: 'manipur', label: 'Manipur' },
//   { value: 'meghalaya', label: 'Meghalaya' },
//   { value: 'mizoram', label: 'Mizoram' },
//   { value: 'nagaland', label: 'Nagaland' },
//   { value: 'odisha', label: 'Odisha' },
//   { value: 'punjab', label: 'Punjab' },
//   { value: 'rajasthan', label: 'Rajasthan' },
//   { value: 'sikkim', label: 'Sikkim' },
//   { value: 'tamil-nadu', label: 'Tamil Nadu' },
//   { value: 'telangana', label: 'Telangana' },
//   { value: 'tripura', label: 'Tripura' },
//   { value: 'uttar-pradesh', label: 'Uttar Pradesh' },
//   { value: 'uttarakhand', label: 'Uttarakhand' },
//   { value: 'west-bengal', label: 'West Bengal' },
//   { value: 'andaman-nicobar', label: 'Andaman and Nicobar Islands' },
//   { value: 'chandigarh', label: 'Chandigarh' },
//   { value: 'dadra-nagar-haveli', label: 'Dadra and Nagar Haveli' },
//   { value: 'daman-diu', label: 'Daman and Diu' },
//   { value: 'lakshadweep', label: 'Lakshadweep' },
//   { value: 'delhi', label: 'Delhi' },
//   { value: 'puducherry', label: 'Puducherry' },
//   { value: 'ladakh', label: 'Ladakh' },
//   { value: 'jammu-kashmir', label: 'Jammu and Kashmir' }
// ];

const indianStates = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'West Bengal', label: 'West Bengal' },
  // Union Territories
  { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
  { value: 'Chandigarh', label: 'Chandigarh' },
  { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
  { value: 'Lakshadweep', label: 'Lakshadweep' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Puducherry', label: 'Puducherry' },
  { value: 'Ladakh', label: 'Ladakh' },
  { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
];



function randomString(length, chars) {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
}

const CustomerEnquiry = () => {
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [certificateOption, setCertificateType] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  useEffect(() => {
    (async () => {
      showLoader();
      try {
        const certificateType = await axios.get(`/getCertificateType`);
        const Option = certificateType.data.map((option) => ({
          label: option.Certificate_Name,
          value: option.Sr_No,
        }));
        setCertificateType(Option);
        const result = await axios.get(`/getCustomerId`);
        const nextInvoiceNumber = generateNextInvoiceNumber(result.data ? result.data : "");
        setInvoiceNumber(nextInvoiceNumber);
        setCustomer({
          ...customer,
          CustomerId: nextInvoiceNumber,
        })
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }

      hideLoader();
    })();
  }, []);


  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices === "") {
      return "EPR-0001";
    }
    const lastInvoiceNumber = existingInvoices;
    const lastNumber = parseInt(lastInvoiceNumber.split("-")[1]);
    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `EPR-${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }

  const [customer, setCustomer] = useState({
    CustomerId: "",
    First_Name: "",
    Last_Name: "",
    Company_Name: "",
    Reference: "",
    Other_Reference: "",
    Remarks: "",
    DOB: "",
    Certificate_Id: "",
    Certificate_Name: "",
    Gender: "",
    Mobile: "",
    Email: "",
    Address: "",
    Status: "Pending",
    State:"",
    City:"",
    District:"",
    GST_No:"",
    Alt_Email:"",
    VisitingCardFront:"",
    VisitingCardBack:""
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const Updatedcustomer = {
      ...customer,
      CustomerId: invoiceNumber,
      Username: customer?.Mobile,
      Password: randomString(4, '#')
    };

    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(customer.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(customer.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }
    if (!/^([6-9][0-9]{9,11})$/.test(customer.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(customer.Email) &&
      customer.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(customer.Email) &&
      customer.Alt_Email !== ""
    ) {
      validationErrors.Alt_Email = "Invalid Alt Email Address";
    }

    
    const formdata = new FormData();
    formdata.append("CustomerId", Updatedcustomer.CustomerId);
    formdata.append("Username", Updatedcustomer.Username);
    formdata.append("Password", Updatedcustomer.Password);
    formdata.append("First_Name", customer.First_Name);
    formdata.append("Last_Name", customer.Last_Name);
    formdata.append("Company_Name", customer.Company_Name);
    formdata.append("Reference", customer.Reference);
    formdata.append("Other_Reference", customer.Other_Reference);
    formdata.append("Remarks", customer.Remarks);
    formdata.append("DOB", customer.DOB);
    formdata.append("Certificate_Id", customer.Certificate_Id);
    formdata.append("Certificate_Name", customer.Certificate_Name);
    formdata.append("Gender", customer.Gender);
    formdata.append("Mobile", customer.Mobile);
    formdata.append("Email", customer.Email);
    formdata.append("Address", customer.Address);
    formdata.append("Status", customer.Status);
    formdata.append("State", customer.State);
    formdata.append("Alt_Email", customer.Alt_Email);
    formdata.append("GST_No", customer.GST_No);
    formdata.append("District", customer.District);
    formdata.append("City", customer.City);
    formdata.append("VisitingCardFront", customer.VisitingCardFront);
    formdata.append("VisitingCardBack", customer.VisitingCardBack);

 

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .post(`/addCustomer`, formdata)
        .then((res) => {
          Swal.fire("Success!", "Your Response Has Been Submitted Successfully,<br>Kindly Check Your Whatsapp for login credentials.", "success").then(
            (result) => {
              if (result.isConfirmed) {
                navigate("/login")
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          // error("Server is Busy!!");
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="content" style={{ padding: "3rem 0", backgroundColor: "#262477" }}>

      <div className="container">
        <div className="row">
          <div className="col-md-5 " style={{ marginRight: "auto" }}>
            <div style={{ textAlign: "center" }}>
              <img src="/assets/img/logo1.jpeg" style={{ width: "150px" }} ></img>
            </div>

            <p style={{ marginTop: "25px" }}>
              <ul>
                <li>Fill out all the fields in the form accurately.</li>
                <li>Double-check your information before submission to ensure correctness.</li>
                <li>After submitting the form, you will receive login credentials via whatsapp.</li>
                <li>Use the provided link in the whatsapp to upload the payment attachment and required documents.</li>
                <li>Ensure all documents are uploaded in the specified format and size.</li>
              </ul>
            </p>

            <p>For any assistance or inquiries, please contact</p>
            <b>Contact Number : </b><span>7718911574</span> <br />
            <b>Email Address : </b><span>environaturesolution@gmail.com</span><br />
            <b>Address : </b><span>Office no 128, Devi Annapurna Society,plot no 08, sector 18,Vashi Navi Mumbai 400705</span>

          </div>

          <div className="col-md-6">
            <div className="box" style={{ padding: "25px 40px" }}>
              <h3 className="heading" style={{ fontSize: "20px", marginBottom: "10px", textTransform: "none", color: "#262477" }}>Sign Up</h3>
              <form method="post" id="contactForm" name="contactForm" onSubmit={handleSubmit}>
                <div className="row">
              
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="txtFname" className="col-form-label">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtFname"
                        className="form-control"
                        placeholder="Enter First Name"
                        required
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            First_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.First_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="txtLname" className="col-form-label">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control"
                        placeholder="Enter Last Name"
                        required
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Last_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Last_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="Company_Name" className="col-form-label">
                        Company Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="Company_Name"
                        className="form-control"
                        placeholder="Enter Company Name"
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Company_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Company_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="txtMobile" className="col-form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={customer.Mobile}
                        id="txtMobile"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setCustomer({ ...customer, Mobile: numericInput });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="txtEmail" className="col-form-label">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email" required
                        id="txtEmail"
                        className="form-control"
                        placeholder="Enter Email Id"
                        onChange={(e) =>
                          setCustomer({ ...customer, Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Email}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="Alt_Email" className="col-form-label">
                      Alt  Email
                      </label>
                      <input
                        type="email"
                        id="Alt_Email"
                        className="form-control"
                        placeholder="Enter Alt Email Id"
                        onChange={(e) =>
                          setCustomer({ ...customer, Alt_Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Alt_Email}</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-none">
                    <div className="mb-1">
                      <label htmlFor="dropGender" className="col-form-label">
                        Gender
                      </label>
                      <select
                        className="form-select"
                        id="dropGender"
                        onChange={(e) =>
                          setCustomer({ ...customer, Gender: e.target.value })
                        }
                      >
                        <option label="Male" value="Male"></option>
                        <option label="Female" value="Female"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 d-none">
                    <div className="mb-1">
                      <label htmlFor="txtDOB" className="col-form-label">
                        DOB
                      </label>
                      <input
                        type="Date"
                        id="txtDOB"
                        className="form-control"
                        placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, DOB: e.target.value })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="GST_No" className="col-form-label">
                     GST No
                      </label>
                      <input
                        type="text"
                        id="GST_No"
                        className="form-control"
                        placeholder="Enter GST No"
                        maxLength={15}
                        value={customer.GST_No}
                        onChange={(e) =>
                          setCustomer({ ...customer, GST_No: e.target.value.toUpperCase() })
                        }
                      />
                      <p className="text-danger">{errors.GST_No}</p>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="txtLname" className="col-form-label">
                        Certificate Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select type="text" name="Bank" id="Bank" className="selecting"
                        options={certificateOption} required
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? 'white' : 'black', // Change text color of selected option
                            backgroundColor: state.isFocused ? 'blue' : 'white', // Change background color when hovering an option
                            '&:hover': {
                              backgroundColor: 'blue', // Change background color of other options when hovered
                              color: 'white', // Change text color of other options when hovered
                            },
                          }),
                        }}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Certificate_Id: e.value,
                            Certificate_Name: e.label,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Certificate_Name}</p>
                    </div>
                      </div>*/}
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="Reference" className="col-form-label">
                        Reference<span style={{ color: "red" }}>*</span>
                      </label>
                      <select className="form-select" value={customer.Reference || ""} required onChange={(e) =>
                        setCustomer({ ...customer, Reference: e.target.value })
                      } >
                        <option label="Select Reference" value=""></option>
                        <option label="Facebook" value="Facebook"></option>
                        <option label="Instagram" value="Instagram"></option>
                        <option label="Google" value="Google"></option>
                        <option label="LinkedIn" value="LinkedIn"></option>
                        <option label="Other" value="Other"></option>
                      </select>
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-1">
                      <label htmlFor="Reference" className="col-form-label">
                        Mention Reference<span style={{ color: "red" }}>*</span>
                      </label>
                      <input required={customer.Reference === "Other" ? true : false}
                        disabled={customer.Reference === "Other" ? false : true}
                        type="text" max={499}
                        id="Reference"
                        className="form-control"
                        placeholder="Other Reference"
                        onChange={(e) =>
                          setCustomer({ ...customer, Other_Reference: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="City" className="col-form-label">
                        City
                      </label>
                      <input 
                  
                        type="text"
                        id="City"
                        className="form-control"
                        placeholder="Enter City"
                        onChange={(e) =>
                          setCustomer({ ...customer, City: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.City}</p>
                    </div>
                  </div>
                    <div className="col-md-6">
                    <div className="mb-1">
                      <label htmlFor="District" className="col-form-label">
                      District
                      </label>
                      <input 
                        type="text"
                        id="District"
                        className="form-control"
                        placeholder="Enter District"
                        onChange={(e) =>
                          setCustomer({ ...customer, District: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.District}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-2">
                      <label htmlFor="State" className="col-form-label">
                        State<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                      required
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? 'white' : 'black', 
                            backgroundColor: state.isFocused ? 'blue' : 'white',
                            '&:hover': {
                              backgroundColor: 'blue',
                              color: 'white',
                            },
                          }),
                        }}
      options={indianStates}
      onChange={(e) => {
                          setCustomer({ ...customer, State: e.value })
                        }}
      placeholder="Select a state"
    />
                     
                    
                    </div>
                  </div>

               
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-1">
                      <label htmlFor="Address" className="col-form-label">
                        Address
                      </label>
                      <textarea
                        type="text"
                        id="Address"
                        className="form-control"
                        placeholder="Enter Address..."
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Address}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-1">
                      <label htmlFor="Remarks" className="col-form-label">
                        Remarks/Requirements
                      </label>
                      <textarea
                        type="text"
                        id="Remarks"
                        className="form-control"
                        placeholder="Enter Remarks..."
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Remarks: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Remarks}</p>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="VisitingCardFront" className="col-form-label">
                        Visiting Card (Front)
                      </label>
                      <input
                        type="file"
                        id="VisitingCardFront"
                        className="form-control"
                        //placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, VisitingCardFront: e.target.files[0] })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="VisitingCardBack" className="col-form-label">
                        Visiting Card (Back)
                      </label>
                      <input
                        type="file"
                        id="VisitingCardBack"
                        className="form-control"
                        //placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, VisitingCardBack: e.target.files[0] })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <button type="submit" className="btn btn-primary " style={{ height: "38px", marginBottom: "10px" }}>
                      Submit
                    </button><br />
                    <Link style={{ paddingTop: "10px" }} to={"/login"}>Already Have an Account? Sign In Here..</Link>
                  </div>
                </div>
              </form>

              <div id="form-message-warning mt-4"></div>
              <div id="form-message-success">
                Your message was sent, thank you!
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default CustomerEnquiry;
